import animationData from './131043-doctor.json';
import React from 'react';
import Lottie from 'react-lottie';

const LottieAnimation = () => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
  
    return (
      <div >
        <Lottie options={defaultOptions}
          height={400}
          width={350}
        />
      </div>
    );
  }
export default LottieAnimation