import React, { useEffect, useRef } from "react";
import Masonry from "masonry-layout";
import imagesLoaded from "imagesloaded";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ReviewsDescription,ReviewsTitle,ReviewsUrl } from "../metaTags/meta";
import { Link } from "react-router-dom";


const Reviews = () =>
{
    const gridRef = useRef(null);
    let masonryInstance = null;
  
    useEffect(() => {
      const grid = gridRef.current;
      masonryInstance = new Masonry(grid, {
        itemSelector: ".grid-item",
        columnWidth: ".grid-item",
        gutter: 20,
        fitWidth: true,
        columnWidth: 400
      });
  
      imagesLoaded(grid).on("progress", () => {
        masonryInstance.layout();
      });
  
      window.addEventListener("resize", handleResize);
  
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
  
    function handleResize() {
      masonryInstance.reloadItems();
      masonryInstance.layout();
    }
  
    return(<>

<HelmetProvider>
    <Helmet>
      <meta name="robots" content="index,follow" />
<link rel="alternate" href={ ReviewsUrl } hreflang="en" />
<title>{ReviewsTitle}</title>
<meta charset="utf-8" />
<meta name="author" content="WeldonRx"/>
<meta name="title" content={ReviewsTitle}/>
<meta name="description" content= { ReviewsDescription } />
<meta property="og:type" content="website" />
<meta property="og:url" content={ ReviewsUrl } />
<meta property="og:title" content={ReviewsTitle} />
<meta property="og:description" content= { ReviewsDescription }/>
<meta property="og:image" content="https://weldonrx.com/weldon-social-image.png" />
<meta property="twitter:card" content="summary_large_image" />
<meta property="twitter:url" content={ ReviewsUrl } />
<meta property="twitter:title" content={ReviewsTitle}/>
<meta property="twitter:description" content= { ReviewsDescription } />
<meta property="twitter:image" content="https://weldonrx.com/weldon-social-image.png" />
<link rel="canonical" href={ ReviewsUrl } />
  </Helmet>
</HelmetProvider>

  <div className="container spaceContainer pt-5 mt-3 mt-md-3 ">
    <div className="row">
      <div className="col-lg-12 col-md-12">
        <div className="page-title-breadcrumb">
          <h2> Reviews </h2>
          <nav  aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a > Home </a></li>
              <li> <img src="./images/bradcumb-arows.svg" alt="..." /> </li>
              <li className="breadcrumb-item active" aria-current="page"> Reviews </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <section className="wx_secSpacing">
  <div className="container">
    <div className="row">
      <div className="col-sm-12 col-md-6 col-lg-5 order-2 order-sm-2 order-md-1 order-lg-1 ">
        <div className="section-title section-title-block wx_RatingLeftSec">
          <p className="text-orange">Reviews of Our Top-Notch Services</p>
          <h2> Discover What Our Satisfied Customers Have to Say </h2>
          <Link to='/' className="about-btn"> Get Started <img className="img-fluid" src="./images/right-big-arows.svg" alt="..." />  </Link>
        </div>
      </div>
      <div className="col-sm-12 col-md-6 col-lg-7 order-1 order-md-1 order-md-2 order-lg-2">
        <div className="wx_RatingRightSec">
          <img src="./images/ratingimg.svg" className="w-100" alt="" />
          <img src="./images/ratingbox.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</section>

<section className="wx_secSpacing">
  <div className="container">
    <div className="row align-items-center">
      <div className="col-sm-12 col-lg-4">
        <div className="card wx_reviewBg">
          <div className="card-body text-center">
            <img src="./images/chess_queen.svg" className="mb-4" alt="" />
            <h2 className="mb-2">4.9/5</h2>
            <ul className="d-flex justify-content-center">
              <li><img src="./images/fill-star.svg" alt="" /></li>
              <li><img src="./images/fill-star.svg" alt="" /></li>
              <li><img src="./images/fill-star.svg" alt="" /></li>
              <li><img src="./images/fill-star.svg" alt="" /></li>
              <li><img src="./images/half-star.svg" alt="" /></li>
            </ul>
            <p>11,626 reviews</p>
          </div>
        </div>
      </div>
      <div className="col-sm-12 col-lg-8">
        <div className="wx_ReviewRatingBgInner">
          <span>5</span>
            <div className="progress" role="progressbar" aria-label="Basic example" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
              <div className="progress-bar" style={{width: '80%'}}></div>
            </div>
            <span>4</span>
          <div className="progress" role="progressbar" aria-label="Basic example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
            <div className="progress-bar" style={{width: "20%"}}></div>
          </div>
          <span>3</span>
          <div className="progress" role="progressbar" aria-label="Basic example" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100">
            <div className="progress-bar" style={{width:"10%"}}></div>
          </div>
          <span>2</span>
          <div className="progress" role="progressbar" aria-label="Basic example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
            <div className="progress-bar" style={{width: "5%"}}></div>
          </div>
          <span>1</span>
          <div className="progress" role="progressbar" aria-label="Basic example" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
            <div className="progress-bar" style={{width: "0%"}}></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section className="wx_secSpacing"> 
  <div className="grid wx_ReviewRatingBg" ref={gridRef}>
    <div className="grid-item">
      <div className="card">
        <div className="card-body">
          <div className="d-flex align-items-center mb-3 position-relative">
            <div className="flex-shrink-0">
              <img src="./images/reviewuser.svg" alt="..." />
            </div>
            <div className="flex-grow-1 ms-3">
              <h5 className="mb-0">Sarah C. <img src="./images/reviewright.svg" alt="" /></h5>
              <ul className="d-flex p-0 mb-0">
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><span className="ms-2">5 stars</span></li>                  
              </ul>
            </div>
            <p className="mb-0">March 1, 2022</p>
          </div>
          <div className="text-left">
            <p>Outstanding service and products! WeldonRx has made managing my medications a breeze. I highly recommend their services to anyone looking for a reliable and convenient solution. </p>
          </div>
        </div>
      </div>
    </div>
    <div className="grid-item " >
      <div className="card">
        <div className="card-body">
          <div className="d-flex align-items-center mb-3 position-relative">
            <div className="flex-shrink-0">
              <img src="./images/reviewuser.svg" alt="..." />
            </div>
            <div className="flex-grow-1 ms-3">
              <h5 className="mb-0">Michael B. <img src="./images/reviewright.svg" alt="" /></h5>
              <ul className="d-flex p-0 mb-0">
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><img src="./images/fillhalfstar.svg" alt="" /></li>
                <li><span className="ms-2">4.5 stars</span></li>                  
              </ul>
            </div>
            <p className="mb-0">May 12, 2022</p>
          </div>
          <div className="text-left">
            <h6>Very Impressed With The WeldonRX</h6>
            <p>I've been using WeldonRx for over a year now, and I couldn't be happier with their service. The app is easy to use, and the customer support team is always available to help with any questions or concerns.  </p>
          </div>
        </div>
      </div>
    </div>
    <div className="grid-item " >
      <div className="card">
        <div className="card-body">
          <div className="d-flex align-items-center mb-3 position-relative">
            <div className="flex-shrink-0">
              <img src="./images/reviewuser.svg" alt="..." />
            </div>
            <div className="flex-grow-1 ms-3">
              <h5 className="mb-0">Emily F.<img src="./images/reviewright.svg" alt="" /></h5>
              <ul className="d-flex p-0 mb-0">
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><img src="./images/fillratingstar.svg" alt="" /></li>

                <li><span className="ms-2">5 stars</span></li>                  
              </ul>
            </div>
            <p className="mb-0">June 15, 2022</p>
          </div>
          <div className="text-left">
            <p>WeldonRx has exceeded my expectations in every way possible. Their online prescription renewal process is simple and fast, and their delivery service is always on time. I highly recommend them! </p>
          </div>
        </div>
      </div>
    </div>
    <div className="grid-item " >
      <div className="card">
        <div className="card-body">
          <div className="d-flex align-items-center mb-3 position-relative">
            <div className="flex-shrink-0">
              <img src="./images/reviewuser.svg" alt="..." />
            </div>
            <div className="flex-grow-1 ms-3">
              <h5 className="mb-0">David R. <img src="./images/reviewright.svg" alt="" /></h5>
              <ul className="d-flex p-0 mb-0">
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><img src="./images/fillhalfstar.svg" alt="" /></li>
                <li><span className="ms-2">4.5 stars</span></li>                  
              </ul>
            </div>
            <p className="mb-0">July 22, 2022</p>
          </div>
          <div className="text-left">
            <p>I've tried other online pharmacies before, but WeldonRx is by far the best. Their prices are competitive, and their service is top otch. I'm a customer for life!  </p>
          </div>
        </div>
      </div>
    </div>
    <div className="grid-item ">
      <div className="card">
        <div className="card-body">
          <div className="d-flex align-items-center mb-3 position-relative">
            <div className="flex-shrink-0">
              <img src="./images/reviewuser.svg" alt="..." />
            </div>
            <div className="flex-grow-1 ms-3">
              <h5 className="mb-0">Jessica M. <img src="./images/reviewright.svg" alt="" /></h5>
              <ul className="d-flex p-0 mb-0">
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><span className="ms-2">5 stars</span></li>                  
              </ul>
            </div>
            <p className="mb-0">November 27, 2022</p>
          </div>
          <div className="text-left">
            <h6>Very Impressed With The WeldonRX</h6>
            <p>I love WeldonRx! Their automatic refill feature has saved me so much time and hassle, and their customer service team is always friendly and helpful.  </p>
          </div>
        </div>
      </div>
    </div>


    <div className="grid-item ">
      <div className="card">
        <div className="card-body">
          <div className="d-flex align-items-center mb-3 position-relative">
            <div className="flex-shrink-0">
              <img src="./images/reviewuser.svg" alt="..." />
            </div>
            <div className="flex-grow-1 ms-3">
              <h5 className="mb-0">Andrew G. <img src="./images/reviewright.svg" alt="" /></h5>
              <ul className="d-flex p-0 mb-0">
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><img src="./images/fillhalfstar.svg" alt="" /></li>
                <li><span className="ms-2">4.5 stars</span></li>                  
              </ul>
            </div>
            <p className="mb-0">December 5, 2022</p>
          </div>
          <div className="text-left">
            <p>WeldonRx has made my life so much easier. I no longer have to worry about running out of my medications or dealing with the hassle of going to the pharmacy. Thank you, WeldonRx!  </p>
          </div>
        </div>
      </div>
    </div>

    <div className="grid-item ">
      <div className="card">
        <div className="card-body">
          <div className="d-flex align-items-center mb-3 position-relative">
            <div className="flex-shrink-0">
              <img src="./images/reviewuser.svg" alt="..." />
            </div>
            <div className="flex-grow-1 ms-3">
              <h5 className="mb-0">Rachel S. <img src="./images/reviewright.svg" alt="" /></h5>
              <ul className="d-flex p-0 mb-0">
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><span className="ms-2">5 stars</span></li>                  
              </ul>
            </div>
            <p className="mb-0">Janaury 9, 2023</p>
          </div>
          <div className="text-left">
            <p>I highly recommend WeldonRx to anyone looking for a reliable and convenient way to manage their medications. Their app is user friendly, and their customer support team is exceptional.  </p>
          </div>
        </div>
      </div>
    </div>

    <div className="grid-item ">
      <div className="card">
        <div className="card-body">
          <div className="d-flex align-items-center mb-3 position-relative">
            <div className="flex-shrink-0">
              <img src="./images/reviewuser.svg" alt="..." />
            </div>
            <div className="flex-grow-1 ms-3">
              <h5 className="mb-0">Lisa P.<img src="./images/reviewright.svg" alt="" /></h5>
              <ul className="d-flex p-0 mb-0">
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><img src="./images/fillhalfstar.svg" alt="" /></li>
                <li><span className="ms-2">4.5 stars</span></li>                  
              </ul>
            </div>
            <p className="mb-0">January 18, 2023</p>
          </div>
          <div className="text-left">
            <p>WeldonRx is the best thing to happen to me since sliced bread! Their service is fast, reliable, and convenient. I couldn't ask for more.   </p>
          </div>
        </div>
      </div>
    </div>

    <div className="grid-item ">
      <div className="card">
        <div className="card-body">
          <div className="d-flex align-items-center mb-3 position-relative">
            <div className="flex-shrink-0">
              <img src="./images/reviewuser.svg" alt="..." />
            </div>
            <div className="flex-grow-1 ms-3">
              <h5 className="mb-0">Kevin T.<img src="./images/reviewright.svg" alt="" /></h5>
              <ul className="d-flex p-0 mb-0">
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><img src="./images/fillratingstar.svg" alt="" /></li>
                <li><span className="ms-2">5 stars</span></li>                  
              </ul>
            </div>
            <p className="mb-0">Feburary 3, 2023</p>
          </div>
          <div className="text-left">
            <p>I'm so grateful for WeldonRx. Their service has made it easy for me to manage my medications, and I no longer have to worry about missing a dose or running out of my prescriptions. Thank you, WeldonRx!    </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
    </>)
}
export default Reviews ;
