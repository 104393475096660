import { BiUser } from 'react-icons/bi';
import { MdEmail } from 'react-icons/md';
import { MdCall } from 'react-icons/md';
import { useMemo, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AppUrl, feedback } from '../sharedApi/common';
import { customAxios } from '../sharedApi/customeAxios';
import { Modal } from 'react-bootstrap';
import { decrypteData } from '../sharedApi/customeAxios';

const ContactUs = () => {
  const getProfileFromStorage = () => decrypteData();

  const [profile, setProfile] = useState(() => getProfileFromStorage());
  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [yourMessage, setYourMessage] = useState('')
  const [termsCondtions, setTermsConditions] = useState(false)
  const [fullNameColor, setFullNameColor] = useState('lightgrey')
  const [emailColor, setEmailColor] = useState('lightgrey')
  const [phoneColor, setPhoneColor] = useState('lightgrey')
  const [yourMessageColor, setYourMessageColor] = useState('lightgrey')
  const [termsCondtionsColor, setTermsConditionsColor] = useState('lightgrey')
  const [loading, setLoading] = useState('')
  const[phoneMessageEror,setPhoneMessageError] = useState('')
   const location = useLocation();

  useMemo(() => {
    const myProfile = decrypteData();
    if (myProfile) {
      setFullName(myProfile.full_name)
      setEmail(myProfile.email)
      setPhone(myProfile.phone_number)
    }
  }, [location]);

  useEffect(() => {
    if (!profile) {
      setProfile(getProfileFromStorage());
    }
  }, [profile]);

  const submitContactUsData = () => {
    setLoading(true)
    setPhoneMessageError('')
    customAxios({
      method: 'POST',
      url: `${feedback}`,
      data:  {'name': `${fullName}`, 'email': `${email}`,'phone': phone,'message': `${yourMessage}`} 
    },)
      .then((response) => {
        // console.log(response)
        if (response) {
          setShowOTPModal(true)
          setLoading(false) 
        }
      })
  };

  const [showOtpModal, setShowOTPModal] = useState(false)

  const submitData = () => {
    if (!fullName) {
      setFullNameColor('#FF4200')
    } else if (!email) {
      setEmailColor('#FF4200')
    } else if (!phone) {
      setPhoneColor('#FF4200')
    }else if(phone.length < 10 || phone.length > 10){
      setPhoneColor('#FF4200')
      setPhoneMessageError('Please Enter 10 Digits Mobile Number');
    }else if (!yourMessage) {
      setYourMessageColor('#FF4200')
    } else if (!termsCondtions) {
      setTermsConditionsColor('#FF4200')
    } else {
      submitContactUsData();
    }
  }

  return (<>
    <h2> Contact Us </h2>
    <div className="card wx_myprofileTextRight wx_MyContactBg mt-0">
      <div className="card-body">
        <h4 className="m-0 mb-4"> </h4>
        <form >
          <div className="row">
            
            <div className="col">
              <div className="form-floating mb-3">
                <input type="text" className="form-control" defaultValue={fullName} onChange={(e) => { return setFullName(e.target.value), setFullNameColor('lightgrey') }} style={{ borderColor: fullNameColor }} id="formGroupExampleInput" placeholder="Evan Edwards" />
                <span className="form-icon"> <i className="fa-regular fa-user"><BiUser size={20} /></i> </span>
                <label htmlFor="emailAddress"> Full Name </label>
              </div>
            </div>

            <div className="col">
              <div className="form-floating mb-3">
                <input type="email" defaultValue={email} onChange={(e) => { return setEmail(e.target.value), setEmailColor('lightgrey') }} className="form-control" id="formGroupExampleInput" style={{ borderColor: emailColor }} placeholder="tefri@gmail.com" />
                <span className="form-icon"> <i className="fa-solid fa-envelope"><MdEmail size={20} /></i> </span>
                <label htmlFor="emailAddress"> Email </label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <div className="form-floating mb-3">
                <input type="text" defaultValue={phone} onChange={(e) => { return setPhone(e.target.value), setPhoneColor('lightgrey') }} className="form-control" id="formGroupExampleInput" style={{ borderColor: phoneColor }} placeholder="+1(135) 1984 2020" />
                <span className="form-icon"> <i className="fa-solid fa-phone"><MdCall size={20} /></i> </span>
                <label htmlFor="emailAddress"> Phone </label>{phoneMessageEror}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              
              <div className="mb-3">
                <textarea className="form-control" defaultValue={yourMessage} onChange={(e) => { return setYourMessage(e.target.value), setYourMessageColor('lightgrey') }} id="exampleFormControlTextarea1" style={{ borderColor: yourMessageColor }} rows="5" placeholder="Write your message..."></textarea>
              </div>

              <div className="mb-3 mt-2 form-check">
                <input type="checkbox" onChange={(e) => { return setTermsConditions(e.target.checked), setTermsConditionsColor('lightgrey') }} checked={termsCondtions} style={{ borderColor: termsCondtionsColor }} className="form-check-input" id="exampleCheck1" />
                <label className="form-check-label" htmlFor="exampleCheck1">I agree to the <a className='#'> terms</a> and <a className='#'> privacy policy</a></label>
              </div>
            </div>
          </div>

          <div className="">
            <button onClick={submitData} className="btn btn-primary btn-lg submitButton" id="submitButton" type="button">
              {loading ? 'Processing...' : 'Submit'}</button>
          </div>

        </form>
      </div>
    </div>

    <Modal show={showOtpModal} onHide={() => setShowOTPModal(false)} className="modal fade wx_thankYouModal" centered>
      <Modal.Dialog centered>
        <div className="modal-header border-0">
          <img src="./images/myprofile/rightsignic.svg" alt="" />
          <img src="./images/myprofile/closeic.svg" className="btn-close" onClick={() => setShowOTPModal(false)} />
        </div>
        <Modal.Body className="text-center">
          <h4>Thank You!</h4>
          <p>We've received your message. Someone from our team will contact you soon.</p>
        </Modal.Body>
      </Modal.Dialog>
    </Modal>

  </>)
}
export default ContactUs;