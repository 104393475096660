import { useEffect, useState, useMemo, useLayoutEffect } from "react";
import Button from 'react-bootstrap/Button'
import { AiOutlineBell } from 'react-icons/ai';
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import { decryptNextillRemndrData } from "../sharedApi/customeAxios";
import { getPillRemainderUrlList } from "../sharedApi/common";

const MedicineScheudle = () => {

  const [hours, setHours] = useState([])
  const [minutes, setMinutes] = useState([]);
  const location = useLocation();

  const getProfileFromStorage = () => decryptNextillRemndrData();

  const [NextPillReminder, setNextPillReminder] = useState(() => getProfileFromStorage());

  useMemo(() => {
    const token = getProfileFromStorage();
    if (token) {
      setNextPillReminder(token);
    }
  }, [location]);

  useEffect(() => {

    if (!NextPillReminder) {
      setNextPillReminder(getProfileFromStorage());
    }
  }, [NextPillReminder]);

  useLayoutEffect(() => {

    if (NextPillReminder) {
      function diffLocalTime(start, end) {
        start = start.split(":");
        end = end.split(":");
        var startDate = new Date(0, 0, 0, start[0], start[1], 0);
        var endDate = new Date(0, 0, 0, end[0], end[1], 0);
        var diff = endDate.getTime() - startDate.getTime();
        var hours = Math.floor(diff / 1000 / 60 / 60);
        diff -= hours * 1000 * 60 * 60;
        var minutes = Math.floor(diff / 1000 / 60);
        if (hours < 0)
          hours = hours + 24;

        return (hours < 9 ? "0" : "") + hours + ":" + (minutes < 9 ? "0" : "") + minutes;
      }
      const now = new Date();
      const localTime = now.toTimeString('').slice(0, 8);
      const reminderTime = NextPillReminder != null ? NextPillReminder.remind_at : '';
      const hr = NextPillReminder != null ? diffLocalTime(localTime, reminderTime).slice(0, 2) : '';
      const min = NextPillReminder != null ? diffLocalTime(localTime, reminderTime).slice(3, 5) : '';
      setHours(hr)
      setMinutes(min)
    }
  }, [NextPillReminder,hours,minutes])

  return (<>
    <div className="gradiant-col" >
      <div className="media">
        <div className="media-body">
          {NextPillReminder ? (
            <h3>
              Next medicine in
              <span className="bold-text" style={{ marginLeft: '8px' }}>
                {hours}  Hours
              </span> {minutes} minutes
            </h3>)
            :
            (<Link to='/SetReminder' >
              <Button type='Submit'><AiOutlineBell size={24} />
                <span style={{ fontSize: '20px' }} > Set Reminder  </span>
              </Button>
            </Link>)
          }
          <ul>
          </ul>
        </div>
        <img src="./images/profile-1.png" style={{ marginLeft: '0px' }} />
      </div>
    </div>
  </>)
}

export default MedicineScheudle;