import { useState, useEffect } from 'react';
import { registerUrl, loginUrl } from './components/sharedApi/common'
import OtpConfirmation from './components/login/otpConfirmation'
import { Routes, Route, Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import CreateProfile from './components/createProfile/createProfile';
import Header from './components/main/header';
import LoginPage from './components/login/loginWithPhone';
import NewPrescription from './components/newPrescritption/newPrescription';
import OrderList from './components/orders/orderList';
import { clientId } from './components/sharedApi/common';
import { gapi } from 'gapi-script';
import TrackingOrders from './components/orders/trackOrders';
import About from './components/main/howItWorks';
import PillRemainder from './components/pillRemainder/pillRemainder';
import DashBoard from './components/dashboard/dashboard';
import TransferPrescription from './components/transferPrescription/transferPrescription';
import AboutUS from './components/aboutUs/aboutUs';
import ContactUS from './components/contactUs/contactUs';
import NavBar from './components/main/navbar';
import PrivacyPolicy from './components/privacyPolicy/privacyPolicy';
import { useLocation } from 'react-router-dom';
import TermsAndConditions from './components/privacyPolicy/termsAndConditions';
import Careers from './components/careers/careers';
import ProtectedRoute from './components/protectedRoutes/ProtectedRoute';
import Reviews from './components/reviews/reviews';
import Footer from './components/main/footer';
import SetReminder from './components/pillRemainder/setReminder';
import MyProfileSettings from './components/settings/myProfileSettings';
import RxTransfer from './components/RxTransfer/RxTransfer';
import Error from './components/error/error';
import { getToken } from './components/sharedApi/customeAxios';

function App() {

  const [userNumDetail, setUserNumDetail] = useState('');
  const [getOtp, setGetOtp] = useState('');
  const [otpVerify, setOtpVerify] = useState([]);
  const [otpErrMsg, setOtpErrMsg] = useState('One time password has been sent to your phone via text. Please enter it and press continue.');
  const [otpReceived, setOtpReceived] = useState([])
  const [errMsg, settErrMsg] = useState('');
  const [isloading, setIsLoading] = useState(false)
  const [loadingPhoneNumber, setLoadingPhoneNumber] = useState(false)
  const [loading, setLoading] = useState(false)
  const [googleLoginLoading, SetGoogleLoading] = useState(false)

  const token = getToken();
  const location = useLocation();
  const navigate = useNavigate();

  const handleNumber = (event) => {
    const value = event.target.value.replace(/\D/g, '').slice(0, 10);
    setUserNumDetail(value)
  }

  /*----------Register Number ----------*/
  const registerNumber = async () => {
    setLoadingPhoneNumber(true)
    const rawResponse = await fetch(registerUrl, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'country_code': '1', 'phone_number': userNumDetail, 'login_type': 'phone'
      })
    });
    const response = await rawResponse.json();
    // console.log(response.data)
    if (response.success == false) {
      settErrMsg('Please Enter a Valid Mobile Number')
    }
    else {
      (userNumDetail && navigate('/otpConfirmation'))
    }
    setLoadingPhoneNumber(false)
    setOtpReceived(response.data.otp);
    setOtpVerify(response);
  }

  /*-----------Otp Verfication ----------*/

  const loginNumber = async () => {
    const rawResponse = await fetch(loginUrl, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 'phone_number': userNumDetail, 'otp': getOtp })
    });
    const content = await rawResponse.json();
    // console.log(content )    
    setIsLoading(true)
    setIsLoading(false)
    localStorage.setItem('Token_access', JSON.stringify(content.data.remember_token))
    setUserNumDetail('')
    // (response?.data?.data?.user?.user_default_address == undefined
    if (content?.data?.user?.user_default_shipping_address == undefined || content?.data?.user?.user_default_billing_address == undefined) {
      navigate('/createProfile')
      setGetOtp('')
      setOtpErrMsg('One time password has been sent to your phone via text. Please enter it and press continue.')
    }
    else {
      navigate('/dashboard')
      setGetOtp('')
      setOtpErrMsg('One time password has been sent to your phone via text. Please enter it and press continue.')
    }
  }

  /*----------Register Submit Data---------*/

  const submit = () => {

    if (!userNumDetail) {
      settErrMsg('Please Enter Your Phone Number')
    }else if (userNumDetail.length < 10 || userNumDetail.length > 10) {
      settErrMsg('Number Must be of 10 digits')
    }else {
      localStorage.removeItem('email')
      registerNumber();
      localStorage.setItem('My Number', userNumDetail)
      settErrMsg('')
    }
  }
  const handleOtp = (event) => {
    const value = event.target.value.replace(/\D/g, '').slice(0, 6);
    setGetOtp(value) 
  }

  /*-----------Otp Submit Data------------*/
  const submitOtp = () => {
    if (!getOtp) {
      setOtpErrMsg('Invalid Input! Please try again.')
    }else if (getOtp != otpVerify.data.otp) {
      setOtpErrMsg('Invalid OTP');
    }else if (getOtp == otpVerify.data.otp) {
      setIsLoading(true)
      loginNumber()
    }
  }

  /*----------------------------Google Login -------------------*/

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: clientId,
        scope: '',
      })
    };
    gapi.load('client:auth2', initClient);
  }, []);

  const onSuccess = (res) => {
    setLoading(true)
    SetGoogleLoading(true)
    localStorage.setItem('email', JSON.stringify(res.profileObj.email))
    localStorage.removeItem('My Number')
    const formdata = new FormData();
    formdata.append('country_code', '1')
    formdata.append('first_name', res.profileObj.givenName)
    formdata.append('login_type', 'google')
    formdata.append('last_name', res.profileObj.familyName)
    formdata.append('sex', '')
    formdata.append('date_of_birth', '')
    formdata.append('email', res.profileObj.email)
    axios.post(registerUrl, formdata, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'multipart/form-data',
        'SameSite':'None',
      },
      withCredentials: true,
      credentials: 'include',
      sameSite: 'none',
      secure: true,
    }).then((response) => {
      // console.log(response.data)
      localStorage.setItem('Token_access', JSON.stringify(response.data.data.remember_token))
      setLoading(false)
      SetGoogleLoading(false)
      if (response?.data?.data?.user?.user_default_shipping_address === undefined || response?.data?.data?.user?.user_default_billing_address === undefined) {
        navigate('/createProfile')
      } else {
        navigate('/dashboard')
      }
    });
  }

  return (
    <>
      {['/', '/AboutUs', '/ContactUs', '/PrivacyPolicy', '/toc', '/Careers', '/Reviews', '/Error'].includes(location.pathname) && <NavBar />}

      <Routes>
        <Route exact path='/' element={<Header submit={submit} handleNumber={handleNumber} errMsg={errMsg} userNumDetail={userNumDetail} onSuccess={onSuccess} loadingPhoneNumber={loadingPhoneNumber} showNavbar={true} loading={loading} googleLoginLoading={googleLoginLoading} />} />

        <Route exact path='/otpConfirmation'
          element={!userNumDetail ? <Navigate to='/' /> :
            <OtpConfirmation handleOtp={handleOtp} getOtp={getOtp} otpReceived={otpReceived} submitButton={submit} loading={isloading} submit={submitOtp} userNumDetail={userNumDetail} otpErrMsg={otpErrMsg} />}>
        </Route>

        <Route exact path='/createProfile' element={<ProtectedRoute Component={CreateProfile}></ProtectedRoute>} ></Route>

        <Route exact path='/RxTransfer' element={<ProtectedRoute Component={RxTransfer}></ProtectedRoute>} ></Route>

        <Route exact path='/dashboard' element={<ProtectedRoute Component={DashBoard}></ProtectedRoute>} ></Route>
        
        {!token &&
          <Route exact path='/login' element={<LoginPage submit={submit} handleNumber={handleNumber} errMsg={errMsg} userNumDetail={userNumDetail} onSuccess={onSuccess} loadingPhoneNumber={loadingPhoneNumber} googleLoginLoading={googleLoginLoading} />}></Route>
        }
        <Route exact path='/NewPrescription' element={<ProtectedRoute Component={NewPrescription}></ProtectedRoute>} ></Route>

        <Route exact path='/TransferPrescription' element={<ProtectedRoute Component={TransferPrescription}></ProtectedRoute>} ></Route>

        <Route exact path='/orderList' element={<ProtectedRoute Component={OrderList}></ProtectedRoute>} ></Route>

        <Route exact path='/trackOrder' element={<ProtectedRoute Component={TrackingOrders}></ProtectedRoute>} ></Route>

        <Route exact path='/Settings' element={<ProtectedRoute Component={MyProfileSettings}></ProtectedRoute>} ></Route>

        <Route exact path='/HowItWorks' element={<About />}></Route>

        <Route exact path='/PillReminder' element={<ProtectedRoute Component={PillRemainder}></ProtectedRoute>} ></Route>

        <Route exact path='/SetReminder' element={<ProtectedRoute Component={SetReminder}></ProtectedRoute>} ></Route>

        <Route exact path='/AboutUs' element={<AboutUS />} />

        <Route exact path='/ContactUs' element={<ContactUS />} />

        <Route exact path='/PrivacyPolicy' element={<PrivacyPolicy />} />

        <Route exact path='/toc' element={<TermsAndConditions />} />

        <Route exact path='/Careers' element={<Careers />}></Route>

        <Route exact path='/Reviews' element={<Reviews />}></Route>

        <Route exact path='/Error' element={<Error />}></Route>

        <Route path="*" element={<Navigate to="/Error" replace />} />

      </Routes>

      {['/', '/AboutUs', '/ContactUs', '/PrivacyPolicy', '/toc', '/Careers', '/Reviews'].includes(location.pathname) && <Footer />}

    </>
  );
}
export default App;
