import { AppUrl, getPillRemainderUrlList } from "../sharedApi/common";
import { useEffect, useState } from "react";
import axios from 'axios'
import Card from 'react-bootstrap/Card';
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { FcExpired } from 'react-icons/fc';
import LogoutTimer from "../logOut/logOutTimer";

const PillList = (props) => {

  const [getPillList, setGetPillList] = useState([])
  const [isLoading, setIsLoading] = useState(false);
  const [messageError, setMessageError] = useState('')

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false)    
  }

  const navigate = useNavigate();

  const GetPillList = async () => {
    setIsLoading(true)
    let token = JSON.parse(localStorage.getItem('Token_access'))
    if (token) {
      const request = fetch(`${getPillRemainderUrlList}`,
        {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + token,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        },
      );
      const response = await request;
      const parsed = await response.json();

      if(parsed.message === 'Unauthenticated.'){
        setShow(true)
        setTimeout(()=>{
        localStorage.removeItem('Token_access')
        localStorage.removeItem('u/\s/p/r\e')
        localStorage.removeItem('My Number')
        localStorage.removeItem('O/[]r/d/I/d')
        localStorage.removeItem('activeTab')
        navigate('/')
        },3000)
      }

      if (parsed.data.pils_data.data.length === 0) {
        setMessageError('No Reminders Found.')
        localStorage.removeItem('n/x\t/r/m/n/d/r')
      }
      else {
        setMessageError('Pill Schedule')
        setGetPillList(parsed.data.pils_data.data)
        // localStorage.setItem('PillRemainder', JSON.stringify(parsed.data.pils_data.data))
        
          const pillreminder = CryptoJS.AES.encrypt(JSON.stringify(parsed.data.pils_data.data),'secret key').toString();
          localStorage.setItem('p/l/r\/m/n/d/\r', pillreminder);

          const nexpillreminder = CryptoJS.AES.encrypt(JSON.stringify(parsed.data.next_pill),'secret key').toString();
          localStorage.setItem('n/x\t/r/m/n/d/r',nexpillreminder);

        // localStorage.setItem('nextPill', JSON.stringify(parsed.data.next_pill))
      }
      setTimeout(() => {
        setIsLoading(false)
      }, 1000)
    }
  }

  useEffect(() => {
    GetPillList();
  }, [])

  function deletePills(index) {
    let token = JSON.parse(localStorage.getItem('Token_access'))
    if (token) {
      axios.delete(`${AppUrl}/api/pill-reminder/delete/${index}`, {
        headers: {
          'Authorization': 'Bearer ' + token,
          'Accept': 'application/json',
        }
      }).then((response) => {
        return response;
      })
    }
  }

  const deletePill = (index) => {
    const del = getPillList.filter((elem, id) => {
      return index != id;
    })
    setGetPillList(del)
  }

  const handleClick = (value) => {
    // console.log(value)
    // localStorage.setItem('pillsdata', JSON.stringify(value))

    const nexpillreminder = CryptoJS.AES.encrypt(JSON.stringify(value),'secret key').toString();
    localStorage.setItem('pillsdata',nexpillreminder);

  }



  return (<> <br /><br />
    <HelmetProvider>
          <Helmet>
            <meta name="robots" content="noindex,nofollow" />
        </Helmet>
      </HelmetProvider>
    <LogoutTimer onLogout={props.logOut} />        
      

    <div className="pharmacy-search">
      <div className="row pt-4 pb-4">
        <div className="col-md-12">
          <div className="search-header">
            <h5>{messageError}</h5>
          </div>
        </div>
      </div>

      <div>
        {getPillList?.map((value, index) => {
          return (
            <div key={index}>
              <div className="card-body">
                {isLoading ? <Card className="pill-schedule-row" style={{ height: '68px', backgroundColor: "#ecebeb", borderColor: 'white', marginBottom: '20px' }}></Card>
                  :
                  <div className="pill-schedule-row">
                    <div className="pill-schedule-left">
                      <div className="pill-schedule-icon">
                        <img src="./images/Pill-icon.svg" />
                      </div>

                      <div className="pill-schedule-content">
                        <h4>{value.pill_name}</h4>

                        <h5>{value.remind_at}<span className="schedule-possin">
                          {value.reminder_type.charAt(0).toUpperCase() + value.reminder_type.slice(1).toLowerCase()}

                        </span></h5>
                      </div>
                    </div>

                    <div className="pill-schedule-right">
                      <ul>
                        <li onClick={() => { deletePill(index) }}> <a className="btn" onClick={() => { deletePills(value.id) }}>
                          <img src="./images/delete-icon.svg" /> </a> </li>

                        <li>
                          <div onClick={() => handleClick(value)}>
                            <Link to='/SetReminder' className="btn" ><img src="./images/edit.svg" /> </Link>
                          </div>
                        </li>
                        <li> <a > <img src="./images/blue-right-arows.svg" /></a></li>
                      </ul>
                    </div>
                  </div>
                }
              </div>
            </div>)
        })}
      </div>
    </div>

    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title><FcExpired />Your session has expired</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please log in again to continue using this app</Modal.Body>
      </Modal>


  </>)
}
export default PillList 