import { contactInformation } from '../constants/contactUs';
import { FaRegUser } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ContactUsDescription,ContactUsTitle,ContactUsUrl } from '../metaTags/meta';
import { useState,useEffect } from 'react';
import { feedback } from '../sharedApi/common';
import { Modal } from 'react-bootstrap';
import { contactUs } from '../sharedApi/common';

const ContactUS = (props) => {

  const[firstName,setFirstName] = useState('')
  const[email,setEmail] = useState('')
  const[message,setMessage] = useState('') 
  const[firstNameColor,setFirstNameColor] = useState('lightgrey')
  const[emailColor,setEmailColor] = useState('lightgrey')
  const[messageColor,setMessageColor] = useState('lightgrey')
  const[loading,setLoading] = useState(false)
  const[messageError,setMessageError] = useState('')
  const[showOtpModal, setShowOTPModal] = useState(false)

  const SubmitContactUs = async () => {
    setLoading(true)
    setMessageError('')
    const rawResponse = await fetch(contactUs,{
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({'name' : firstName ,'email': email ,'message': message ,})
    });
    const content = await rawResponse.json();
    console.log(content)
    setLoading(false)
    if(content.message === 'Unauthenticated.'){
      setMessageError('Something went wrong Please try again !!')
    }else{
      setShowOTPModal(true) 
    }
  }

  const Submitdata = (e) =>{
    e.preventDefault();
    if(!firstName){
      setFirstNameColor('#FF4200')
    }else if(!email){
      setEmailColor('#FF4200')
    }else if(!message){
      setMessageColor('#FF4200')
    }else{
      SubmitContactUs()
      setMessage('')
    }
  }

  return (<>

<HelmetProvider>
    <Helmet>
      <meta name="robots" content="index,follow" />
<link rel="alternate" href={ ContactUsUrl } hreflang="en" />
<title>{ContactUsTitle}</title>
<meta charset="utf-8" />
<meta name="author" content="WeldonRx"/>
<meta name="title" content={ContactUsTitle}/>
<meta name="description" content= { ContactUsDescription } />
<meta property="og:type" content="website" />
<meta property="og:url" content={ ContactUsUrl } />
<meta property="og:title" content={ContactUsTitle} />
<meta property="og:description" content= { ContactUsDescription }/>
<meta property="og:image" content="https://weldonrx.com/weldon-social-image.png" />
<meta property="twitter:card" content="summary_large_image" />
<meta property="twitter:url" content={ ContactUsUrl } />
<meta property="twitter:title" content={ContactUsTitle}/>
<meta property="twitter:description" content= { ContactUsDescription } />
<meta property="twitter:image" content="https://weldonrx.com/weldon-social-image.png" />
<link rel="canonical" href={ ContactUsUrl } />
  </Helmet>
</HelmetProvider>

    <div className="container spaceContainer pt-5 mt-3 mt-md-3 ">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="page-title-breadcrumb">
              <h2> Contact Us </h2>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a > Home </a></li>
                  <li> <img src="./images/bradcumb-arows.svg" alt="..." /> </li>
                  <li className="breadcrumb-item active" aria-current="page"> Contact Us</li>
                </ol>
              </nav>
            </div>
          </div> 
        </div>

      <div className="row mt-5">
        <div className="col-lg-7 col-md-7 col-sm-12 col-12">
          <div className="section-title section-title-block">
            <h5 className="text-orange">{contactInformation[0].getinTouch}</h5>
            <h2>{contactInformation[1].goals} </h2>
            <p className='mt-1'>
            Perhaps you have a question about our product offerings, or want to learn more about how our services can benefit you. Maybe you're experiencing an issue with your account or have feedback for us on how we can improve our services. Whatever the reason, we welcome your inquiries and look forward to hearing from you.
            </p>
          </div>
          <div className="row mt-5">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="card contact-col b-none">
                <img src="./images/map.svg" alt="..." />
                <h5> {contactInformation[6].location} </h5>
                <p> 4676 Hastings St, Burnaby,<br/>British Columbia V5C 2K5, Canada </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="card contact-col b-none">
                <img src="./images/map.svg" alt="..." />
                <h5>{contactInformation[7].workingHours} </h5>
                <p> 
                   Monday - Friday: 10AM - 5PM<br/>
                  Saturday - Sunday: 10AM - 12PM</p>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-5 col-md-5 col-sm-12 col-12">
          <div className="contact-from-box">
            <h3>{contactInformation[4].getInTouch} </h3>
            <p>{contactInformation[5].contactUsDescription} </p> 
            <form  className="form-inline" onSubmit={Submitdata} >
              <div className="row mt-2">
                <div className="col-md-6">
                  <div className="form-floating mb-3">
                    <input className="form-control" onChange={(e)=>{return setFirstName(e.target.value),setFirstNameColor('lightgrey')}} style={{borderColor: firstNameColor}} type="text" placeholder="First name"/>
                    <span className="form-icon"> <i className="fa-regular fa-user"><FaRegUser /></i> </span>
                    <label > First name</label>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-floating mb-3">
                    <input className="form-control" onChange={(e)=>{return setEmail(e.target.value),setEmailColor('lightgrey')}} style={{borderColor: emailColor}} type="email" placeholder="Last Name"  />
                    <span className="form-icon"> <i className="fa-solid fa-envelope"><MdEmail /></i> </span>
                    <label> Enter email address </label>
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-12">
                  <div className="form-floating contact-text-area mb-3">
                    <textarea className="form-control" onChange={(e)=>{return setMessage(e.target.value),setMessageColor('lightgrey')}} style={{borderColor: messageColor ,height:'110px',paddingTop:'35px'}} rows="5" placeholder="Leave your message..." ></textarea>

                    <span className="form-icon"> <i className="fa-solid fa-envelope"><MdEmail /></i> </span>
                    <label> Leave your message...</label>
                  </div>
                </div>
              </div>
               <p>{messageError}</p> 
              <div className="row mt-2">
                <div className="col-md-12">
                  <div className="d-grid">
                    <button className="btn btn-primary btn-lg submitButton" id="submitButton" type="submit">
                      {loading ? 'Processing...' : 'Submit' }  </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <Modal show={showOtpModal} onHide={() => setShowOTPModal(false)} className="modal fade wx_thankYouModal" centered>
      <Modal.Dialog centered>
        <div className="modal-header border-0">
          <img src="./images/myprofile/rightsignic.svg" alt="" />
          <img src="./images/myprofile/closeic.svg" className="btn-close" onClick={() => setShowOTPModal(false)} />
        </div>
        <Modal.Body className="text-center">
          <h4>Thank You!</h4>
          <p>We've received your message. Someone from our team will contact you soon.</p>
        </Modal.Body>
      </Modal.Dialog>
    </Modal>

  </>)
}
export default ContactUS 