import axios from 'axios';
import CryptoJS from 'crypto-js';

/*----------------------------------------------------------------------Create Axios Header-----------------------------------------------------------------*/
export const customAxios = axios.create({
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});

customAxios.interceptors.request.use(config => {
  const token = JSON.parse(localStorage.getItem('Token_access'));
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  return config;
});

/*------------------------------------------------------------------------Get the token-----------------------------------------------------------------------*/

export const token = JSON.parse(localStorage.getItem('Token_access'))

export function getToken() {
  return JSON.parse(localStorage.getItem('Token_access'));
}

/*----------------------------------------------------------------------Decrypted UserProfile Data-----------------------------------------------------------------*/

export const decrypteData = () => {
  const storedData = localStorage.getItem('u/\s/p/r\e');
  if (storedData) {
    const decryptedData = CryptoJS.AES.decrypt(storedData, 'secret key').toString(CryptoJS.enc.Utf8);
    const userProfile = JSON.parse(decryptedData);
    return userProfile
  }
}

/*----------------------------------------------------------------------Decrypted PillReminder Data-----------------------------------------------------------------*/

export const decryptPillReminderData = () => {

  const storedData = localStorage.getItem('p/l/r\/m/n/d/\r');
  if (storedData) {
    const decryptedData = CryptoJS.AES.decrypt(storedData, 'secret key').toString(CryptoJS.enc.Utf8);
    const pillRemainder = JSON.parse(decryptedData);
    return pillRemainder
  }
}

/*----------------------------------------------------------------------Decrypted NextPillReminderData Data-----------------------------------------------------------------*/

export const decryptNextillRemndrData = () => {

  const storedData = localStorage.getItem('n/x\t/r/m/n/d/r');
  if (storedData) {
    const decryptedData = CryptoJS.AES.decrypt(storedData, 'secret key').toString(CryptoJS.enc.Utf8);
    const nextPill = JSON.parse(decryptedData);
    return nextPill
  }
}

/*----------------------------------------------------------------------Decrypted Pills Data-----------------------------------------------------------------*/

export const decryptPillsData = () => {

  const storedData = localStorage.getItem('pillsdata');
  if (storedData) {
    const decryptedData = CryptoJS.AES.decrypt(storedData, 'secret key').toString(CryptoJS.enc.Utf8);
    const pillsdata = JSON.parse(decryptedData);
    return pillsdata
  }
}

/*----------------------------------------------------------------------Decrypted OrderId Data-----------------------------------------------------------------*/
export const decryptOrderID = () => {
  const storedData = localStorage.getItem('O/[]r/d/I/d');
  if (storedData) {
    const orderID = CryptoJS.AES.decrypt(storedData, 'secret key').toString(CryptoJS.enc.Utf8);
    const orderIDs = JSON.parse(orderID);
    return orderIDs
  }
}

/*----------------------------------------------------------------------Decrypted OrderDate Data-----------------------------------------------------------------*/
export const decryptOrderDate = () => {
  const storedData = localStorage.getItem('O/[]r/date');
  if (storedData) {
    const orders = CryptoJS.AES.decrypt(storedData, 'secret key').toString(CryptoJS.enc.Utf8);
    const orderDate = JSON.parse(orders);
    return orderDate
  }
}

/*----------------------------------------------------------------------Decrypted PharmacyId Data-----------------------------------------------------------------*/
export const decryptPharmacyId = () => {
  const storedData = localStorage.getItem('p/\h/r\m/c\/i[]d');
  if (storedData) {
    const pharmacy = CryptoJS.AES.decrypt(storedData, 'secret key').toString(CryptoJS.enc.Utf8);
    const pharmacyId = JSON.parse(pharmacy);
    return pharmacyId
  }
}
