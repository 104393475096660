import LogOut from "../logOut/logOut";
import List from "../medicineScheudle/list";
import MedicineScheudle from "../medicineScheudle/medicineScheudle";
import SetProfile from "../profile/setProfile";
import { Tabs, Tab } from 'react-bootstrap';
import React, { useRef, useEffect, useState } from "react";
import { pillRemainderUrl } from "../sharedApi/common";
import { useNavigate } from "react-router-dom";
import { AppUrl } from "../sharedApi/common";
import { decryptPillsData } from "../sharedApi/customeAxios";
import "react-datepicker/dist/react-datepicker.css";
import $ from 'jquery';
import 'bootstrap';
import 'bootstrap-datepicker';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import LogoutTimer from "../logOut/logOutTimer";

const SetReminder = (props) => {
  const [pillName, setPillName] = useState('')
  const [medicationQty, setMedicationQty] = useState('')
  const [unit, setUnit] = useState('pills')
  const [meal, setMeal] = useState('')
  const [reminder, setReminder] = useState('');
  const [qtyToConsume, setQtyToConsume] = useState('')
  const [pillColor, setPillColor] = useState('lightgrey')
  const [medicationQtyColor, setMedicationQtyColor] = useState('lightgrey')
  const [mealColor, setMealColor] = useState('lightgrey')
  const [reminderColor, setReminderColor] = useState('lightgrey')
  const [qtyToConsumeColor, setQtyToConsumeColor] = useState('lightgrey')
  const [loading, setLoading] = useState(false)
  const [pillWeekDays, setPillWeekDays] = useState([])
  const [dateRange, setDateRange] = useState([]);
  const [monthlyDate, setMonthlyDate] = useState([])
  const [weekDaysColor, setWeekDaysColor] = useState('lightgrey')
  const [continueWeeks, setContinueWeeks] = useState('')
  const [continueWeekColors, setContinueWeekColors] = useState('lightgrey')
  const [messageError, setMessageError] = useState('')
  const [continueMonths, setContinueMonths] = useState('')
  const [continueMonthsColors, setContinueMonthsColor] = useState('lightgrey')
  const [messageMonthError, setMessgaeMonthError] = useState('')
  const [msgErr, setMsgErr] = useState('')
  const [dateArray, setDateArray] = useState([]);

  const navigate = useNavigate();

  const submitPillRemainder = async () => {
    let token = JSON.parse(localStorage.getItem('Token_access'))
    setLoading(true)
    const rawResponse = await fetch(pillRemainderUrl, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + token,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'pill_name': pillName, 'medication_total_qty': medicationQty, unit: unit, dose: medicationQty, 'note': meal, 'remind_at': reminder, 'reminder_type': 'daily', 'days': qtyToConsume, 'week_day': '6'
      })
    })
    const result = await rawResponse.json();
    setLoading(false)
    // console.log(result);

    if (result.code === 400) {
      setMsgErr('Something Went Wrong Please Try Again Later !')
    }else {
      navigate('/PillReminder')
    }
    return result;
  }

  const submitData = () => {
    if (!pillName) {
      setPillColor('#FF4200')
    }else if (!medicationQty) {
      setMedicationQtyColor('#FF4200')
    }else if (!meal) {
      setMealColor('#FF4200')
    }else if (!reminder) {
      setReminderColor('#FF4200')
    }else if (!qtyToConsume) {
      setQtyToConsumeColor('#FF4200')
    }else if (selectedId) {
      updateDailyDays();
    }else {
      submitPillRemainder()
    }
  }

  const updateDailyDays = async () => {
    let token = JSON.parse(localStorage.getItem('Token_access'))
    setLoading(true)

    if (selectedId) {
      const rawResponse = await fetch(`${AppUrl}/api/pill-reminder/update/${selectedId}`, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + token,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          'pill_name': pillName, 'medication_total_qty': medicationQty, unit: unit, dose: medicationQty, 'note': meal, 'remind_at': reminder, 'reminder_type': 'daily', 'days': qtyToConsume, 'week_day': '6', repeat_cycle: qtyToConsume
        })
      })
      const result = await rawResponse.json();
      // console.log(result);
      setLoading(false)

      if (result.code === 400) {
        setMsgErr('Something Went Wrong Please try Again!!')
      }
      else {
        navigate('/PillReminder')
      }
      return result;
    }
  }

  const [weeklyTime, setWeeklyTime] = useState('')
  const [weeklyColor, setWeeklyColor] = useState('lightgrey')


  const handleWeekly = async () => {
    let token = JSON.parse(localStorage.getItem('Token_access'))
    setLoading(true)
    if (token) {
      const rawResponse = await fetch(pillRemainderUrl, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + token,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          'pill_name': pillName, 'medication_total_qty': medicationQty, unit: unit, dose: medicationQty, 'note': meal, 'remind_at': weeklyTime, 'reminder_type': 'weekly', 'week_day': pillWeekDays, 'repeat_cycle': continueWeeks
        })
      })
      const result = await rawResponse.json();
      // console.log(result);
      setLoading(false)

      if (result.code === 400) {
        setMessageError('Something Went Wrong Please try Again!!')
      }else {
        navigate('/PillReminder')
      }
      return result;
    }
  }

  const submitWeekDays = () => {

    if (!pillName) {
      setPillColor('#FF4200')
    }else if (!medicationQty) {
      setMedicationQtyColor('#FF4200')
    }else if (!meal) {
      setMealColor('#FF4200')
    }else if (!weeklyTime) {
      setWeeklyColor('#FF4200')
    }else if (!continueWeeks) {
      setContinueWeekColors('#FF4200')
    }else if (selectedId) {
      updateWeekDays()
    }else {
      handleWeekly();
    }
  }

  function handleWeekDays(event) {
    const { value, checked } = event.target;
    if (checked) {
      setPillWeekDays(prevSelectedDays => [...prevSelectedDays, value]);
    } else {
      setPillWeekDays(prevSelectedDays => prevSelectedDays.filter(day => day !== value));
    }
    setWeekDaysColor('lightgrey')
  }

  const [monthyReminder, setMonthlyReminder] = useState('')
  const [monthlyColor, setMonthlyColor] = useState('lightgrey')

  const handleMonthly = async () => {
    let token = JSON.parse(localStorage.getItem('Token_access'))
    setLoading(true)
    if (token) {
      const rawResponse = await fetch(pillRemainderUrl, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + token,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          'pill_name': pillName, 'medication_total_qty': medicationQty, unit: unit, dose: medicationQty, 'note': meal, 'remind_at': monthyReminder, 'reminder_type': 'monthly', 'monthly_date': monthlyDate, 'repeat_cycle': continueMonths,
        })
      })
      const result = await rawResponse.json();
      // console.log(result);
      setLoading(false)

      if (result.code === 400) {
        setMessgaeMonthError('Something Went Wrong Please try Again!!')
      }
      else {
        navigate('/PillReminder')
      }
      return result;
    }
  }

  const submitMonthlyData = () => {

    if (!pillName) {
      setPillColor('#FF4200')
    }
    else if (!medicationQty) {
      setMedicationQtyColor('#FF4200')
    }
    else if (!meal) {
      setMealColor('#FF4200')
    }
    else if (!monthyReminder) {
      setMonthlyColor('#FF4200')
    }
    else if (!continueMonths) {
      setContinueMonthsColor('#FF4200')
    }
    else if (selectedId) {
      updateMonths()
    }
    else {
      handleMonthly()
    }
  }

  const [tabKey, setTabKey] = useState('home')
  const [selectedId, setSelectedId] = useState('')

  useEffect(() => {
    // const pills_data = JSON.parse(localStorage.getItem('pillsdata'))

    const pills_data = decryptPillsData();

    if (pills_data) {
      setPillName(pills_data.pill_name)
      setMedicationQty(pills_data.medication_total_qty)
      setMeal(pills_data.note)
      setQtyToConsume(pills_data.days)
      setUnit(pills_data.unit)
      setSelectedId(pills_data.id)

      if (pills_data.reminder_type === "monthly") {
        setTabKey('longer-tab')
        setContinueMonths(pills_data.repeat_cycle)
        setMonthlyReminder(pills_data.remind_at)
        setMonthlyDate(pills_data.monthly_date) 
      }

      if (pills_data.reminder_type === "weekly") {
        setTabKey('profile')
        setWeeklyTime(pills_data.remind_at)
        setMonthlyReminder(pills_data.repeat_cycle)
        setContinueWeeks(pills_data.repeat_cycle)
        setPillWeekDays(pills_data.week_day)
      }

      if (pills_data.reminder_type === "daily") {
        setReminder(pills_data.remind_at)
      }
    }
  }, [])

  const updateWeekDays = async () => {
    let token = JSON.parse(localStorage.getItem('Token_access'))
    setLoading(true)
    if (selectedId) {
      const rawResponse = await fetch(`${AppUrl}/api/pill-reminder/update/${selectedId}`, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + token,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          'pill_name': pillName, 'medication_total_qty': medicationQty, unit: unit, dose: medicationQty, 'note': meal, 'remind_at': weeklyTime, 'reminder_type': 'weekly', 'week_day': pillWeekDays, 'repeat_cycle': continueWeeks
        })
      })
      const result = await rawResponse.json();
      // console.log(result);
      setLoading(false)
      if (result.code === 400) {
        setMessageError('Something Went Wrong Please try Again!!')
      }
      else {
        navigate('/PillReminder')
      }
      return result;
    }
  }

  const updateMonths = async () => {
    let token = JSON.parse(localStorage.getItem('Token_access'))
    setLoading(true)
    if (selectedId) {
      const rawResponse = await fetch(`${AppUrl}/api/pill-reminder/update/${selectedId}`, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + token,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          'pill_name': pillName, 'medication_total_qty': medicationQty, unit: unit, dose: medicationQty, 'note': meal, 'remind_at': monthyReminder, 'reminder_type': 'monthly', 'monthly_date': monthlyDate, 'repeat_cycle': continueMonths,
        })
      })
      const result = await rawResponse.json();
      // console.log(result);
      setLoading(false)

      if (result.code === 400) {
        setMessgaeMonthError('Something Went Wrong Please try Again!!')
      }
      else {
        navigate('/PillReminder')
      }
      return result;
    }
  }

  const prevDateRef = useRef('');

  const startDateRef = useRef(null);

  useEffect(() => {
    if (startDateRef.current) {
      $(startDateRef.current).datepicker({
        format: 'yyyy-mm-dd',
        startDate: new Date(),
        autoclose: true,
        todayHighlight: true,
      }).on('changeDate', function (e) {
        const selectedDate = e.target.value;
        if (!dateArray.includes(selectedDate) && selectedDate !== prevDateRef.current) {
          setDateArray(prevArray => [...prevArray, selectedDate]);
          prevDateRef.current = selectedDate;
        }
        setMonthlyDate(selectedDate);
      });
    }
  }, [startDateRef, dateArray]);

  const handleClick = () => {
    $(startDateRef.current).datepicker('show');
  };

  function deleteImage(index) {
    const del = dateArray.filter((elem, id) => {
      return index != id;
    })
    setDateArray(del);
  }

  const activeLink = 2;


  return (<>
    <HelmetProvider>
          <Helmet>
            <meta name="robots" content="noindex,nofollow" />
        </Helmet>
    </HelmetProvider>

    <LogoutTimer onLogout={props.logOut} />        

    <section className="dark-back">
      {/* <div className="container"> */}
        <div className="profile-2-col">
          <div className="container justify-content-center align-items-center">
            <div className="row">
              <div className="col-md-4">
                <SetProfile day={props.day} />
                <MedicineScheudle />
                <List activeLink={activeLink}/>
                <LogOut onLogout={props.logOut} />
              </div>

              <div className="col-md-8">
                <div className="profile-right-col">
                  <div className="card wx_pillRemainderBg">
                    <div className="card-header">
                      <h5>Set Pill Reminder</h5>
                    </div>

                    <div className="card-body">
                      <form action="">
                        <div className="row">
                          <div className="col-md-6">
                            {/* <h6>Pill Name</h6> */}
                            <div className="input-group mb-3">
                              <span className="input-group-text"><img src="./images/pillname.svg" alt="" /></span>
                              <div className="form-floating">
                                <input type="text" value={pillName} onChange={(e) => { setPillName(e.target.value); setPillColor('lightgrey') }} className="form-control" id="floatingInputGroup1" placeholder="Pill name"
                                  style={{ borderColor: pillColor }} />
                                <label htmlFor="floatingInputGroup1">Pill name</label>
                              </div>
                              <span className="input-group-text"><img src="./images/scanner.svg" alt="" /></span>
                            </div>
                          </div>

                          <div className="col-md-6">
                            {/* <h6>Qty. to consume</h6> */}
                            <div className="input-group mb-3">
                              <span className="input-group-text"><img src="./images/consume.svg" alt="" /></span>
                              <div className="form-floating wx_CunsumeSelect">
                                <input type="text" className="form-control" onKeyDown={(e) => { if (!/^\d+$/.test(e.key) && (e.key !== "Backspace") && e.keyCode !== 9) { e.preventDefault(); } }} value={medicationQty} id="floatingInputGroup1" placeholder="Enter consume" onChange={(e) => { setMedicationQty(e.target.value); setMedicationQtyColor('lightgrey') }} style={{ borderColor: medicationQtyColor }} />
                                <label htmlFor="floatingInputGroup1">Qty. to consume</label>
                                <select onChange={(e) => { setUnit(e.target.value) }} style={{ borderRadius: '8px ' }} value={unit}>
                                  <option defaultValue>pills</option>
                                  <option value="Capsule" >Capsule</option>
                                  <option value="Drops"  >Drops</option>
                                  <option value="Injections">Injections</option>
                                  <option value="Milligrams">Milligrams</option>
                                  <option value="Milliliter">Milliliter</option>
                                  <option value="Spray">Spray</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            {/* <h6>How to use?</h6> */}
                            <div className="input-group mb-3">
                              <span className="input-group-text"><img src="./images/howtouse.svg" alt="" /></span>
                              <div className="form-floating">
                                <input type="text" value={meal} onChange={(e) => { setMeal(e.target.value); setMealColor('lightgrey') }} className="form-control" style={{ borderColor: mealColor }} id="floatingInputGroup1" placeholder="Write your notes..." />
                                <label htmlFor="floatingInputGroup1">How to use?</label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <Tabs activeKey={tabKey} id="fill-tab-example nav nav-tabs" className="mb-3" fill onSelect={(k) => setTabKey(k)} >
                              <Tab eventKey="home" title="Daily" >
                                {/* <div className="row">
                                  <div className="col">
                                    <div className="wx_dayOfWeekSec">
                                      <h6>Day of Week</h6>
                                    </div>
                                    <div className="AddWeekly">
                                      <span>Sunday</span>
                                      <span>Monday</span>
                                      <span>Tuesday</span>
                                      <span>Wednesday</span>
                                      <span>Thursday</span>
                                      <span>Friday</span>
                                      <span>Saturday</span>
                                    </div>
                                  </div>
                                </div> */}


                                <div className="tab-content" id="myTabContent">
                                  <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                                    <div className="wx_dayOfWeekBtn mt-3">
                                      <div className="row">
                                        <div className="col-md-6">
                                          {/* <h6>Set Reminder Time</h6> */}
                                          <div className="input-group mb-3">
                                            <span className="input-group-text"><img src="./images/remainder.svg" alt="" /></span>
                                            <div className="form-floating">
                                              {/* <input type="time" value={reminder} onChange={(e) => { setReminder(e.target.value); setReminderColor('lightgrey') }} style={{ borderColor: reminderColor }} className="form-control" id="floatingInputGroup1" placeholder="10:00 AM" /> */}
                                              {/* <label htmlFor="floatingInputGroup1">10:00 AM</label> */}
                                             

                                                <input type="time" value={reminder} onChange={(e) => { setReminder(e.target.value); setReminderColor('lightgrey') }} style={{ borderColor: reminderColor }} className="form-control"  placeholder="10:00 AM" id="session-date" name="session-date" />
                                                <label htmlFor="session-date">Set Reminder Time</label>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-md-6">
                                          {/* <h6>Continue to</h6> */}
                                          <div className="input-group mb-3">
                                            <span className="input-group-text"><img src="./images/continueto.svg" alt="" /></span>
                                            <div className="form-floating">
                                              <input type="text" value={qtyToConsume} onKeyDown={(e) => { if (!/^\d+$/.test(e.key) && (e.key !== "Backspace") && e.keyCode !== 9) { e.preventDefault(); } }} onChange={(e) => { setQtyToConsume(e.target.value); setQtyToConsumeColor('lightgrey') }} style={{ borderColor: qtyToConsumeColor }} className="form-control" id="floatingInputGroup1" placeholder="7" />
                                              <label htmlFor="floatingInputGroup1">Continue to</label>
                                            </div>
                                            <span className="input-group-text"><h6>Days</h6></span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <p style={{ textAlign: 'center' }}>{msgErr}</p>
                                    <div className="row">
                                      <div className="col text-center mt-3">
                                        <button type="button" className="btn wx_SetReminder" onClick={submitData}>
                                          {loading ? 'Processing..' : 'Set Reminder'}
                                        </button>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabIndex="0">...</div>
                                </div>
                              </Tab>

                              <Tab eventKey="profile" title="Weekly" >
                                <div className="row">
                                  <div className="col">
                                    <div className="wx_dayOfWeekSec wx_dayOfWeeklySec">
                                      <h6>Day of Week</h6>
                                    </div>

                                    <div className="checkedBgMain">
                                      <div className="form-check checkedBg">
                                        <input className="form-check-input" type="checkbox" value="sunday"  id="check1" onChange={handleWeekDays} checked={pillWeekDays.includes("sunday")} style={{ borderColor: weekDaysColor }} />
                                        <label className="form-check-label ns-2" htmlFor="check1">
                                          Sunday
                                        </label>
                                      </div>

                                      <div className="form-check checkedBg">
                                        <input className="form-check-input" type="checkbox" value="monday" onChange={handleWeekDays} checked={pillWeekDays.includes("monday")} id="check2" />
                                        <label className="form-check-label ns-2" htmlFor="check2">
                                          Monday
                                        </label>
                                      </div>

                                      <div className="form-check checkedBg">
                                        <input className="form-check-input" type="checkbox" value="tuesday" id="check3" checked={pillWeekDays.includes("tuesday")} onChange={handleWeekDays}/>
                                        <label className="form-check-label ns-2" htmlFor="check3" >
                                          Tuesday
                                        </label>
                                      </div>

                                      <div className="form-check checkedBg">
                                        <input className="form-check-input" type="checkbox" value="wednesday" onChange={handleWeekDays} checked={pillWeekDays.includes("wednesday")} id="check4" />
                                        <label className="form-check-label ns-2" htmlFor="check4">
                                          Wednesday
                                        </label>
                                      </div>

                                      <div className="form-check checkedBg">
                                        <input className="form-check-input" type="checkbox" value="thursday" onChange={handleWeekDays}  checked={pillWeekDays.includes("thursday")} id="check5" />
                                        <label className="form-check-label ns-2" htmlFor="check5">
                                          Thursday
                                        </label>
                                      </div>

                                      <div className="form-check checkedBg">
                                        <input className="form-check-input" type="checkbox" value="friday" onChange={handleWeekDays} checked={pillWeekDays.includes("friday")} id="check6" />
                                        <label className="form-check-label ns-2" htmlFor="check6">
                                          Friday
                                        </label>
                                      </div>

                                      <div className="form-check checkedBg">
                                        <input className="form-check-input" type="checkbox" value="saturday" onChange={handleWeekDays} checked={pillWeekDays.includes("saturday")} id="check7" />
                                        <label className="form-check-label ns-2" htmlFor="check7">
                                          Saturday
                                        </label>
                                      </div>

                                    </div>
                                  </div>
                                </div>

                                <div className="tab-content" id="myTabContent">
                                  <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                                    <div className="wx_dayOfWeekBtn mt-3">
                                      <div className="row">
                                        <div className="col-md-6">
                                          {/* <h6>Set Reminder Time</h6> */}
                                          <div className="input-group mb-3">
                                            <span className="input-group-text"><img src="./images/remainder.svg" alt="" /></span>
                                            <div className="form-floating">
                                              {/* <input type="time" value={weeklyTime} onChange={(e) => { setWeeklyTime(e.target.value); setWeeklyColor('lightgrey') }} style={{ borderColor: weeklyColor }} className="form-control" id="floatingInputGroup1" placeholder="10:00 AM" />
                                              <label htmlFor="floatingInputGroup1">10:00 AM</label> */} 

                                              <input type="time"  value={weeklyTime} onChange={(e) => { setWeeklyTime(e.target.value); setWeeklyColor('lightgrey') }} style={{ borderColor: weeklyColor }}  className="form-control"  placeholder="10:00 AM" id="session-date" name="session-date" />
                                                <label htmlFor="session-date">Set Reminder Time</label>



                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-md-6">
                                          {/* <h6>Continue to</h6> */}
                                          <div className="input-group mb-3">
                                            <span className="input-group-text"><img src="./images/continueto.svg" alt="" /></span>
                                            <div className="form-floating">
                                              <input type="text" onKeyDown={(e) => { if (!/^\d+$/.test(e.key) && (e.key !== "Backspace")&& e.keyCode !== 9) { e.preventDefault(); } }} value={continueWeeks} onChange={(e) => { setContinueWeeks(e.target.value); setContinueWeekColors('lightgrey') }} style={{ borderColor: continueWeekColors }} className="form-control" id="floatingInputGroup1" placeholder="7" />
                                              <label htmlFor="floatingInputGroup1">Continue to</label>
                                              
                                            </div>
                                            <span className="input-group-text"><h6>Weeks</h6></span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <p style={{ textAlign: 'center' }}>{messageError} </p>
                                    <div className="row">
                                      <div className="col text-center mt-3">
                                        <button type="button" className="btn wx_SetReminder" onClick={submitWeekDays}>
                                          {loading ? 'Processing..' : 'Set Reminder'}
                                        </button>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabIndex="0">...</div>
                                </div>
                              </Tab>

                              <Tab eventKey="longer-tab" title="Monthly">
                                <div className="row" >
                                  <div className="col" >
                                    <div className="wx_MonthlyDateBtn form-picker" >
                                      <p id='startDate' onClick={handleClick}>
                                        <img src="./images/calendaric.svg" className="me-2" alt="" placeholder="" />
                                        <label>
                                          <input type="text" ref={startDateRef} style={{ position: 'relative', top: 15, border: 'none', outline: 'none', backgroundColor: '#F5F5FB', color: '#F5F5FB' }} />
                                      <label className="btn" style={{position:"relative",bottom:18,left:25}} onClick={handleClick}> Add Monthly Date</label>

                                        </label>
                                      </p>

                                      {/* <div className="btn" style={{}} onClick={handleClick}> Add Monthly Date</div> */}

                                    </div>

                                    <div className="wx_MonthlyDate">

                                      {dateArray && dateArray.length > 0 ? (
                                        <>
                                          {dateArray.map((date, index) => (
                                            <p style={{ margin: '5px' }} key={index} >{date}
                                              <img src="./images/closeic.svg" alt="" className="btn" onClick={() => deleteImage(index)} />
                                            </p>
                                          ))}
                                        </>) : null
                                      }

                                    </div>
                                  </div>
                                </div>

                                <div className="tab-content" id="myTabContent">
                                  <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                                    <div className="wx_dayOfWeekBtn mt-3">
                                      <div className="row">
                                        <div className="col-md-6">
                                          {/* <h6>Set Reminder Time</h6> */}
                                          <div className="input-group mb-3">
                                            <span className="input-group-text"><img src="./images/remainder.svg" alt="" /></span>
                                            <div className="form-floating">
                                              {/* <input type="time" value={monthyReminder} onChange={(e) => { setMonthlyReminder(e.target.value); setMonthlyColor('lightgrey') }} style={{ borderColor: monthlyColor }} className="form-control" id="floatingInputGroup1" placeholder="10:00 AM"
                                              />
                                              <label htmlFor="floatingInputGroup1">10:00 AM</label> */}

                                    <input type="time"  value={monthyReminder} onChange={(e) => { setMonthlyReminder(e.target.value); setMonthlyColor('lightgrey') }} style={{ borderColor: monthlyColor }}   className="form-control"  placeholder="10:00 AM" id="session-date" name="session-date" />
                                    
                                    <label htmlFor="session-date">Set Reminder Time</label>


                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-md-6">
                                          {/* <h6>Continue to</h6> */}
                                          <div className="input-group mb-3">
                                            <span className="input-group-text">
                                              {/* <BsPlay /> */}
                                              <img src="./images/continueto.svg" alt="" />
                                            </span>
                                            <div className="form-floating">

                                              <input type="text" onKeyDown={(e) => { if (!/^\d+$/.test(e.key) && (e.key !== "Backspace")&& e.keyCode !== 9) { e.preventDefault(); } }} value={continueMonths} onChange={(e) => { setContinueMonths(e.target.value); setContinueMonthsColor('lightgrey') }} style={{ borderColor: continueMonthsColors }} className="form-control" id="floatingInputGroup1" placeholder="7" />
                                              <label htmlFor="floatingInputGroup1">Continue to</label>
                                            </div>
                                            <span className="input-group-text"><h6>Months</h6></span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <p style={{ textAlign: 'center' }}>{messageMonthError}</p>
                                    <div className="row">
                                      <div className="col text-center mt-3">
                                        <button type="button" className="btn wx_SetReminder" onClick={submitMonthlyData}>
                                          {loading ? 'Processing..' : 'Set Reminder'}
                                        </button>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabIndex="0">...</div>
                                </div>
                              </Tab>
                            </Tabs>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      {/* </div> */}
    </section>

  </>)
}
export default SetReminder;