import { Link } from "react-router-dom"

const Error = () => {
  return (<>
    <div className="container">
      <div className="row">
        <div className="col">
          <div className="wx_errorBg text-center">
            <img src="./images/myprofile/error.svg" alt="" />
            <h2 className="mt-3">Page Not Found</h2>
            <p className="mb-0">The page you are looking for is not available. The page may have been </p>
            <p>deleted or unpublished.</p>
            <Link className="nav-link btn btn-hero" to='/'>Back To Home </Link>
          </div>
        </div>
      </div>
    </div>

    <footer className="wx_errorFooterBg">
      <div className="container">
        <div className="row">
          <div className="col text-center">
            <p>© 2022 - <a  >Weldon Pharmacy</a>. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>

  </>)
}
export default Error