import { Link } from "react-router-dom";
import { HiArrowTopRightOnSquare } from 'react-icons/hi2'
import List from "../medicineScheudle/list";
import MyProfile from '../profile/myProfile'
import LogOut from "../logOut/logOut";
import MedicineScheudle from "../medicineScheudle/timeTotakePill";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import LogoutTimer from "../logOut/logOutTimer";

const DashBoard = (props) => {
  const activeLink = 0; 
  return (<>

 <HelmetProvider>
    <Helmet>
      <meta name="robots" content="noindex,nofollow" />
      <title>WeldonRx</title>
  </Helmet>
</HelmetProvider> 

    <section className="dark-back" >
      <div className="profile-2-col">
        <div className="container justify-content-center align-items-center">
          <div className="row">
            <div className="col-md-4">
              <MyProfile day={props.day} />
              <MedicineScheudle />
              <List />
              <LogOut logOut={props.logOut} />
              <LogoutTimer onLogout={props.logOut} />        
              </div>

            <div className="col-md-8">
              <div className="dashboard">
                <div className="row">                  
                  <div className="col-md-6">
                    <div className="dashboard-col">
                      <h4> New Prescription </h4>
                      <p> Start Fresh </p>
                      <Link to='/NewPrescription'> Start Now <i className="fa-sharp fa-solid fa-arrow-up-right-from-square">
                        <HiArrowTopRightOnSquare size={22} style={{ marginBottom: '5px' }} /></i></Link>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="dashboard-col">
                      <h4> Transfer Prescription </h4>
                      <p> Move to us</p>
                      <Link to='/RxTransfer' > Transfer Now <i className="fa-sharp fa-solid fa-arrow-up-right-from-square">
                        <HiArrowTopRightOnSquare size={22} style={{ marginBottom: '5px' }} /></i></Link>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="dashboard-col">
                      <h4> Pill Reminder </h4>
                      <p> Setup and leave on us</p>
                      <Link to='/PillReminder' > Set Reminder <i className="fa-sharp fa-solid fa-arrow-up-right-from-square">
                        <HiArrowTopRightOnSquare size={22} style={{ marginBottom: '5px' }} /></i></Link>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="dashboard-col">
                      <h4> Track Your Orders </h4>
                      <p> Simple Steps</p>
                      <Link to="/orderList"> Track Now <i className="fa-sharp fa-solid fa-arrow-up-right-from-square">
                        <HiArrowTopRightOnSquare size={22} style={{ marginBottom: '5px' }} /></i></Link>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}
export default DashBoard;