import { createOrderUrl } from "../sharedApi/common";
import axios from "axios";
import { useEffect, useState, useMemo } from "react";
import Modals from "../main/modalTwo";
import MedicineScheudle from "../medicineScheudle/medicineScheudle";
import List from "../medicineScheudle/list";
import LogOut from "../logOut/logOut";
import SetProfile from "../profile/setProfile";
import { useLocation } from "react-router-dom";
import { decrypteData } from "../sharedApi/customeAxios";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Modal from 'react-bootstrap/Modal'
import LogoutTimer from "../logOut/logOutTimer";

const NewPrescription = (props) => {

  const [ipAddress, setIpAddress] = useState(null);
  const [errMsg, setErrMsg] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const [baseImages, setBaseImages] = useState([]);
  const [imgNames, setImgNames] = useState([]);
  const [loading, setLoading] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [orderError, setOrderError] = useState('')
  const [shippingId, setShippingId] = useState([])
  const [billingId, setBillingId] = useState([])
  const [showOTPModal, setShowOTPModal] = useState(false);

  const location = useLocation();

  const getProfileFromStorage = () => decrypteData();

  const [profile, setProfile] = useState(() => getProfileFromStorage());

  const [multipleShippingAddress, setMultipleShippingAddress] = useState([])
  const [defaultShippingAddress, setDefaultShippingAddress] = useState([])


  useMemo(() => {
    const myProfile = getProfileFromStorage();
    if (myProfile) {
      setProfile(myProfile);
      setMultipleShippingAddress(myProfile?.shipping_address)
      setDefaultShippingAddress(myProfile?.user_default_shipping_address)


      if (myProfile?.user_default_shipping_address == undefined) {
        return
      } else {
        setShippingId(myProfile?.user_default_shipping_address.id)
      }

      if (myProfile?.user_default_billing_address == undefined) {
        return
      } else {
        setBillingId(myProfile?.user_default_billing_address.id)
      }
    }
  }, [location]);

  // useEffect(() => {
  //   console.log(multipleShippingAddress)
  // },[])

  useEffect(() => {
    if (!profile) {
      setProfile(getProfileFromStorage());
    }
  }, [profile]);

  useEffect(() => {
    fetch('https://geolocation-db.com/json/2725d960-5eef-11ed-9b62-857a2b26943e').then((response) =>
      response.json()).then((data) => setIpAddress(data.IPv4))
  }, [])

  const uploadImage = async (e) => {
    const fileObj = e.target.files;
    const newBaseImages = [...baseImages];
    const newImgNames = [...imgNames];
    for (let i = 0; i < fileObj.length; i++) {
      const file = fileObj[i];
      const base64Obj = await convertBase64(file);
      newBaseImages.push(base64Obj.base64.replace(/^data:image\/[a-z]+;base64,/, ""));
      newImgNames.push(base64Obj.name);
    }
    setBaseImages(newBaseImages);
    setImgNames(newImgNames);
    setErrMsg("");
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve({
          base64: fileReader.result,
          name: file.name,
        });
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleSippingId = (id) => {
    setShippingId(id)
  }

  const OrderDetails = () =>{
    let token = JSON.parse(localStorage.getItem('Token_access'))
    setProcessing(true)
      setOrderError('')
      const formdata = new FormData();
      formdata.append("customer_ip", ipAddress);
      for (let i = 0; i < baseImages.length; i++) {
        formdata.append(`prescription_image[${i}]`, baseImages[i]);
      }
      formdata.append("is_transfer", "0");
      formdata.append('shipping_add_id', shippingId);
      formdata.append('billing_add_id', shippingId);
      axios
        .post(createOrderUrl, formdata, {
          headers: {
            Authorization: "Bearer " + token,
            Accept: "application/json",
          },
        })
        .then((response) => {
          if (response.data) {
            setProcessing(false)
            setLoading(true)
            setShowOTPModal(false)
            // setTimeout(()=>{
              setModalShow(true)
            // },2000)
          } else {
            setProcessing(false)
          }
        })
        .catch((error) => {
          setProcessing(false)
          setOrderError("Something Went Gone Wrong Try Again Later !!")
          setShowOTPModal(false)
        });
    }
  


  const submitImages = () => {
    if (baseImages.length === 0) {
      setErrMsg("Prescription image is required");
      setModalShow(false)
      return;
    }

    if (profile.shipping_address.length > 0) {
      setShowOTPModal(true)
      setShippingId('')
      setShipMessageError('')
    }else{
      // setShowOTPModal(true)
      // setShippingId('')
      setShipMessageError('')
      OrderDetails()
    }
  };

  function deleteImage(index) {
    const del = baseImages.filter((elem, id) => {
      return index != id;
    })
    setBaseImages(del);
  }

  const [shipMessageError, setShipMessageError] = useState('')

  const submitOrder = () => {
    console.log(shippingId)
    if (!shippingId) {
      setShipMessageError('Please Select the address')
    }
    else {
      setShipMessageError('')
      OrderDetails();

    //   setProcessing(true)
    //   setOrderError('')
    //   const formdata = new FormData();
    //   formdata.append("customer_ip", ipAddress);
    //   for (let i = 0; i < baseImages.length; i++) {
    //     formdata.append(`prescription_image[${i}]`, baseImages[i]);
    //   }
    //   formdata.append("is_transfer", "0");
    //   formdata.append('shipping_add_id', shippingId);
    //   formdata.append('billing_add_id', shippingId);
    //   axios
    //     .post(createOrderUrl, formdata, {
    //       headers: {
    //         Authorization: "Bearer " + token,
    //         Accept: "application/json",
    //       },
    //     })
    //     .then((response) => {
    //       if (response.data) {
    //         setProcessing(false)
    //         setLoading(true)
    //         setShowOTPModal(false)
    //         // setTimeout(()=>{
    //           setModalShow(true)
    //         // },2000)
    //       } else {
    //         setProcessing(false)
    //       }
    //     })
    //     .catch((error) => {
    //       setProcessing(false)
    //       setOrderError("Something Went Gone Wrong Try Again Later !!")
    //       setShowOTPModal(false)
    //     });
    // }
  }
  }
  

  
  const activeLink = 1; 
  return (<>

    <HelmetProvider>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
    </HelmetProvider>

    <section className="dark-back">
      <div className="profile-2-col">
        <div className="container justify-content-center align-items-center">
          <div className="row">
            <div className="col-md-4">

              {/* <!----------------------Components------------------!>   */}

              <SetProfile day={props.day} />
              <MedicineScheudle />
              <List activeLink={activeLink}  />
              <LogOut logOut={props.logOut} />
             <LogoutTimer onLogout={props.logOut} />        


              {/* <!------------------------------------------------------!>*/}
            </div>

            <div className="col-md-8">
              <div className="profile-right-col">
                <div className="uplode-pic-row">
                  <div className="uplode-pic-left">
                    <h3> Your Prescription Details </h3>
                    <p> Upload a picture of your prescription & we take care of the rest </p>
                    <div className="uplode-pic-drop">
                      <span className="uplode-pic-drop-img">
                        <img src="./images/image-uplode.svg" />
                      </span>
                      JPG,PNG
                    </div>
                  </div>

                  <div className="uplode-pic-right">
                    <div className="uplode-pic-drop-right">
                      <span className="uplode-pic-drop-img">
                        <span className="uplode-box"> <a> <img src="./images/uplode-arow.svg" /> </a> </span >
                      </span>
                    </div>
                  </div>

                </div>

                <div className="drag-drop">
                  <h4> Drag & Drop </h4>
                  <p> prescription photo here </p>
                  <input className="form-control" data-maxfilesize='2' placeholder="Browse Files" id="formFileLg" type="file" name='file' onChange={(e) => uploadImage(e)} />
                </div>

                <div className="image-uplode-bar">
                  <div className="row">
                    {baseImages.map((base64, index) => {
                      return (
                        <div key={index} className="col-md-4" >
                          <div className="image-uplode-media-col"  >
                            <div className="media" >
                              <div className="image"   >
                                <img src={`data:image/png;base64,${base64}`} alt={`Image ${index}`} />
                              </div>
                              <div className="media-body"   >
                                <h6 className="mt-0"  >
                                  {imgNames[index]}
                                </h6>
                                <span className="complete"   >
                                  <img src="./images/complete.svg" /> <h6>Complete </h6> </span>
                              </div>
                              <span className="image-uplode-close" onClick={() => deleteImage(index)}   >
                                <a className="btn">
                                  <img src="./images/uplode-close.svg" />
                                </a>
                              </span>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>

                <p style={{ textAlign: 'center' }}>{errMsg}</p>
                <p style={{ textAlign: 'center' }}>{orderError}</p>
                <div className="uplode-image-btn" onClick={submitImages} >

                  <button type="button" className="btn">
                    Submit Details
                  </button> </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading ?
        <Modals
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
        : null}


      {/* <!-- Modal --> */}

      <Modal {...props} size="md" show={showOTPModal} onHide={() => setShowOTPModal(false)} aria-labelledby="contained-modal-title-vcenter" centered className="modal fade wx_selectAddressModal" tabIndex="-1" aria-hidden="true">

        <h3 className="text-bold text-center">Select Address</h3>
        <div className="modal-body">

          <div className="card mb-4" >
            <div className="card-body d-flex align-items-center">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0 me-2">
                  <svg width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_313_2-368883)">
                      <path
                        d="M10 12.6875C10.6042 12.6875 11.1198 12.474 11.5469 12.0469C11.974 11.6198 12.1875 11.1042 12.1875 10.5C12.1875 9.89583 11.974 9.38021 11.5469 8.95312C11.1198 8.52604 10.6042 8.3125 10 8.3125C9.39583 8.3125 8.88021 8.52604 8.45312 8.95312C8.02604 9.38021 7.8125 9.89583 7.8125 10.5C7.8125 11.1042 8.02604 11.6198 8.45312 12.0469C8.88021 12.474 9.39583 12.6875 10 12.6875ZM10 23.0312C12.7708 20.5104 14.8177 18.224 16.1406 16.1719C17.4635 14.1198 18.125 12.3125 18.125 10.75C18.125 8.29167 17.3385 6.28125 15.7656 4.71875C14.1927 3.15625 12.2708 2.375 10 2.375C7.72917 2.375 5.80729 3.15625 4.23438 4.71875C2.66146 6.28125 1.875 8.29167 1.875 10.75C1.875 12.3125 2.55208 14.1198 3.90625 16.1719C5.26042 18.224 7.29167 20.5104 10 23.0312ZM10.0312 25.125C9.88542 25.125 9.75 25.1042 9.625 25.0625C9.5 25.0208 9.38542 24.9583 9.28125 24.875C6.17708 22.1458 3.85417 19.6146 2.3125 17.2812C0.770833 14.9479 0 12.7708 0 10.75C0 7.625 1.00521 5.13542 3.01562 3.28125C5.02604 1.42708 7.35417 0.5 10 0.5C12.6458 0.5 14.974 1.42708 16.9844 3.28125C18.9948 5.13542 20 7.625 20 10.75C20 12.7708 19.2292 14.9479 17.6875 17.2812C16.1458 19.6146 13.8229 22.1458 10.7188 24.875C10.6146 24.9583 10.5052 25.0208 10.3906 25.0625C10.276 25.1042 10.1562 25.125 10.0312 25.125Z"
                        fill="#767E87" />
                    </g>
                    <defs>
                      <clipPath id="clip0_313_2-368883">
                        <rect width="20" height="26" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>

                <div className="flex-grow-1 ms-3">
                  <h5 className="mb-0">{defaultShippingAddress.address_1} {defaultShippingAddress.city}, {defaultShippingAddress.postcode}</h5>
                  <p className="mb-0">
                    <span>Default Address</span>
                  </p>
                </div>
              </div>
              <div className="form-check ms-auto" onClick={() => handleSippingId(defaultShippingAddress.id)}>
                <input className="form-check-input" type="radio" name="address" id="flexRadioDefault1" />
                <label className="form-check-label stretched-link" type="button" htmlFor="flexRadioDefault1">
                </label>
              </div>
            </div>
          </div>

          {multipleShippingAddress?.map((value, index) => {
            return (
              <div className="card mb-4" key={index}>
                <div className="card-body d-flex align-items-center">
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 me-2">
                      <svg width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_313_2-368883)">
                          <path
                            d="M10 12.6875C10.6042 12.6875 11.1198 12.474 11.5469 12.0469C11.974 11.6198 12.1875 11.1042 12.1875 10.5C12.1875 9.89583 11.974 9.38021 11.5469 8.95312C11.1198 8.52604 10.6042 8.3125 10 8.3125C9.39583 8.3125 8.88021 8.52604 8.45312 8.95312C8.02604 9.38021 7.8125 9.89583 7.8125 10.5C7.8125 11.1042 8.02604 11.6198 8.45312 12.0469C8.88021 12.474 9.39583 12.6875 10 12.6875ZM10 23.0312C12.7708 20.5104 14.8177 18.224 16.1406 16.1719C17.4635 14.1198 18.125 12.3125 18.125 10.75C18.125 8.29167 17.3385 6.28125 15.7656 4.71875C14.1927 3.15625 12.2708 2.375 10 2.375C7.72917 2.375 5.80729 3.15625 4.23438 4.71875C2.66146 6.28125 1.875 8.29167 1.875 10.75C1.875 12.3125 2.55208 14.1198 3.90625 16.1719C5.26042 18.224 7.29167 20.5104 10 23.0312ZM10.0312 25.125C9.88542 25.125 9.75 25.1042 9.625 25.0625C9.5 25.0208 9.38542 24.9583 9.28125 24.875C6.17708 22.1458 3.85417 19.6146 2.3125 17.2812C0.770833 14.9479 0 12.7708 0 10.75C0 7.625 1.00521 5.13542 3.01562 3.28125C5.02604 1.42708 7.35417 0.5 10 0.5C12.6458 0.5 14.974 1.42708 16.9844 3.28125C18.9948 5.13542 20 7.625 20 10.75C20 12.7708 19.2292 14.9479 17.6875 17.2812C16.1458 19.6146 13.8229 22.1458 10.7188 24.875C10.6146 24.9583 10.5052 25.0208 10.3906 25.0625C10.276 25.1042 10.1562 25.125 10.0312 25.125Z"
                            fill="#767E87" />
                        </g>
                        <defs>
                          <clipPath id="clip0_313_2-368883">
                            <rect width="20" height="26" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>

                    <div className="flex-grow-1 ms-3">
                      <h5>{value.address_1} {value.city}, {value.postcode}</h5>
                      <p className="mb-0">
                        {/* <span>{value.type}</span> */}
                      </p>
                    </div>
                  </div>
                  <div className="form-check ms-auto" onClick={() => handleSippingId(value.id)}>
                    <input className="form-check-input" type="radio" name={`address`} id={`address-${index}`} />
                    <label className="form-check-label stretched-link" type="button" htmlFor={`address-${index}`} >
                    </label>
                  </div>
                </div>
              </div>
            )
          })}


        </div>
        <p>{shipMessageError}</p>
        <div className="col-md-12 text-center ">
          <button type="button" class="btn btn-primary login-btn" onClick={submitOrder} >
            {processing ? 'Processing...' : 'Continue'}  </button>
        </div>



      </Modal>
    </section>

  </>)
}
export default NewPrescription; 