import { AppUrl } from "../sharedApi/common"
import { useState, useEffect } from "react"
import axios from "axios"
import Card from 'react-bootstrap/Card';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { TermsCondtionsDescription,TermsCondtionsTitle,TermsCondtionsUrl } from "../metaTags/meta";
import DOMPurify from 'dompurify';

const TermsAndConditions = (props) => {

  const [termsCondtions, setTermsCondtions] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function makeRequest() {
      setLoading(true)
      const response = await axios.get(`${AppUrl}/api/content`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      });
      setTermsCondtions(response.data.data)
      setLoading(false)
    }
    makeRequest();
  }, [])

  const sanitizedHTML = termsCondtions && termsCondtions[0] ? DOMPurify.sanitize(termsCondtions[0].content) : '';

  return (<>

<HelmetProvider>
    <Helmet>
      <meta name="robots" content="index,follow" />
<link rel="alternate" href={ TermsCondtionsUrl } hreflang="en" />
<title>{TermsCondtionsTitle}</title>
<meta charset="utf-8" />
<meta name="author" content="WeldonRx"/>
<meta name="title" content={TermsCondtionsTitle}/>
<meta name="description" content= { TermsCondtionsDescription } />
<meta property="og:type" content="website" />
<meta property="og:url" content={ TermsCondtionsUrl } />
<meta property="og:title" content={TermsCondtionsTitle} />
<meta property="og:description" content= { TermsCondtionsDescription }/>
<meta property="og:image" content="https://weldonrx.com/weldon-social-image.png" />
<meta property="twitter:card" content="summary_large_image" />
<meta property="twitter:url" content={ TermsCondtionsUrl } />
<meta property="twitter:title" content={TermsCondtionsTitle}/>
<meta property="twitter:description" content= { TermsCondtionsDescription } />
<meta property="twitter:image" content="https://weldonrx.com/weldon-social-image.png" />
<link rel="canonical" href={ TermsCondtionsUrl } />
  </Helmet>
</HelmetProvider>

    <div className="container spaceContainer pt-5 mt-3 mt-md-3 ">
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <div className="page-title-breadcrumb">
            <h2> Terms of Use</h2>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a > Home </a></li>
                <li> <img src="./images/bradcumb-arows.svg" alt="..." /> </li>
                <li className="breadcrumb-item active" aria-current="page">Terms of Use </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="card wx_myprofileTextRight wx_MyContactBg mt-0">

          {loading ?
            <>
              <Card className="pill-schedule-row" style={{ height: '68px', backgroundColor: "#ecebeb", borderColor: 'white', marginBottom: '20px' }}></Card>
              <Card className="pill-schedule-row" style={{ height: '68px', backgroundColor: "#ecebeb", borderColor: 'white', marginBottom: '20px' }}></Card>
              <Card className="pill-schedule-row" style={{ height: '68px', backgroundColor: "#ecebeb", borderColor: 'white', marginBottom: '20px' }}></Card>
              <Card className="pill-schedule-row" style={{ height: '68px', backgroundColor: "#ecebeb", borderColor: 'white', marginBottom: '20px' }}></Card>
              <Card className="pill-schedule-row" style={{ height: '68px', backgroundColor: "#ecebeb", borderColor: 'white', marginBottom: '20px' }}></Card>
              <Card className="pill-schedule-row" style={{ height: '68px', backgroundColor: "#ecebeb", borderColor: 'white', marginBottom: '20px' }}></Card>
              <Card className="pill-schedule-row" style={{ height: '68px', backgroundColor: "#ecebeb", borderColor: 'white', marginBottom: '20px' }}></Card>

            </>
            :
            <div className="card-body">
            <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }}></div>
            </div>
          }
        </div>
      </div>
    </div>
  </>)
}
export default TermsAndConditions;