import { BiUser } from 'react-icons/bi';
import { BsTelephoneFill } from 'react-icons/bs';
import { MdEmail } from 'react-icons/md';
import { FaMapPin } from 'react-icons/fa';
import { FaAllergies } from 'react-icons/fa';
import { IoLocationSharp } from 'react-icons/io5';
import { BsFillMapFill } from 'react-icons/bs';
import React, { useEffect, useState, useRef } from 'react';
import { userProfileUrl, myImageUrl,  addAddress, } from '../sharedApi/common'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { BsCalendar3 } from "react-icons/bs";
import Autocomplete from 'react-google-autocomplete';
import { usePlacesWidget } from 'react-google-autocomplete';
import $ from 'jquery';
import 'bootstrap';
import 'bootstrap-datepicker';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';

const CreateProfile = (props) => {

  const [createProfiles, setCreateProfiles] = useState({ first_name: '', last_name: '', date_of_birth: '', sex: '', phn: '', email: '', phone_number: '' })
  const [messageError, setMessageError] = useState('');
  const [errMsg, settErrMsg] = useState('');
  const [image, setImage] = useState('');
  const [profileImage, setProfileImage] = useState('')
  const [color, setColor] = useState('lightgrey')
  const [colors, setColors] = useState('lightgrey')
  const [genderColor, setGenderColor] = useState('lightgrey')
  const [DobColor, setDobColor] = useState('lightgrey')
  const [emailColor, setEmailColor] = useState('lightgrey')
  const [phnColor, setPhnColor] = useState('lightgrey')
  const [address1Color, setAddress1Color] = useState('ced4da')
  const [stateColor, setStateColor] = useState('lightgrey')
  const [zipCodeColor, setZipCodeColor] = useState('lightgrey')
  const [cityColor, setCityColor] = useState('lightgrey')
  const [phoneColor, setPhoneColor] = useState('lightgrey')
  const [defaultImage, setDefaultImage] = useState(true);
  const [mobileNumber, setMobileNumber] = useState([])
  const [emailID, setEmailID] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [dob, setDob] = useState('')
  const [selectGender, setSelectGender] = useState('')
  const [email, setEmail] = useState('')
  const [phn, setPhn] = useState('')
  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('British Columbia')
  const [zipCode, setZipCode] = useState('')
  const [allergy, setAllergy] = useState('')
  const [phone, setPhone] = useState('')
  const [PhoneMessageError, setPhoneMessageError] = useState('')
  const [billingAddress1, setBillingAddress] = useState('')
  const [billingAddress2, setBillingAddress2] = useState('')
  const [billingCity, setBillingCity] = useState('')
  const [billingZipCode, setBillingZipCode] = useState('')
  const [billingState, setBillingState] = useState('British Columbia')
  const [billingAddressColor, setBillingAddressColor] = useState('lightgrey')
  const [billingCityColor, setBillingCityColor] = useState('lightgrey')
  const [billingStateColor, setBillingStateColor] = useState('lightgrey')
  const [billingZipCodeColor, setBillingZipCodeColor] = useState('lightgrey')
  const [isChecked, setIsChecked] = useState(true);
  const [emailMessageError, setEmailMessageError] = useState('')

  const navigate = useNavigate();
  const startDateRef = useRef(null);

  useEffect(() => {
    $(startDateRef.current).datepicker({
      format: 'yyyy-mm-dd',
      endDate: new Date() 
    }).on('changeDate', function (e) { 
      setDob(e.target.value);
      setDobColor('lightgrey')
    });
  }, []);

  let curr = new Date();
  curr.setDate(curr.getDate());
  let date = curr.toISOString().substring(0, 10);

  useEffect(() => {
    let number = localStorage.getItem('My Number')
    setMobileNumber(number)
  }, [])

  const submitUserProfile = async () => {
    const token = JSON.parse(localStorage.getItem('Token_access'))
    setIsLoading(true)
    const rawResponse = await fetch(userProfileUrl,
      {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + token,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 'first_name': firstName, 'last_name': lastName, 'date_of_birth': dob, 'sex': selectGender, 'phn': phn, 'phone_number': mobileNumber || phone, 'allergy': allergy, 'email': emailID || email })
      })
    const result = await rawResponse.json();
    if (result.data.email.includes('The email has already been taken.')) {
      setEmailMessageError('Email Already has been taken')
    } else {
      (createProfiles && zipCode && navigate('/dashboard'))
    }
    return result;
  }

  const submitProfileData = () => {
    if (!createProfiles) {
      setMessageError('Please Enter Your Name')
    }
    else if (createProfiles) {
      submitUserProfile();
    }
  }

  /*----------------------------Profile Image ----------------------------------*/
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setImage(base64)
    setProfileImage(base64.replace(/^data:image\/[a-z]+;base64,/, ""));
    setDefaultImage(false);
    settErrMsg('')
  };

  const submitImage = () => {
    if (profileImage) {
      const token = JSON.parse(localStorage.getItem('Token_access'))
      const formdata = new FormData();
      formdata.append('profile_image', profileImage.replace(/^data:image\/[a-z]+;base64,/, ""))
      axios.post(myImageUrl, formdata, {
        headers: {
          'Authorization': 'Bearer ' + token,
          'Accept': 'application/json',
          'Content-Type': 'multipart/form-data'
        }
      }).then((response) => {
        return response
      });
    }
  }

  /* --------------------------Shipping Info--------------------------------------*/

  const submitUserProfileAddress = async () => {
    const token = JSON.parse(localStorage.getItem('Token_access'))
    setIsLoading(true)
    try {
      const response = await fetch(addAddress, {
        method: "POST",
        headers: {
          'Authorization': 'Bearer ' + token,
          'Accept': "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          address_1: address1, address_2: address2, city: city, postcode: zipCode, state: state, country: "Canada",
          email: emailID || email, phone: phone || mobileNumber, allergy: allergy, first_name: firstName, last_name: lastName,
          type: "shipping", same_shipping: isChecked ? 'true' : 'false'
        }),
      });
      const result = await response.json();
      setIsLoading(false);
      return result;
    } catch (error) {
      // console.error(error);
    }
  }

  const submitUserBillingAddress = async () => {
    if (!isChecked) {
      const token = JSON.parse(localStorage.getItem('Token_access'))
      setIsLoading(true)
      try {
        const response = await fetch(addAddress, {
          method: "POST",
          headers: {
            'Authorization': 'Bearer ' + token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            address_1: billingAddress1, address_2: billingAddress2, city: billingCity, postcode: billingZipCode, state: billingState, country: "Canada",
            email: emailID || email, phone: phone || mobileNumber, allergy: allergy, first_name: firstName, last_name: lastName,
            type: "billing",
            same_shipping: 'false'
          }),
        });
        const result = await response.json();
        setIsLoading(false);
        return result;
      } catch (error) {
        // console.error(error);
      }
    }
  }

  const submitMyAddressData = (event) => {
    event.preventDefault();

    if (!firstName) {
      setColor('#FF4200')
    } else if (!lastName) {
      setColors('#FF4200')
    } else if (!selectGender) {
      setGenderColor('#FF4200')
    } else if (!dob) {
      setDobColor('#FF4200')
    } 
    else if (!email) {
      setEmailColor('#FF4200')
    } else if (phn.length < 10 || phn.length > 10) {
      setMessageError('Enter 10 digit Number')
      setPhnColor('#FF4200')
    } else if (!address1) {
      setAddress1Color('#FF4200')
    } else if (!state) {
      setStateColor('#FF4200')
    } else if (!city) {
      setCityColor('#FF4200')
    } else if (!zipCode) {
      setZipCodeColor('#FF4200')
    } else if (!isChecked && !billingAddress1) {
      setBillingAddressColor('#FF4200');
    } else if (!isChecked && !billingCity) {
      setBillingCityColor('#FF4200');
    } else if (!isChecked && !billingState) {
      setBillingStateColor('#FF4200');
    } else if (!isChecked && !billingZipCode) {
      setBillingZipCodeColor('#FF4200');
    } else {
      submitImage();
      submitProfileData();
      submitUserBillingAddress();
      submitUserProfileAddress();
      settErrMsg('')
    }
    setEmailMessageError('')
  }

  const submitData = (event) => {
    event.preventDefault();

    if (!firstName) {
      setColor('#FF4200')
    } else if (!lastName) {
      setColors('#FF4200')
    } else if (!selectGender) {
      setGenderColor('#FF4200')
    } else if (!dob) {
      setDobColor('#FF4200')
    } else if (!phone) {
      setPhoneColor('#FF4200')
    } else if (phone.length < 10 || phone.length > 10) {
      setPhoneColor('#FF4200')
      setPhoneMessageError('Enter 10 digit Number')
    } else if (phn.length < 10 || phn.length > 10) {
      setMessageError('Enter 10 digit Number')
      setPhnColor('#FF4200')
    } else if (!address1) {
      setAddress1Color('#FF4200')
    } else if (!state) {
      setStateColor('#FF4200')
    } else if (!city) {
      setCityColor('#FF4200')
    } else if (!zipCode) {
      setZipCodeColor('#FF4200')
    } else if (!isChecked && !billingAddress1) {
      setBillingAddressColor('#FF4200');
    } else if (!isChecked && !billingCity) {
      setBillingCityColor('#FF4200');
    } else if (!isChecked && !billingState) {
      setBillingStateColor('#FF4200');
    } else if (!isChecked && !billingZipCode) {
      setBillingZipCodeColor('#FF4200');
    } else {
      submitImage();
      submitProfileData();
      submitUserBillingAddress();
      submitUserProfileAddress();
      settErrMsg('')
    }
  }

  useEffect(() => {
    const email = JSON.parse(localStorage.getItem('email'))
    setEmailID(email)
  }, [])

  const handleFirstName = (e) => {
    setFirstName(e.target.value)
    setColor('lightgrey')
  }

  const handleLastName = (e) => {
    setLastName(e.target.value)
    setColors('lightgrey')
  }

  const handlePhnChange = (event) => {
    const value = event.target.value.replace(/\D/g, '').slice(0, 10);
    setPhn(value)
    setPhnColor('lightgrey')
    setMessageError('')
  }

  const handleAddress2 = (e) => {
    setAddress2(e.target.value)
  }

  const handleCity = (e) => {
    setCity(e.target.value)
    setCityColor('lightgrey')
  }

  const handleState = (e) => {
    setState(e.target.value)
    setStateColor('lightgrey')
  }

  const handleZipCode = (event) => {
    const value = event.target.value.slice(0, 6);
    setZipCode(value)
    setZipCodeColor('lightgrey')
  }

  const handleBillingAddress2 = (e) => {
    setBillingAddress2(e.target.value)
  }

  const handleBillingCity = (e) => {
    setBillingCity(e.target.value)
    setBillingCityColor('lightgrey')
  }

  const handleBillingZipCode = (event) => {
    const value = event.target.value.slice(0, 6);
    setBillingZipCode(value)
    setBillingZipCodeColor('lightgrey')
  }

  const handleBillingState = (e) => {
    setBillingState(e.target.value)
    setBillingStateColor('lightgrey')
  }

  const handleCheckboxChange = (event) => {
    setIsChecked(!isChecked);
  };

  // /-----------------------------------------------------Billing And Shipping AutoComplte SetUp-------------------------------------------------  

  const onPlaceSelected111 = (place) => {
    if (place && place.place_id) {
      const request = {
        placeId: place.place_id,
        fields: ['address_components', 'geometry', 'formatted_address', 'name']
      };
      const service = new window.google.maps.places.PlacesService(document.createElement('div'));
      service.getDetails(request, (placeResult, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const addressComponents = placeResult.address_components;
          let houseNumber, streetName, city, state, province, country, postcode

          for (let i = 0; i < addressComponents.length; i++) {
            const component = addressComponents[i];
            if (component.types.includes('street_number')) {
              houseNumber = component.long_name;
              setAddress1(houseNumber)
            } else if (component.types.includes('locality')) {
              city = component.long_name;
              setCity(city)
            } else if (component.types.includes('administrative_area_level_1')) {
              state = component.long_name;
              setState(state)
            } else if (component.types.includes('postal_code')) {
              postcode = component.long_name;
              setZipCode(postcode)
            } else if (component.types.includes('route')) {
              streetName = component.long_name;
            }
          }
          setAddress2('')
          if (houseNumber) {
            setAddress1(`${houseNumber} ${streetName}`);
          } else {
            setAddress1(`${streetName}`);
          }
        }
      });
    }
  };

  const onPlaceSelected222 = (place) => {
    if (place && place.place_id) {
      const request = {
        placeId: place.place_id,
        fields: ['address_components', 'geometry', 'formatted_address', 'name']
      };
      const service = new window.google.maps.places.PlacesService(document.createElement('div'));
      service.getDetails(request, (placeResult, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const addressComponents = placeResult.address_components;
          let houseNumber, streetName, city, state, province, country, postcode

          for (let i = 0; i < addressComponents.length; i++) {
            const component = addressComponents[i];
            if (component.types.includes('street_number')) {
              houseNumber = component.long_name;
              setBillingAddress(component.long_name)
            } else if (component.types.includes('locality')) {
              city = component.long_name;
              setBillingCity(component.long_name)
            } else if (component.types.includes('administrative_area_level_1')) {
              state = component.long_name;
              setBillingState(state)
            } else if (component.types.includes('postal_code')) {
              postcode = component.long_name;
              setBillingZipCode(postcode)
            } else if (component.types.includes('route')) {
              streetName = component.long_name;
            }
          }
          setBillingAddress2('')
          if (houseNumber) {
            setBillingAddress(`${houseNumber} ${streetName}`);
          } else {
            setBillingAddress(`${streetName}`);
          }
        }
      });
    }
  };

  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value;
      setEmail(enteredEmail);
      setEmailColor('lightgrey');
  }


  const { ref: ref1 } = usePlacesWidget({
    apiKey: "https://maps.googleapis.com/maps/api/place/autocomplete/json?input=<input>&key= AIzaSyBzuzGR9_XoLdb7nx-L9UdPPmIwZyiSOdM&libraries=places",
    onPlaceSelected: onPlaceSelected111,
    options: {
      types: ["address"],
      componentRestrictions: { country: "ca" },
      location: { lat: 49.0869607, lng: -122.4077515 },
      limit: 7,
      googleLogo: false
    }
  });

  return (<>

    <nav className="navbar">
      <div className="container dark-hedaer-container">
        <div className="row">
          <div className="col-md-12">
            <div className="dark-hedaer">
              <a className="navbar-brand"><img src="./images/weldonrx-white-final.png" style={{ width: '229.99px', height: 'auto' }} /> </a>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <div className="Profile-section">
      <div className="container">
      

        {/* ----------------------------------------Provide Your Personal Info Section Start-------------------------------------------- */}

        <div className="row">
          <div className="col-md-5">
            <div className="profile-info">
              <h3> About you </h3>
              <div className="profile-details">
                <div className="profile-details-left">
                  <h4> Personal info </h4>
                  <p> Provide your personal info </p>
                </div>

                <div className="profile-details-right">
                  {image ?
                    <img src={image} alt="Profile" />
                    :
                    <img src='./images/upload-profile-pic.png' />
                  }
                  <span className="profile-img-uplode">
                    <form >
                      <input type="file" id="myFile" name='file' onChange={(e) => uploadImage(e)} />
                      <img src="./images/profile-uplode-icon.svg" />
                    </form>
                  </span>
                </div>
              </div>
              <div style={{ marginLeft: '292px', marginTop: '20px' }}>{errMsg}</div>
            </div>
          </div>

          <div className="col-md-7">
            <form id="contactForm" className="form-inline" data-sb-form-api-token="">
              <div className="row mt-5">
                <div className="col-md-6">
                  <div className="form-floating mb-3">
                    <input className="form-control" type="text" placeholder="First name" name='first_name'
                      onChange={handleFirstName} defaultValue={firstName} style={{ borderColor: color }} />
                    <span className="form-icon"> <i className="fa-regular fa-user"><BiUser size={20} style={{ marginBottom: '5px' }} /></i> </span>
                    <label htmlFor="emailAddress"> First name</label>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-floating mb-3">
                    <input className="form-control" type="text" onChange={handleLastName} defaultValue={lastName} name='last_name' placeholder="Last Name" style={{ borderColor: colors }} />
                    <span className="form-icon"> <i className="fa-regular fa-user"><BiUser size={20} style={{ marginBottom: '5px' }} /></i> </span>
                    <label htmlFor="emailAddress">
                      Last Name
                    </label>
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-6">
                  <div className="checkbox-row mb-3">
                    <label htmlFor="emailAddress"> Select Your Gender </label>
                    <div className="checkbox-col">
                      <div className="form-check">
                        <input className="form-check-input" type="radio" id="flexRadioDefault1" name='sex' defaultValue='male' checked={selectGender === "male"} style={{ borderColor: genderColor }}
                          onChange={() => { setSelectGender("male"); setGenderColor("grey"); }} />
                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                          Male
                        </label>
                      </div>

                      <div className="form-check">
                        <input className="form-check-input" type="radio" id="flexRadioDefault2" onChange={() => { setSelectGender("female"); setGenderColor("grey"); }}
                          checked={selectGender === "female"} name='sex' defaultValue='female' />
                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                          Female
                        </label>
                      </div>

                      <div className="form-check">
                        <input className="form-check-input" type="radio" id="flexRadioDefault3"
                          onChange={() => setSelectGender("others")} checked={selectGender === "others"} name='sex' defaultValue='others' />
                        <label className="form-check-label" htmlFor="flexRadioDefault3">
                          Others
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-date-piker mb-3" >
                    <label htmlFor="emailAddress"> Date of Birth </label>
                    <div className='input-group date' >
                      <span className="input-group-addon input-group-text"><i className="fa-solid fa-calendar-days"><BsCalendar3 /></i>
                      </span>

                      <input type="text" className="form-control" ref={startDateRef} defaultValue={dob} name="dob" placeholder="yyyy-mm-dd" style={{ borderColor: DobColor }} />

                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-6">
                  <div className="form-floating mb-3">
                    {mobileNumber ? (<input className="form-control" id="emailAddress" type="text" placeholder="Email" defaultValue={mobileNumber} disabled />)
                      :
                      (<input className="form-control" id="emailAddress" type="text" placeholder="Email" value={phone} onChange={(e) => { setPhone(e.target.value.replace(/\D/g, '').slice(0, 10)); setPhoneColor('lightgrey'); setPhoneMessageError('') }}
                        style={{ borderColor: phoneColor }}  onKeyDown={(e) => { if (!/^\d+$/.test(e.key) && (e.key !== "Backspace") && e.keyCode !== 9) { e.preventDefault(); } }} />
                      )}

                    {PhoneMessageError}
                    <span className="form-icon"> <i className="fa-solid fa-phone"><BsTelephoneFill size={16} style={{ marginBottom: '2px' }} /></i> </span>
                    <label htmlFor="emailAddress"> Phone Number </label>

                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-floating mb-3">
                    {emailID ? (<input className="form-control" id="emailAddress" type="text" placeholder="Email" defaultValue={emailID} disabled />)
                      :
                      // <input type="email" className="form-control" id="emailAddress" placeholder="Email" defaultValue={email} onChange={(e) => { setEmail(e.target.value); setEmailColor('lightgrey') }}
                      // style={{ borderColor: emailColor }} />

                      <input type="email" className="form-control" id="emailAddress" placeholder="Email" style={{ borderColor: emailColor }} value={email} onChange={handleEmailChange} />
                      
                    }
                    {emailMessageError}
                    <span className="form-icon"> <i className="fa-solid fa-envelope"><MdEmail size={20} style={{ marginBottom: '4px' }} /></i> </span>
                    <label htmlFor="emailAddress"> Enter email address </label>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* ------------------------------------------------ Section End------------------------------------------------ */}

        <hr />

        {/* -------------------------------------Provide Your Medical Information Section Start---------------------------------- */}

        <div className="row">
          <div className="col-md-5">
            <div className="profile-info">
              <div className="profile-details">
                <div className="profile-details-left">
                  <h4> Medical </h4>
                  <p> Provide your medical information </p>
                </div>
                <div className="profile-details-right"> </div>
              </div>
            </div>
          </div>

          <div className="col-md-7">
            <form id="contactForm" className="form-inline" data-sb-form-api-token="">
              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="form-floating mb-3">
                    <input className="form-control" id="emailAddress" type="text" placeholder="First name" data-sb-validations="required,name" name='phn' onChange={handlePhnChange} value={phn}
                      style={{ borderColor: phnColor }}  onKeyDown={(e) => { if (!/^\d+$/.test(e.key) && (e.key !== "Backspace") && e.keyCode !== 9) { e.preventDefault(); } }}/>
                    <span className="form-icon"> <i className="fa-sharp fa-solid fa-map-pin"><FaMapPin size={20} style={{ marginBottom: '0px' }} /></i> </span>
                    <label htmlFor="emailAddress">
                      PHN number
                    </label>
                    {messageError}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-floating mb-3">
                    <input className="form-control" onChange={(e) => setAllergy(e.target.value)} type="text" placeholder="Last Name" defaultValue={allergy} data-sb-validations="required,email" />
                    <span className="form-icon"> <i className="fa-solid fa-hand-dots"><FaAllergies size={16} /></i> </span>
                    <label htmlFor="emailAddress"> Allergies </label>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        <hr />

        {/* -------------------------------Provide Your Shipping Details Start-------------------------------------------- */}

        <div className="row">
          <div className="col-md-5">
            <div className="profile-info">
              <div className="profile-details">
                <div className="profile-details-left">
                  <h4> Billing Address</h4>
                  <p> Provide your billing details </p>
                </div>
                <div className="profile-details-right">
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-7">
            <form id="contactForm" className="form-inline" data-sb-form-api-token="">
              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="form-floating mb-3">
                    <input
                      ref={ref1}
                      value={address1}
                      onChange={(e) => { return setAddress1(e.target.value), setAddress1Color('lightgrey') }}
                      style={{ borderColor: address1Color }}
                      className="form-control" />
                    <span className="form-icon"><i className="fa-solid fa-location-dot"><IoLocationSharp size={20} style={{ marginBottom: '5px' }} /></i></span>
                    <label htmlFor="emailAddress"> House number and street name </label>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-floating mb-3">
                    <input className="form-control" id="emailAddress" type="text" name="address_2" onChange={handleAddress2} placeholder="Last Name" defaultValue={address2} required />
                    <span className="form-icon"> <i className="fa-solid fa-location-dot"><IoLocationSharp size={20} style={{ marginBottom: '5px' }} /></i> </span>
                    <label htmlFor="emailAddress"> Apartment , suite, unit, etc. (optional) </label>
                  </div>
                </div>
              </div>

              <div className="row mt-1">
                <div className="col-md-4">
                  <div className="form-floating mb-3">
                    <input className="form-control" id="emailAddress" type="text" name="city" onChange={handleCity} style={{ borderColor: cityColor }} placeholder="City" defaultValue={city} required />
                    <span className="form-icon"> <i className="fa-solid fa-location-dot"><IoLocationSharp size={20} style={{ marginBottom: '5px', }} /></i> </span>
                    <label htmlFor="emailAddress"> City </label>
                  </div>
                </div>

                <div className="col-md-5">
                  <div className="form-floating mb-3">
                    <select className="form-select" name='state' onChange={handleState}
                      value={state} required style={{ borderColor: stateColor }}>
                      <option >Select State</option>
                      <option value='Alberta'>Alberta</option>
                      <option value='British Columbia'>British Columbia</option>
                      <option value='Manitoba'>Manitoba</option>
                      <option value='New Brunswick'>New Brunswick</option>
                      <option value='Newfoundland and Labrador'>Newfoundland and Labrador</option>
                      <option value='Northwest Territories'>Northwest Territories</option>
                      <option value='Nova Scotia'>Nova Scotia</option>
                      <option value='Nunavut'>Nunavut</option>
                      <option value='Ontario'>Ontario</option>
                      <option value='Prince Edward Island'>Prince Edward Island</option>
                      <option value='Quebec'>Quebec</option>
                      <option value='Saskatchewan'>Saskatchewan</option>
                      <option value='Yukon'>Yukon</option>
                    </select>

                    <span className="form-icon"> <i className="fa-sharp fa-solid fa-map"><BsFillMapFill size={16} style={{ marginBottom: '4px' }} /></i> </span>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-floating mb-3">
                    <input className="form-control" id="emailAddress" type="text" placeholder="Last Name" name='postcode'
                      onChange={handleZipCode} value={zipCode} style={{ borderColor: zipCodeColor }} />
                    <span className="form-icon"> <i className="fa-sharp fa-solid fa-map-pin"><FaMapPin size={20} /></i> </span>
                    <label htmlFor="emailAddress"> Zip code </label>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        {/* ------------------------------------Section End-------------------------------------------- */}

        {/* <input type='checkbox' className="form-check-input" checked={isChecked} onChange={handleCheckboxChange} value='true' id="sameAsBilling" /> &nbsp; Same as above billing address */}
        <div className="mb-3 mt-2  form-check wx_FormCheck">
          <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} value='true' id="sameAsBilling" className="form-check-input" />
          <label className="form-check-label" htmlFor="exampleCheck1"><p>Ship to a different address?</p></label>
        </div>

        <div className='row'>

          {/* --------------------------------------Provide Your Billing Details----------------------------------*/}
          {isChecked ? null : (
            <div className="row">
              <div className="col-md-5">
                <div className="profile-info">
                  <div className="profile-details">
                    <div className="profile-details-left">
                      <h4> Shipping Address</h4>
                      <p> Provide your billing details </p>
                    </div>
                    <div className="profile-details-right">
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-7">
                <form id="contactForm" className="form-inline" data-sb-form-api-token="">
                  <div className="row mt-3">
                    <div className="col-md-6">

                      <div className="form-floating mb-3">
                        <Autocomplete
                          apiKey="https://maps.googleapis.com/maps/api/place/autocomplete/json?input=<input>&key= AIzaSyBzuzGR9_XoLdb7nx-L9UdPPmIwZyiSOdM&libraries=places"
                          onPlaceSelected={onPlaceSelected222}
                          types={['geocode']}
                          value={billingAddress1}
                          onChange={(e) => { return setBillingAddress(e.target.value), setBillingAddressColor('lightgrey') }}
                          radius={5000}
                          className='form-control'
                          style={{ borderColor: billingAddressColor }}
                          placeholder="House Number and Street Name"
                          options={{
                            types: ["address"],
                            componentRestrictions: { country: "ca" },
                            location: { lat: 49.0869607, lng: -122.4077515 },
                            limit: 7,
                            googleLogo: false
                          }}>
                        </Autocomplete>

                        <span className="form-icon"> <i className="fa-solid fa-location-dot"><IoLocationSharp size={20} style={{ marginBottom: '5px' }} /></i> </span>
                        <label htmlFor="emailAddress"> House number and street name </label>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-floating mb-3">
                        <input className="form-control" onChange={handleBillingAddress2} defaultValue={billingAddress2} id="emailAddress" type="text" name="address_2" placeholder="Last Name" required />
                        <span className="form-icon"> <i className="fa-solid fa-location-dot"><IoLocationSharp size={20} style={{ marginBottom: '5px' }} /></i> </span>
                        <label htmlFor="emailAddress"> Apartment , suite, unit, etc. (optional) </label>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-1">
                    <div className="col-md-4">
                      <div className="form-floating mb-3">
                        <input className="form-control" onChange={handleBillingCity} defaultValue={billingCity} style={{ borderColor: billingCityColor }} id="emailAddress" type="text" name="city" placeholder="City" required />
                        <span className="form-icon"> <i className="fa-solid fa-location-dot"><IoLocationSharp size={20} style={{ marginBottom: '5px', }} /></i> </span>
                        <label htmlFor="emailAddress"> City </label>
                      </div>
                    </div>

                    <div className="col-md-5">
                      <div className="form-floating mb-3">
                        <select className="form-select" name='state' style={{ borderColor: billingStateColor }} onChange={handleBillingState} value={billingState}
                          required >
                          <option > Select State</option>
                          <option value='Alberta'>Alberta</option>
                          <option value='British Columbia'>British Columbia</option>
                          <option value='Manitoba'>Manitoba</option>
                          <option value='New Brunswick'>New Brunswick</option>
                          <option value='Newfoundland and Labrador'>Newfoundland and Labrador</option>
                          <option value='Northwest Territories'>Northwest Territories</option>
                          <option value='Nova Scotia'>Nova Scotia</option>
                          <option value='Nunavut'>Nunavut</option>
                          <option value='Ontario'>Ontario</option>
                          <option value='Prince Edward Island'>Prince Edward Island</option>
                          <option value='Quebec'>Quebec</option>
                          <option value='Saskatchewan'>Saskatchewan</option>
                          <option value='Yukon'>Yukon</option>
                        </select>

                        <span className="form-icon"> <i className="fa-sharp fa-solid fa-map"><BsFillMapFill size={16} style={{ marginBottom: '4px' }} /></i> </span>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-floating mb-3">
                        <input className="form-control" id="emailAddress" type="text" placeholder="Last Name" name='postcode' onChange={handleBillingZipCode} value={billingZipCode}
                          style={{ borderColor: billingZipCodeColor }} />
                        <span className="form-icon"> <i className="fa-sharp fa-solid fa-map-pin"><FaMapPin size={20} /></i> </span>
                        <label htmlFor="emailAddress"> Zip code </label>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}

          {/* ------------------------------------------Section End----------------------------------------------------- */}

          <div className="row mt-4">
            <div className="col-md-8"></div>
            <div className="col-md-4">
              <div className="d-grid">
                <button className="btn btn-primary btn-lg submitButton" id="submitButton" type="submit" onClick={emailID ? submitData : submitMyAddressData}>
                {/* <button className="btn btn-primary btn-lg submitButton" id="submitButton" type="submit" > */}
                  {isLoading ? 'Processing..' : 'Continue'}
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div className="loging-footer-section">
      <div className="container justify-content-center align-items-center">
        <div className="row">
          <div className="col-md-12">
            <div className="footer-full">
              <p>&copy; 2023 -  Weldon Pharmacy. All rights reserved. </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>)
}
export default CreateProfile;