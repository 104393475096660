import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import { MdOutlineLogin } from "react-icons/md";
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { decrypteData } from '../sharedApi/customeAxios';

const NavBar = () => {

  const [homeActive, setHomeActive] = useState(true);
  const [howWorksActive, setHowWorksActive] = useState(false);
  const [faqActive, setFaqActive] = useState(false);
  const [homepage, setHomePage] = useState([])

  const navigate = useNavigate();

  const handleHomeClick = () => {
    setHomeActive(true);
    setHowWorksActive(false);
    setFaqActive(false);
    window.scrollTo({ top: 0, left: 0 });
  };

  const handleHowWorksClick = () => {
    setHomeActive(false);
    setHowWorksActive(true);
    setFaqActive(false);
    window.scrollTo({ top: 900, left: 0 });
  };

  const handleFaqClick = () => {
    setHomeActive(false);
    setHowWorksActive(false);
    setFaqActive(true);
    window.scrollTo({ top: 1850, left: 0 });
  };

  const handleClick = () => {
    navigate('/')
  }


  useEffect(() => {
    let profile = decrypteData();
    if (profile) {
      setHomePage(profile?.user_default_shipping_address === null)
    }
  }, [])

  return (<>

    <Navbar collapseOnSelect expand="lg" bg="white" variant="white" >
      <Container className="container">

        <div onClick={handleClick} className="btn">
          <Navbar.Brand href="/"><img src="./images/weldonrx-logo.png" style={{ width: '160px' }} /></Navbar.Brand>
        </div>

        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll ">

          <ListGroup className="navbar-nav mr-auto w-100 justify-content-end">

            <li className={homeActive ? "nav-item " : "nav-item"} onClick={handleHomeClick}>
              <Link to='/' className="nav-link" >Home</Link>
            </li>

            <li className={howWorksActive ? "nav-item active" : "nav-item"} onClick={handleHowWorksClick}>
              <Link to='/' style={{ textDecoration: 'None' }} className="nav-link" >How It Works</Link>
            </li>

            <li className={faqActive ? "nav-item active" : "nav-item"} onClick={handleFaqClick}>
              <Link to='/' style={{ textDecoration: 'None' }} className="nav-link">Faq</Link>
            </li>


            {homepage ?
              <li className="nav-item mt-1">
                <Link to='/login' className="nav-link btn btn-primary butttooon234 btn-hero" ><i className="fa-solid fa-arrow-right-to-bracket">
                  <MdOutlineLogin style={{ marginBottom: '3px', fontWeight: 'bold' }} size={22} /></i>&nbsp;Login </Link>
              </li>
              :
              <li className="nav-item mt-1">
                <Link to='/dashboard' className="nav-link btn btn-primary butttooon234 btn-hero" ><i className="fa-solid fa-arrow-right-to-bracket">
                  <MdOutlineLogin style={{ marginBottom: '3px', fontWeight: 'bold' }} size={22} /></i>&nbsp;Dashboard </Link>
              </li>
            }

          </ListGroup>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </>)
}
export default NavBar;
