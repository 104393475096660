import { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import { useNavigate } from 'react-router-dom'

const OtpConfirmation = (props) => {

  const [getNumber, setGetNumber] = useState([])

  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
  }, [])

  const handleClick = () => {
    navigate('/login')
  }

  useEffect(() => {
    let a = localStorage.getItem(('My Number'))
    setGetNumber(a)
  }, [])

  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      props.submit()
    }
  }

  return (<>
    <Container>
      <nav className="navbar">
        <div className="container justify-content-center align-items-center">
          <div className="row">
            <div className="col-md-12">
              <a className="navbar-brand" href="#"><img src="./images/weldonrx-logo.png" style={{ width: '160px' }} /> </a>
            </div>
          </div>
        </div>
      </nav>

      <div className="loging-section">
        <div className="container">
          <div className="row">
            <div className="col-md-5 mx-auto">
              <div id="first">
                <div className="myform mylogin-form form ">
                  <div className="logo mb-3">
                    <div className="col-md-12 text-center">           
                      <h3> <strong> Check Your</strong> Phone </h3>
                      <p>{props.otpErrMsg}</p>
                    </div>
                  </div>
                  
                  <form action="" method="post" name="otp">
                    <div className="otp-number">
                      <div className="otp-icon">
                        {getNumber}
                        <span onClick={handleClick} className='btn'><img src="./images/otp-icon.svg" /></span>
                      </div>
                    </div>

                    <div className="opt-box">
                      <ul>
                        <div className="input-group phone-filed-transparent mb-3 mt-3 bg-white mw-360px">
                          <input type="text" placeholder='Enter OTP' onChange={props.handleOtp} value={props.getOtp} onKeyPress={handleKeyPress} className="form-control bg-transparent text-white p-2 inputhero" style={{ fontSize: '17px', textAlign: 'center' }} aria-label="Username" aria-describedby="basic-addon1" />
                        </div>
                      </ul>
                    </div>

                    <div className="recive-code btn" onClick={props.submitButton}  > Don’t receive code? <div > Request again </div> </div>
                    <div className="col-md-12 text-center " onClick={props.submit}>

                      {props.loading ?
                        <button type="button" className="btn btn-primary login-btn" disabled>Processing</button> :
                        <button type="button" className="btn btn-primary login-btn" >Continue</button>
                      }

                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="loging-footer-section">
        <div className="container justify-content-center align-items-center">
          <div className="row">
            <div className="col-md-12">
              <div className="footer-full">
                <p> &copy; 2023 -  Weldon Pharmacy. All rights reserved. </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>

  </>)
}

export default OtpConfirmation 