import Modal from 'react-bootstrap/Modal';
import { useNavigate,} from 'react-router-dom';
import Button from 'react-bootstrap/esm/Button';

  const Modals = (props,{pharmacyDetails}) => {
  const navigate = useNavigate();
  
  const transfer = () => {
    navigate('/TransferPrescription')
  }

  // console.log(props.pharmacyDetails)


  return (<>
    
    <Modal {...props} size="xm"className="modal fade" centered  aria-labelledby="exampleModalLabel" aria-hidden="true"  tabIndex="-1" >
      <div className="modal-body popup-fermacy">
         <div className="popup-photo-box">
           <img src="./images/popup-fermacy.svg" />

         </div>
         <h3> Transferring From  </h3>
         <div className="modal-content-col">
           <h4> {props?.pharmacydetails?.name} </h4>
           <p> {props?.pharmacydetails?.street} {props?.pharmacydetails?.province} {props?.pharmacydetails?.city} {props?.pharmacydetails?.zip_code}</p>
            <h5>Ph. {props?.pharmacydetails?.phone} </h5>
            <h5>Fax.  {props?.pharmacydetails?.fax} </h5>
         </div>
           
      </div>
      <div className="modal-footer popup-fermacy-footer">
        <button type="button" onClick={props.onHide} className="btn" data-bs-dismiss="modal" >Cancel</button>
        <button type="button" className="btn btn-primary" onClick={transfer}>Transfer</button>
      </div>
  </Modal>
  </>)
}
export default Modals