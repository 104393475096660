export const contactInformation = [
    {
        getinTouch: 'Connect with Us',
    },
    {
        goals: "We're Here to Listen and Help"
    },
    {
        emergencyLine: '24/7 Emergency Line',
    },
    {
        emergencyLinenumber: '+1 325-220-9901'
    },
    {
        getInTouch: 'Get in touch with us today by filling out the form below.'

    },
    {
        contactUsDescription: "We welcome your questions, comments, and feedback, and our team is always happy to assist you in any way we can. Our team will get back to you as soon as possible. Thank you for considering us as your trusted healthcare partner!"
    },
    {
        location: 'Our Location',
        address1: '4676 Hastings St, Burnaby, British Columbia V5C 2K5, Canada'
    },
    {
        workingHours: 'Working Hours',
        days: 'Monday - Friday: 8AM - 7PM Saturday: Closed Sunday: Closed'
    },

]

