export const AppUrl = 'https://app.weldonrx.com'

export const registerUrl = `${AppUrl}/api/register`;

export const loginUrl = `${AppUrl}/api/login`;

export const logoutUrl = `${AppUrl}/api/logout`;

export const userProfileUrl = `${AppUrl}/api/update-profile`;

export const myProfileUrl = `${AppUrl}/api/get-profile`;

export const myAddressUrl = `${AppUrl}/api/update-address`;

export const myImageUrl = `${AppUrl}/api/update-profile-image`;

export const existPhoneNumberUrl = `${AppUrl}/api/check-exist-phone-email`;

export const createOrderUrl = `${AppUrl}/api/create-order`;

export const pharmacyIdUrl = `${AppUrl}/api/get-pharmacies`

export const orderList = `${AppUrl}/api/get-order-list`;

export const clientId = '301866623914-qkvvkbq3eo1js4f4g3duen5rhen81knd.apps.googleusercontent.com';

export const newClientId = '623160978291-h3o1iq0kp4l2b773o5fkno0a415i9pkb.apps.googleusercontent.com'

export const pharmacy = `${AppUrl}/api/get-pharmacies?page=3&lat=51.6386083&long=-121.2921997`

export const pillRemainderUrl = `${AppUrl}/api/pill-reminder/create`;

export const getPillRemainderUrlList = `${AppUrl}/api/pill-reminder/list`;

export const deletePillRemainder = `${AppUrl}/api/pill-reminder/delete`;

export const updateMobileNumber = `${AppUrl}/api/update-phone-verification`

export const updateOtp = `${AppUrl}/api/update-phone`

export const feedback = `${AppUrl}/api/user-feedback/create`

export const addAddress = `${AppUrl}/api/add-address`

export const getAllAddress = `${AppUrl}/api/get-user-address`

export const deleteAddress = `${AppUrl}/api/delete-address`

export const contactUs = `${AppUrl}/api/contact-us`




