import { CgFacebook } from 'react-icons/cg';
import { FaTwitter } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { AiFillLinkedin } from 'react-icons/ai';
import { BsCircleFill } from 'react-icons/bs';
import { FaGooglePlay } from 'react-icons/fa';
import { AiFillApple } from 'react-icons/ai';
import Container from 'react-bootstrap/esm/Container';
import { useNavigate } from 'react-router-dom';

const Footer = () => {

    const navigate = useNavigate();

    const HandleClick = () => {
        navigate('/AboutUs')
        window.scrollTo({ top: 0, left: 0 });
    }

    const handleClicked = () => {
        window.scrollTo({ top: 0, left: 0 });
        navigate('/ContactUs')
    }

    const handleProcessChange = () => {
        window.scrollTo({ top: 900, left: 0 });
        navigate('/')
    }

    const handleFaqProcess = () => {
        window.scrollTo({ top: 1850, left: 0 });
        navigate('/')
    }

    const handleLoginProcess = () => {
        window.scrollTo({ top: 0, left: 0 });
        navigate('/login')
    }

    const PrivacyPolicy = () => {
        navigate('/PrivacyPolicy')
        window.scrollTo({ top: 0, left: 0 });
    }

    const handleTermsAndConditions = () => {
        navigate('/toc')
        window.scrollTo({ top: 0, left: 0 });
    }

    const handleClickCareers = () => {
        navigate('/Careers')
        window.scrollTo({ top: 0, left: 0 });
    }

    const handleReviews = () => {
        navigate('/Reviews')
        window.scrollTo({ top: 0, left: 0 });
    }

    return (<>
        <Container >
            <a className="navbar-brand footer-logo-small d-md-none d-sm-block d-block"><img src="./images/logo.svg" />
            </a>
            <div className="footer-container">
                <div className="container  mt-md-5 mt-sm-0 mt-0">
                    <footer className="py-md-5 py-sm-0 py-0 footer-row" style={{ paddingBottom: '7px !important' }}>
                        <div className="row">
                            <a className="navbar-brand d-md-block d-sm-none d-none footer-logo" href=''><img src="./images/logo.svg" /></a>
                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-4">

                                <ul className="nav flex-column contact-info">
                                    <li className="nav-item ">
                                        <a className="nav-link p-0 text-muted">
                                            <div className="d-flex">
                                                <div>
                                                    Accreditation #39331
                                                </div>
                                            </div>
                                        </a>
                                    </li>

                                    <li className="nav-item ">
                                        <a className="nav-link p-0 text-muted">
                                            <div className="d-flex">
                                                <div>
                                                    Pharmacy Manager<br />Prabhjot Sran
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="nav-item ">
                                        <a className="nav-link p-0 text-muted">
                                            <div className="d-flex">
                                                <div>
                                                    <svg width="20" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M2.375 20C1.875 20 1.4375 19.8125 1.0625 19.4375C0.6875 19.0625 0.5 18.625 0.5 18.125V1.875C0.5 1.375 0.6875 0.9375 1.0625 0.5625C1.4375 0.1875 1.875 0 2.375 0H23.625C24.125 0 24.5625 0.1875 24.9375 0.5625C25.3125 0.9375 25.5 1.375 25.5 1.875V18.125C25.5 18.625 25.3125 19.0625 24.9375 19.4375C24.5625 19.8125 24.125 20 23.625 20H2.375ZM23.625 3.59375L13.5 10.2188C13.4167 10.2604 13.3385 10.2969 13.2656 10.3281C13.1927 10.3594 13.1042 10.375 13 10.375C12.8958 10.375 12.8073 10.3594 12.7344 10.3281C12.6615 10.2969 12.5833 10.2604 12.5 10.2188L2.375 3.59375V18.125H23.625V3.59375ZM13 8.6875L23.5 1.875H2.53125L13 8.6875ZM2.375 3.59375V3.8125V2.57813V2.59375V1.875V2.59375V2.5625V3.8125V3.59375V18.125V3.59375Z" fill="white" />
                                                    </svg>
                                                </div>
                                                <div className="ml-4">info@weldonrx.com</div>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link p-0 text-muted">
                                            <div className="d-flex">
                                                <div>
                                                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M21.8438 23.25C19.3021 23.25 16.776 22.625 14.2656 21.375C11.7552 20.125 9.5 18.5 7.5 16.5C5.5 14.5 3.875 12.2448 2.625 9.73438C1.375 7.22396 0.75 4.69792 0.75 2.15625C0.75 1.76042 0.885417 1.42708 1.15625 1.15625C1.42708 0.885417 1.76042 0.75 2.15625 0.75H6.53125C6.82292 0.75 7.07813 0.848958 7.29688 1.04687C7.51562 1.24479 7.65625 1.51042 7.71875 1.84375L8.5625 5.78125C8.60417 6.07292 8.59896 6.33854 8.54688 6.57812C8.49479 6.81771 8.38542 7.02083 8.21875 7.1875L5.09375 10.3438C6.26042 12.2812 7.56771 13.9688 9.01562 15.4062C10.4635 16.8438 12.1042 18.0625 13.9375 19.0625L16.9062 16C17.1146 15.7708 17.3542 15.6094 17.625 15.5156C17.8958 15.4219 18.1667 15.4062 18.4375 15.4688L22.1562 16.2812C22.4688 16.3438 22.7292 16.5 22.9375 16.75C23.1458 17 23.25 17.2917 23.25 17.625V21.8438C23.25 22.2396 23.1146 22.5729 22.8438 22.8438C22.5729 23.1146 22.2396 23.25 21.8438 23.25ZM4.15625 8.625L6.6875 6.0625L5.96875 2.625H2.625C2.625 3.4375 2.75 4.32812 3 5.29688C3.25 6.26562 3.63542 7.375 4.15625 8.625ZM21.375 21.375V18.0312L18.1562 17.375L15.6875 19.9688C16.5417 20.3646 17.4687 20.6875 18.4688 20.9375C19.4688 21.1875 20.4375 21.3333 21.375 21.375Z" fill="white" />
                                                    </svg>
                                                </div>
                                                <div className="ml-1">604-365-4444</div>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link p-0 text-muted">
                                            <div className="d-flex">
                                                <div>
                                                    <svg width="20" height="20" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10 12.6875C10.6042 12.6875 11.1198 12.474 11.5469 12.0469C11.974 11.6198 12.1875 11.1042 12.1875 10.5C12.1875 9.89583 11.974 9.38021 11.5469 8.95312C11.1198 8.52604 10.6042 8.3125 10 8.3125C9.39583 8.3125 8.88021 8.52604 8.45313 8.95312C8.02604 9.38021 7.8125 9.89583 7.8125 10.5C7.8125 11.1042 8.02604 11.6198 8.45313 12.0469C8.88021 12.474 9.39583 12.6875 10 12.6875ZM10 23.0312C12.7708 20.5104 14.8177 18.224 16.1406 16.1719C17.4635 14.1198 18.125 12.3125 18.125 10.75C18.125 8.29167 17.3385 6.28125 15.7656 4.71875C14.1927 3.15625 12.2708 2.375 10 2.375C7.72917 2.375 5.80729 3.15625 4.23438 4.71875C2.66146 6.28125 1.875 8.29167 1.875 10.75C1.875 12.3125 2.55208 14.1198 3.90625 16.1719C5.26042 18.224 7.29167 20.5104 10 23.0312ZM10.0313 25.125C9.88542 25.125 9.75 25.1042 9.625 25.0625C9.5 25.0208 9.38542 24.9583 9.28125 24.875C6.17708 22.1458 3.85417 19.6146 2.3125 17.2812C0.770833 14.9479 0 12.7708 0 10.75C0 7.625 1.00521 5.13542 3.01563 3.28125C5.02604 1.42708 7.35417 0.5 10 0.5C12.6458 0.5 14.974 1.42708 16.9844 3.28125C18.9948 5.13542 20 7.625 20 10.75C20 12.7708 19.2292 14.9479 17.6875 17.2812C16.1458 19.6146 13.8229 22.1458 10.7188 24.875C10.6146 24.9583 10.5052 25.0208 10.3906 25.0625C10.276 25.1042 10.1563 25.125 10.0313 25.125Z" fill="white" />
                                                    </svg>
                                                </div>
                                                <div className="ml-1">4676 Hastings St, Burnaby <br />British Columbia V5C 2K5, Canada</div>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6 mt-4">
                                <h5 className="text-white mt-3">Navigation</h5>
                                <ul className="nav flex-column footer-nav">
                                    <li className="nav-item mb-2">
                                        <a onClick={HandleClick} className="btn nav-link p-0 text-muted" style={{ border: 'none' }}>About</a>
                                    </li>
                                    <li className="nav-item mb-2" onClick={handleProcessChange}>
                                        <a className="btn nav-link p-0 text-muted" style={{ border: 'none' }}>Process</a>
                                    </li>
                                    <li className="nav-item mb-2" onClick={handleClickCareers}>
                                        <a className="btn nav-link p-0 text-muted" style={{ border: 'none' }}>Careers</a>
                                    </li>
                                    <li className="nav-item mb-2" onClick={handleReviews}>
                                        <a className="btn nav-link p-0 text-muted" style={{ border: 'none' }}>Reviews</a>
                                    </li>
                                </ul>
                            </div>

                            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-6 col-6 mt-4">
                                <h5 className="text-white mt-3">&nbsp;</h5>
                                <ul className="nav flex-column footer-nav">
                                    <li className="nav-item mb-2">
                                        <a className="btn nav-link p-0 text-muted" onClick={handleFaqProcess} style={{ border: 'none' }}>FAQ </a>
                                    </li>
                                    <li className="nav-item mb-2">
                                        <a className="nav-link p-0 text-muted">Blog</a>
                                    </li>

                                    <li className="nav-item mb-2">
                                        <a className="btn nav-link p-0 text-muted" onClick={handleClicked} style={{ border: 'none' }}>Contact </a>
                                    </li>

                                    <li className="nav-item mb-2" >
                                        <a className="btn nav-link p-0 text-muted" onClick={handleLoginProcess} style={{ border: 'none' }}>Login</a>
                                    </li>

                                </ul>
                            </div>

                            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-4">
{/* 
                                <h5 className="text-white"> Member of</h5>
                                <ul className="nav flex-column footer-nav">
                                    <li className="nav-item mb-2" >
                                        <img src='./images/logo/member-of-bc-pharmacy-association.png' style={{ width: '80%' }} />
                                    </li>
                                </ul> */}

                                <h5 className="text-white mt-4">Licensed by</h5>
                                <ul className="nav flex-column footer-nav">
                                    <li className="nav-item mb-2" >
                                        <img src='./images/logo/college-of-pharmacists-of-british-columbia.png' style={{ width: '80%' }} />
                                    </li>
                                </ul>

                            </div>

                            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12 mt-4">
                                <form>
                                    <h5 className="text-white mt-4 mb-2">Download</h5>
                                    <div><a href="https://play.google.com/store/apps/details?id=com.weldonrx" className="googleplay"><i className="fa-brands fa-google-play"><FaGooglePlay /></i> Google Play</a></div>
                                    <div className="mt-3"><a href="" className="applestore"><i className="fa-brands fa-apple"><AiFillApple /></i> Apple Store</a></div>
                                </form>
                            </div>

                        </div>

                        <div className="row mt-2 mb-2">
                            <div className="col-3"></div>
                            <div className="col-md-6 col-sm-12 col-12 policty text-center">
                                <ul className="d-flex p-0 justify-center-lg">

                                    <span className='btn'>
                                        <li onClick={handleTermsAndConditions} ><a >Terms of Conditions</a></li>
                                        <li><i className="fa-solid fa-circle text-white"><BsCircleFill style={{ marginBottom: '2px', marginLeft: '6px' }} /></i> </li>
                                        <li onClick={PrivacyPolicy} ><a >Privacy Policy</a></li>
                                    </span>
                                </ul>
                            </div>
                            <div className="col-md-2 col-sm-12 col-12 sociallinks ml-auto">
                                <ul className="d-flex p-0">
                                    <li className="m-1"><a href="https://twitter.com/weldon_rx/"><i className="fa-brands icon fa-twitter"><FaTwitter /></i></a></li>
                                    <li className="m-1"><a href="https://www.linkedin.com/company/weldonrx/"><i className="fa-brands  icon fa-linkedin"><AiFillLinkedin /></i></a></li>
                                    <li className="m-1"><a href="https://www.facebook.com/weldonrx/"><i className="fa-brands icon fa-facebook-f"><CgFacebook /></i></a></li>
                                    <li className="m-1"><a href="https://www.instagram.com/weldonrx/"><i className="fa-brands icon fa-instagram"><FaInstagram /></i></a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="border-top-footer">
                            <p className="p-2 pb-0 text-center footercopyright text-white" >Weldon Pharmacy is licensed by<a href='https://www.bcpharmacists.org/' style={{ textDecoration: 'none', color: 'white' }} > College of Pharmacists of British Columbia</a> (604-733-2440)<br />
                                &copy; 2023 -  Weldon Pharmacy. All rights reserved.</p>
                        </div>
                    </footer>
                </div>
            </div>
        </Container>

    </>)
}
export default Footer; 