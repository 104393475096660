import { useState, useEffect, useMemo } from "react"
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { decrypteData } from "../sharedApi/customeAxios";

const SetProfile = (props) => {

  const location = useLocation();
  const getProfileFromStorage = () => decrypteData();

  const [profile, setProfile] = useState(() => getProfileFromStorage());
  useMemo(() => {
    const token = getProfileFromStorage();
    if (token) {
      setProfile(token);
    }
  }, [location]);

  useEffect(() => {
    if (!profile) {
      setProfile(getProfileFromStorage());
    }
  }, [profile]);

  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/Settings')
  }

  return (<>
    <div className="card-logo-col" >
      <Link to='/dashboard'> <img src="./images/weldonrx-logo.png" style={{ width: "160px" }} /> </Link>
      <div className="user-dropdown-group">
        <div className='dropdown-toggle'>
          <div className="user-dropdown-toggle">
            <img src={profile?.profile_image} />
            <div className="media-body">
              <h5 className="mt-0">{props.day}</h5>
              <h4>{profile?.full_name}</h4>
            </div>
          </div>

          <div className="wx_ShowHideTab">
            <button className="accordion-button collapsed m-0" type="button" >
              <img src="./images/myprofile/settingic.svg" className="btn" onClick={handleClick} />
            </button>
          </div>
        </div>
      </div>
    </div>
  </>)
}
export default SetProfile;