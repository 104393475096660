import Image from 'react-bootstrap/Image'
import { useEffect, useState } from "react";
import { orderList } from "../sharedApi/common";
import { Link } from "react-router-dom";
import MedicineScheudle from "../medicineScheudle/medicineScheudle";
import Lists from "../medicineScheudle/list";
import LogOut from "../logOut/logOut";
import SetProfile from "../profile/setProfile";
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";
import { getToken } from '../sharedApi/customeAxios';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { FcExpired } from 'react-icons/fc';
import CryptoJS from 'crypto-js';
import LogoutTimer from '../logOut/logOutTimer';
    
const OrderList = (props, { type }) => {
    const [orderId, setOrderId] = useState([])
    const [isloading, setIsLoading] = useState(false);
    const [loader, setLoader] = useState(false)
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);

    const navigate = useNavigate();
    const token = getToken();
    
  const handleClose = () => {
    setShow(false)    
  }

    const getProfile = async () => {
        setLoading(true)
        setLoader(true)
        setIsLoading(true)
        if (token) {
            const rawResponse = await fetch(orderList,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                }
            )
            const response = await rawResponse.json();
            setOrderId(response.data)
            setIsLoading(false)
            setLoading(false)
            setTimeout(() => {
                setLoader(false)
            }, 1000)

            if(response.message === 'Unauthenticated.'){
                setShow(true)
                setTimeout(()=>{
                localStorage.removeItem('Token_access')
                localStorage.removeItem('u/\s/p/r\e')    
                localStorage.removeItem('My Number')
                localStorage.removeItem('O/[]r/d/I/d')
                localStorage.removeItem('activeTab')
                navigate('/')
                },3000)
              }
        }
    };

    useEffect(() => {
        getProfile();
    }, [])

  const activeLink = 3;

  
  const handleOrderId = (id) => {
    
    const encryptedOrderID = CryptoJS.AES.encrypt(JSON.stringify(id),'secret key').toString();
    localStorage.setItem('O/[]r/d/I/d', encryptedOrderID);

  }

  const handleOrderDate = (id) => {

    const encryptedOrderID = CryptoJS.AES.encrypt(JSON.stringify(id),'secret key').toString();
    localStorage.setItem('O/[]r/date', encryptedOrderID);

  }

    return (<> 
     <HelmetProvider>
          <Helmet>
            <meta name="robots" content="noindex,nofollow" />
        </Helmet>
      </HelmetProvider>

        <section className="dark-back">
            <div className="profile-2-col">
                <div className="container justify-content-center align-items-center">
                    <div className="row">
                        <div className="col-md-4">

                            {/* <!----------------------Components------------------!>   */}

                            <SetProfile day={props.day} />
                            <MedicineScheudle />
                            <Lists accesstoken={props.accesstoken} activeLink={activeLink}/>
                            <LogOut logOut={props.logOut} />
                            <LogoutTimer onLogout={props.logOut} />        

                            {/* <!------------------------------------------------------!>*/}

                        </div>

                        <div className="col-md-8">
                            <div className="profile-right-col">
                                <div className="uplode-pic-row">
                                    <div className="uplode-pic-left">
                                        <h3> My Orders </h3>
                                        <p> Here you will the list or orders you have created and track them. </p>
                                    </div>
                                </div>

                                <div className="pharmacy-search mt-5" >
                                    {loading ?
                                     <div className="text-center mt-3">
                                        <Spinner animation="border" role="status" >
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                        </div>
                                        :
                                        <>
                                            <div className="row pb-4">
                                                <div className="col-md-12">
                                                    <div className="search-header">
                                                        <h5>
                                                            {isloading ?
                                                                <Card style={{ width: '155px', height: '50px', backgroundColor: "#ecebeb", borderColor: 'white' }}>
                                                                </Card>
                                                                : <>
                                                                    {orderId?.reduce((total, value) => total += 1, 0)}  Results </>}
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                {orderId?.sort((a, b) => new Date(b.order_date) - new Date(a.order_date)).map((value, index) => {
                                                    return (
                                                        <div key={index} className="col-md-4 pt-4">
                                                            {loader ?
                                                                <Card className='pharmacy-col' style={{ height: '227.3px', backgroundColor: "#ecebeb", borderColor: 'white' }}>
                                                                </Card>
                                                                :
                                                                <div className="pharmacy-col">
                                                                    <Image src="./images/order-track-icon.svg" />
                                                                    <span className="track-icon"> <Image src="./images/track-icon.svg" /> <h6>{value.status} </h6> </span>
                                                                    <h4>#{value.id} </h4>

                                                                    {(() => {
                                                                        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
                                                                        const date = new Date(value.order_date);
                                                                        const formattedDate = `Placed On: ${months[date.getMonth()]} ${date.getDate()},${date.getFullYear()}`;
                                                                        return <p>{formattedDate}</p>;
                                                                    })()}

                                                                    <Link to='/trackOrder' >
                                                                        <span onClick={() => handleOrderDate(`${value.order_date}`)}>
                                                                            {/* <span onClick={() => props.handleStatus(`${value.id}`)}> */}
                                                                                <button type="button" className="btn" onClick={() => handleOrderId(`${value.id}`)}>
                                                                                    Track Order
                                                                                </button>
                                                                            </span>
                                                                        {/* </span> */}
                                                                    </Link>
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </>} </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title><FcExpired />Your session has expired</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please log in again!</Modal.Body>
      </Modal>

    </>)
}
export default OrderList;