import Modal from 'react-bootstrap/Modal'
import {Link} from 'react-router-dom'

const ModalTwo = (props) =>
{
    return(<>
      <Modal {...props}  size="md" aria-labelledby="contained-modal-title-vcenter" centered className="modal fade" id="exampleModal" tabIndex="-1"  aria-hidden="true">
        
      <Modal.Body>
       <div className="modal-body popup-thank-you">
          <div className="thank-you-box">
            <img src="./images/thank-you.png" />
          </div>
          <h2> Thank You! </h2>
          <p> We have received your prescription details. Our team will connect with you shortly. </p>
       </div>
       <div className="modal-footer popup-fermacy-footer" style={{marginLeft:'105px'}}>
 
          <Link to='/dashboard'> 
              <button type="button" className="btn btn-primary" style={{width:'227.52px'}} onClick={props.onHide}   data-bs-dismiss="modal"> Got It! </button>
         </Link>
       </div>
      </Modal.Body>
          
    </Modal>
    </>)
}
export default ModalTwo