import { useState, useEffect, useCallback } from 'react'
import Modals from '../main/modal'
import MedicineScheudle from '../medicineScheudle/medicineScheudle'
import List from '../medicineScheudle/list'
import LogOut from '../logOut/logOut'
import Button from 'react-bootstrap/esm/Button'
import Spinner from 'react-bootstrap/Spinner';
import { useRef } from 'react'
import SetProfile from '../profile/setProfile'
import { BiSearch } from 'react-icons/bi'
import axios from 'axios'
import { debounce } from 'lodash';
import { AppUrl, pharmacyIdUrl } from '../sharedApi/common'
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { FcExpired } from 'react-icons/fc';
import LogoutTimer from '../logOut/logOutTimer'

const RxTransfer = (props) => {

  const [recentSearch, setRecentSearch] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [items, setItems] = useState([]);
  const [pageCount, setpageCount] = useState(2);
  const [isCompleted, setIsCompleted] = useState(false);
  const [loading, setLoading] = useState(false)
  const [loadButton, setLoadButton] = useState(false)
  const [trandingSearche, setTrandingSearche] = useState([])
  const [results, setResults] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [recentSearches, setRecentSearches] = useState(() => {
    const storedSearches = localStorage.getItem('recentSearches');
    return storedSearches ? JSON.parse(storedSearches) : [];
  })

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false)    
  }

  const navigate = useNavigate();

  const inputRef = useRef(null);
  
  const handleClick = () => {
    setRecentSearch(false)
  }

  useEffect(() => {
    localStorage.setItem('recentSearches', JSON.stringify(recentSearches));
  }, [recentSearches]);

  const handleFilter = async (event) => {
    const searchWord = event.target.value;
    setSearchQuery(searchWord.charAt(0).toUpperCase() + searchWord.slice(1));
    setLoading(true)
  }

  const fetchSearchResults = useCallback(
    debounce(async () => {
      setLoading(true)

      let token = JSON.parse(localStorage.getItem('Token_access'))
      if (token) {
        const isNumber = /^[\d-()+ ]+$/.test(searchQuery);
        const params = {
          page: 1,
          lat: 51.6386083,
          long: -121.2921997,
        };
        if (isNumber) {
          params.phone = searchQuery;
        } else {
          params.name = searchQuery;
        }

        try {
          const response = await axios.get(pharmacyIdUrl, {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            params: params,
          });
          setItems(response.data.data.data);
          setLoadButton(true);
          setIsCompleted(false);
          setResults(true);
        } catch (error) {
        } finally {
          setLoading(false);
        }
      }
    },1000),
    [searchQuery]
  );

  useEffect(() => {
    if (searchQuery) {
      fetchSearchResults();
    }
    return () => {
      fetchSearchResults.cancel();
    };
  }, [searchQuery, fetchSearchResults]);
  const handleKeyDown = (event) => {
    if (event.key === 'Backspace' && searchQuery === '') {
      setLoading(false)
      setRecentSearches(prevSearches => prevSearches.slice(0, -1));
    }
  }

  const handleBlur = () => {
    if (searchQuery !== '') {
      setLoading(false)
      if (!recentSearches.includes(searchQuery)) {
        setRecentSearches(prevSearches => [...prevSearches, searchQuery].slice(-7));
      }
    }
  }

  const fetchComments = async () => {
    let token = JSON.parse(localStorage.getItem('Token_access'))
    if (token) {
      const rawResponse = await fetch(
        `${AppUrl}/api/get-pharmacies?page=${pageCount}&lat=51.6386083&long=-121.2921997&name=${searchQuery}`
        , {
          method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + token,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        }
      )
      const data = await rawResponse.json();
      return (data.data.data)
    }
  };

  useEffect(() => {
    fetchComments();
  }, [])

  const LoadMore = async () => {
    setpageCount(pageCount + 1)

    const commentsFormServer = await fetchComments(pageCount);

    setItems((prev) => [...prev, ...commentsFormServer])

    if (pageCount >= items.length) {
      setIsCompleted(true)
    } else {
      setIsCompleted(false)
    }
  }

  /*-----------------------Is Tranding---------------------- */

  const trandingSearches = debounce(async () => {
    let token = JSON.parse(localStorage.getItem('Token_access'))
    if (token) {
      try {
        setRecentSearch(true);
        const response = await axios.get(
          `${AppUrl}/api/get-pharmacies`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            params: {
              lat: 51.6386083,
              long: -121.2921997,
              name: searchQuery,
              is_tranding: 1,
            },
          }
        );
        setTrandingSearche(response.data.data.data);
        // console.log(response.data)
        setTimeout(() => {
          setRecentSearch(false);
        }, 2000);
        return response.data.data.data;
      } catch (error) {
        // console.error(error);
        if(error.response.data.message ==='Unauthenticated.'){
          // console.log('Unauthenticated.')
          setShow(true)
          setTimeout(()=>{
          localStorage.removeItem('Token_access')
          localStorage.removeItem('u/\s/p/r\e')
          localStorage.removeItem('My Number')
          localStorage.removeItem('O/[]r/d/I/d')
          localStorage.removeItem('activeTab')
          navigate('/')
          },2000)
        }
      }
    }
  }, 500);


  useEffect(() => {
    trandingSearches()
  }, [])

  const handleClicks = (value) => {
    inputRef.current.focus();
    setSearchQuery(value)
  }

    const handlePharmacyId = (id) =>{
        // console.log(id)

        const encryptedUserData = CryptoJS.AES.encrypt(JSON.stringify(id),'secret key').toString();
        localStorage.setItem('p/\h/r\m/c\/i[]d', encryptedUserData);
    }
  const activeLink = 0; 

  const[pharmacyDetails,setPharmacyDetails] = useState([])

  const handlePharmacyDetails = (value) =>{
    // console.log(value)
    setPharmacyDetails(value)
  }


  return (<>

      <HelmetProvider>
          <Helmet>
            <meta name="robots" content="noindex,nofollow" />
        </Helmet>
      </HelmetProvider>

    <section className='dark-back'>
      {/* <Container className="container" onChange={handleClick}>  */}
      <div className="profile-2-col">
        <div className="container justify-content-center align-items-center">
          <div className="row">
            <div className="col-md-4">

              {/* <!----------------------Components------------------!>   */}
              <SetProfile day={props.day} />
              <MedicineScheudle />
              <List activeLink={activeLink}/>
              <LogOut logOut={props.logOut} />
              <LogoutTimer onLogout={props.logOut} />        
              {/* <!------------------------------------------------------!>*/}

            </div>

            <div className="col-md-8">
              <div className="profile-right-col">
                <h4> Switch to <span className="color-text"> WeldonRx </span> Pharmacy </h4>
                <p> Please select your current pharmacy, our team will then transfer your prescription to WeldonRx. </p>
                <div className="search-box">
                  <p> Search for your current pharmacy </p>
                  <div className="form-floating mb-3">
                    <input className="form-control" id="emailAddress" value={searchQuery} type="text" onChange={handleFilter} placeholder="First name" data-sb-validations="required,name" onKeyDown={handleKeyDown} onBlur={handleBlur} ref={inputRef} />
                    <span className="form-icon"> <i className="fa-solid fa-search"><BiSearch size={25} /></i> </span>
                    <label htmlFor="emailAddress"> Enter name... </label>
                    <div className="invalid-feedback" data-sb-feedback="emailAddress:required">Name is required.</div>
                    <div className="invalid-feedback" data-sb-feedback="emailAddress:email">Name is not valid.</div>
                  </div>
                </div>

                {recentSearch ?
                 <div className="text-center mt-3">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                  </div>
                  :
                  <div>
                    <div className="trading">
                      <h5> <img src="./images/trading-icon.svg" /> Trending Searches </h5>
                      <ul>
                        {trandingSearche.map((value, index) => {
                          const name = value.name.toLowerCase();
                          const capitalizeWords = (str) => {
                            return str.split(' ').map(word => {
                              return word.charAt(0).toUpperCase() + word.slice(1);
                            }).join(' ');
                          };
                          const capitalized = capitalizeWords(name);
                          return (
                            <li key={index} style={{ padding: '5px' }} onClick={() => handleClicks(capitalized)}>
                              <a className='btn'>{capitalized}</a>
                            </li>
                          )
                        })
                        }

                      </ul>
                    </div>
                    <div>

                      {results ?
                        <div>
                          {!loading && items.length === 0 && <p className='text-center mt-3'>No Results found.</p>}
                        </div>
                        : null
                      }

                      {items.length != [] && (
                        <div > <br />
                          <div className="pharmacy-search" >
                            <div className="row">
                              <div className="col-md-12">
                                <div className="search-header">
                                </div>
                              </div>
                            </div>

                            {loading ?
                             <div className="text-center mt-3">
                              <Spinner animation="border" role="status" >
                                <span className="visually-hidden">Loading...</span>
                              </Spinner>
                              </div>
                              :
                              <div className="row" >
                                {items.map((value, index) => {
                                  return (
                                    <div className="col-md-6" key={index}>
                                      <div className="pharmacy-col">
                                        <img src="./images/pharmecy-icon.svg" />
                                        <h4>{value.name}</h4>
                                        <p> {value.street} {value.province} {value.city}  {value.zip_code} </p>
                                      
                                        <div onClick={() => handlePharmacyDetails(value)} >
                                              <div onClick={()=>handlePharmacyId(value.id)}>
                                                  <button type="button" className="btn" onClick={() => setModalShow(true)}>
                                                    Select
                                                  </button>
                                              </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                                }
                              </div>}
                          </div>

                          <div style={{ textAlign: 'center' }}>
                            {loadButton ?
                              <>
                                {isCompleted ? (<Button onClick={LoadMore} type="button" className="mt-2 mb-2 btn btn-danger disabled">
                                  That's It
                                </Button>
                                ) : (
                                  <Button onClick={LoadMore} className="mt-2 mb-2 btn btn-danger">
                                    Load More +
                                  </Button>
                                )}
                              </> : null}
                          </div>
                        </div>
                      )}
                    </div>

                    <Modals show={modalShow} onHide={() => setModalShow(false)} pharmacydetails={pharmacyDetails}  />

                    <div className="Recent-Searches">
                      <h5> Recent Searches </h5>
                      <ul>
                        {recentSearches.map(search =>
                          <li key={search}><img src="./images/clock.svg" style={{ width: '13px' }} />
                            &nbsp;{search.charAt(0).toUpperCase() + search.slice(1)}
                          </li>)
                        }
                      </ul>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </Container> */}
    </section>
            
    <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title><FcExpired />Your session has expired</Modal.Title>
        </Modal.Header>
        <Modal.Body>Please log in again!</Modal.Body>
        
      </Modal>

  </>)
}
export default RxTransfer;

