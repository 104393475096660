import React, { useEffect, useState } from "react";
import axios from 'axios'
import { AppUrl } from '../sharedApi/common'
import Card from 'react-bootstrap/Card';
import DOMPurify from 'dompurify';

const PrivacyAndPolicy = () => {
  const [privacyPolicyData, setPrivacyPolicyData] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function makeRequest() {
      let token = JSON.parse(localStorage.getItem('Token_access'))
      if (token) {
        setLoading(true)
        const response = await axios.get(`${AppUrl}/api/content`, {
          headers: {
            'Authorization': 'Bearer ' + token,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          }
        });
        // console.log(response.data.data); 
        if (response?.data) {
          setLoading(false)
          setPrivacyPolicyData(response.data.data)
        }
      }
    }
    makeRequest();
  }, [])

  const sanitizedHTML = privacyPolicyData && privacyPolicyData[1] ? DOMPurify.sanitize(privacyPolicyData[1].content) : '';

return (<>
       <h2> Privacy & Policy </h2>
       <div className="card wx_myprofileTextRight wx_MyContactBg mt-0">
         {loading ? <>
           <Card className="pill-schedule-row" style={{ height: '108px', backgroundColor: "#ecebeb", borderColor: 'white', margin: '30px' }}></Card>
           <Card className="pill-schedule-row" style={{ height: '108px', backgroundColor: "#ecebeb", borderColor: 'white', margin: '0px 30px 30px 30px' }}></Card>
           <Card className="pill-schedule-row" style={{ height: '108px', backgroundColor: "#ecebeb", borderColor: 'white', margin: '0px 30px 30px 30px' }}></Card>
           <Card className="pill-schedule-row" style={{ height: '108px', backgroundColor: "#ecebeb", borderColor: 'white', margin: '0px 30px 30px 30px' }}></Card>
           <Card className="pill-schedule-row" style={{ height: '108px', backgroundColor: "#ecebeb", borderColor: 'white', margin: '0px 30px 30px 30px' }}></Card>
         <Card className="pill-schedule-row" style={{ height: '108px', backgroundColor: "#ecebeb", borderColor: 'white', margin: '0px 30px 30px 30px' }}></Card>
           <Card className="pill-schedule-row" style={{ height: '108px', backgroundColor: "#ecebeb", borderColor: 'white', margin: '0px 30px 30px 30px' }}></Card>
        </>
          :
          <div className="card-body">
            <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }}></div>
          </div>}
      </div>
    </>)
  }

export default PrivacyAndPolicy;
