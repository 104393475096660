import { useState } from "react";
import { Link } from "react-router-dom";
import { TbRefresh } from 'react-icons/tb';
import { SlArrowRight } from 'react-icons/sl';
import { FiCamera } from 'react-icons/fi';
import { FiTruck } from 'react-icons/fi';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { GiNotebook } from 'react-icons/gi';

function MyComponent({ activeLink }) {
  const defaultColor = '#003e82';
  const activeColor = '#FF4200';

  return (
    <div className="list-icon-row">
      <Link to='/RxTransfer' style={{ textDecoration: 'none' }}>
        <div className="list-icon-col">
          <span className="list-icon" style={{ color: activeLink === 0 ? activeColor : defaultColor }}  > <TbRefresh size={25} /> </span>
          <h5>Transfer Current Prescription</h5>
          {activeLink === 0 && <span className="question-icon"> <SlArrowRight style={{ color: activeColor }} /> </span>}
          {activeLink !== 0 && <span className="question-icon"> <AiOutlineInfoCircle style={{ color: defaultColor }} /> </span>}
        </div>
      </Link>

      <Link to='/NewPrescription' style={{ textDecoration: 'none' }}> 
        <div className="list-icon-col">
          <span className="list-icon" style={{ color: activeLink === 1 ? activeColor : defaultColor }}> <FiCamera size={25} /> </span>
          <h5>New Prescription Request</h5>
          {activeLink === 1 && <span className="question-icon"> <SlArrowRight style={{ color: activeColor }} /> </span>}
          {activeLink !== 1 && <span className="question-icon"> <AiOutlineInfoCircle style={{ color: defaultColor }} /> </span>}
        </div>
      </Link>

      <Link to='/PillReminder' style={{ textDecoration: 'none' }}>
        <div className="list-icon-col">
          <span className="list-icon" style={{ color: activeLink === 2 ? activeColor : defaultColor }}> <GiNotebook size={25} /> </span>
          <h5>Set Pill Reminder</h5>
          {activeLink === 2 && <span className="question-icon"> <SlArrowRight style={{ color: activeColor }} /> </span>}
          {activeLink !== 2 && <span className="question-icon"> <AiOutlineInfoCircle style={{ color: defaultColor }} /> </span>}
        </div>
      </Link>

      <Link to='/orderList' style={{ textDecoration: 'none' }}>
        <div className="list-icon-col">
          <span className="list-icon" style={{ color: activeLink === 3 ? activeColor : defaultColor }}> <FiTruck size={25} /> </span>
          <h5>Track Your Order</h5>
          {activeLink === 3 && <span className="question-icon"> <SlArrowRight style={{ color: activeColor }} /> </span>}
          {activeLink !== 3 && <span className="question-icon"> <AiOutlineInfoCircle style={{ color: defaultColor }} /> </span>}
        </div>
      </Link>
    </div>
  );
}

export default MyComponent;

