import Container from 'react-bootstrap/Container'
import {React} from 'react';
import { GoogleLogin, } from 'react-google-login';
import { FcGoogle } from 'react-icons/fc';
import { clientId } from '../sharedApi/common';
import Spinner from 'react-bootstrap/Spinner';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { LoginDescription,LoginTitle,LoginUrl } from '../metaTags/meta';
import { Link } from 'react-router-dom';

const LoginPage = (props) => {

    const onFailure = (res) => {
        console.log('Login Failed:', res);
    }

    function handleKeyPress(event) {
        if (event.key === 'Enter') {
          event.preventDefault(); 
        props.submit()  
        } 
      }
   
    return (<>

<HelmetProvider>
    <Helmet>
      <meta name="robots" content="index,follow" />
<link rel="alternate" href={ LoginUrl } hreflang="en" />
<title>{LoginTitle}</title>
<meta charset="utf-8" />
<meta name="author" content="WeldonRx"/>
<meta name="title" content={LoginTitle}/>
<meta name="description" content= { LoginDescription } />
<meta property="og:type" content="website" />
<meta property="og:url" content={ LoginUrl } />
<meta property="og:title" content={LoginTitle} />
<meta property="og:description" content= { LoginDescription }/>
<meta property="og:image" content="https://weldonrx.com/weldon-social-image.png" />
<meta property="twitter:card" content="summary_large_image" />
<meta property="twitter:url" content={ LoginUrl } />
<meta property="twitter:title" content={LoginTitle}/>
<meta property="twitter:description" content= { LoginDescription } />
<meta property="twitter:image" content="https://weldonrx.com/weldon-social-image.png" />
<link rel="canonical" href={ LoginUrl } />
  </Helmet>
</HelmetProvider>


        <Container>
            <nav className="navbar">
                <div className="container justify-content-center align-items-center">
                    <div className="row">
                        <div className="col-md-12">
                            <Link to='/' className="navbar-brand"><img src="./images/logo.svg" /> </Link>
                        </div>
                    </div>
                </div>
            </nav>

            <div className="loging-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-5 mx-auto">
                            <div id="first">
                                <div className="myform mylogin-form form ">
                                    <div className="logo">
                                        <div className="col-md-12 text-center">
                                        
                                            <h3 style={{margin:'10px 0'}}> <strong>Welcome</strong> Back</h3>
                                            <p>Please enter your mobile number below to login. </p>
                                        </div>
                                    </div>
                                    <form action="" method="post" name="login">

                                        <div className="input-group phone-filed-transparent mb-3 mt-3 bg-white mw-360px">
                                            <span className="bg-transparent input-group-text p-2" id="basic-addon1">
                                                <img src="./images/login-phone.svg" />
                                            </span>


                                            <input type="text" onChange={props.handleNumber} value={props.userNumDetail}  
                                             onKeyPress={handleKeyPress}   className="form-control bg-transparent text-white p-2 inputhero" style={{ fontSize: '17px' }} placeholder="10 digit phone number" aria-label="Username" aria-describedby="basic-addon1"  onKeyDown={(e) => {if (!/^\d+$/.test(e.key) && (e.key !== "Backspace") && (e.key !== "Enter")) {e.preventDefault();}}}/>
                                        </div>
                                            {props.errMsg}
                                        <div className="col-md-12 text-center ">
                                            <button type="button" className="btn btn-primary login-btn" onClick={props.submit}>
                                                
                                                {props.loadingPhoneNumber?'Processing Please wait' : 'Continue' }
                                                </button>
                                        </div>

                                        <div className="col-md-12 ">
                                            <div className="login-or">
                                                <hr className="hr-or" />
                                                <span className="span-or">or</span>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-3">
                
                                            <div >
                                                <GoogleLogin
                                                    clientId={clientId}
                                                    buttonText="Sign In"
                                                    onSuccess={props.onSuccess}
                                                    onFailure={onFailure}
                                                    cookiePolicy={'single_host_origin','none'}
                                                    isSignedIn={false}
                                                    render={(renderProps) => (
                                                    <div className="btn btn-light login-gmail-btn gmail-btn" onClick={renderProps.onClick}>
                                                        {props.googleLoginLoading ?  
                                                        <Spinner animation="border" role="status"  style={{marginTop:'5px'}}>
                                                        <span className="visually-hidden">Loading...</span>
                                                      </Spinner>
                                                        :   
                                                        <>
                                                        <FcGoogle size={30} className='float-left' />
                                                        <span >Login With Google</span> </>}
                                                    </div>
                                                    )}>
                                                    <span className="text-center" style={{ marginLeft: '80px', color: 'black', fontSize: '16px' }}> Login With Google </span> </GoogleLogin>
                                            </div> 
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="loging-footer-section">
                <div className="container justify-content-center align-items-center">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="footer-full">
                                <p> &copy; 2023 -  Weldon Pharmacy. All rights reserved. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </Container >
    </>)
}
export default LoginPage;



