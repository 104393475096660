import List from "../medicineScheudle/list";
import MedicineScheudle from "../medicineScheudle/medicineScheudle";
import LogOut from "../logOut/logOut";
import Image from "react-bootstrap/esm/Image";
import { useEffect, useState } from "react";
import SetProfile from "../profile/setProfile";
import Spinner from 'react-bootstrap/Spinner';
import { Link } from "react-router-dom";
import { AppUrl } from "../sharedApi/common";
import { getToken } from "../sharedApi/customeAxios";
import { decryptOrderID } from "../sharedApi/customeAxios";
import { decryptOrderDate } from "../sharedApi/customeAxios";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import LogoutTimer from "../logOut/logOutTimer";

const TrackingOrders = (props) => {
  const [orderId, setOrderId] = useState([])
  const [orderStatus, setOrderStatus] = useState([])
  const [orderDate, setOrderDate] = useState([])
  const [loading, setLoading] = useState(false)

  const token = getToken();

  useEffect(() => {
    let a = decryptOrderID();
    setOrderId(a)
  }, [orderId])

  useEffect(() => {
    setLoading(true)
    let a = decryptOrderID();
    if (token && a) {
      const getOrders = async () => {
        const rawResponse = await fetch(
          `${AppUrl}/api/get-order-detail/${a}`
          ,
          {
            method: 'GET',
            headers: {
              'Authorization': 'Bearer ' + token,
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            }
          }
        )
        const response = await rawResponse.json()
        if (response) {
          setTimeout(() => {
            setLoading(false)
          }, 1000)
          setOrderStatus(response.data.order_status_history.reverse())
        }
      };
      getOrders()
    }
  }, [])

  useEffect(() => {
    // let a = localStorage.getItem('orderDate')
    let a = decryptOrderDate();
    setOrderDate(a)
  }, [orderDate])

  const activeLink = 3;


  return (<>

    <HelmetProvider>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
    </HelmetProvider>

    <section className="dark-back">
      <div className="profile-2-col">
        <div className="container justify-content-center align-items-center">
          <div className="row">
            <div className="col-md-4">
              <SetProfile day={props.day} />
              <MedicineScheudle />
              <List activeLink={activeLink}/>
              <LogOut logOut={props.logOut} />
              <LogoutTimer onLogout={props.logOut} />        

            </div>

            <div className="col-md-8">
              <div className="profile-right-col">
                <div className="uplode-pic-row order-status-row">
                  <span className="left-arows-icon">

                    <Link to='/orderList' className="btn"> <Image src="./images/left-arows.svg" /> </Link>

                  </span>
                  <div className="uplode-pic-left">
                    <h3> Order Status </h3>
                    <p>Check your orders.</p>
                  </div>
                </div>

                <div className="row pt-4 pb-4">
                  <div className="col-md-12">
                    <div className="order-id">
                      <h3> Order Id #{orderId} </h3>
                    </div>
                  </div>
                </div>

                <div className="pharmacy-search">
                  <div className="card-body">
                    <div className="steps">

                      {loading ?
                        <div className="text-center mt-3">
                          <Spinner animation="border" role="status" >
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </div>

                        : <>
                          <div className="step completed">
                            <div className="step-icon-wrap">
                              <div className="step-icon"> <Image src="./images/processing.svg" /> </div>
                            </div>

                            <div className="step-content-wrap">
                              <h4 className="step-title">
                                Processing
                              </h4>
                              <h6> <span className="order-clock"><Image src="./images/click-order.svg" /> </span>{orderDate}  <br />

                              </h6>
                            </div>
                          </div>

                          {orderStatus?.map((value, index) => {
                            return (
                              <div className="card-body" key={index}>
                                <div className="steps">
                                  {
                                    value.status == 'Send Fax' ? <>
                                      <div className="step completed">
                                        <div className="step-icon-wrap">

                                          <div className="" style={{ textAlign: 'center' }}>
                                            <div className="step-icon" >
                                              <Image src="./images/ship.svg" /></div>
                                          </div></div>

                                        <div className="step-content-wrap">
                                          <h4 className="step-title">
                                            Send Fax
                                          </h4>
                                          <h6>
                                            <span style={{ color: 'black' }}>{value.admin_note}</span><br />
                                            <span className="order-clock"><Image src="./images/click-order.svg" /> </span>  {value.updated_at.slice(0, 19)}

                                          </h6>
                                        </div></div>
                                    </>
                                      :

                                      value.status == 'Waiting Approval' ? <>
                                        <div className="step completed">
                                          <div className="step-icon-wrap">

                                            <div className="" style={{ textAlign: 'center' }}>
                                              <div className="step-icon" >
                                                <Image src="./images/ship.svg" /></div>
                                            </div>
                                          </div>

                                          <div className="step-content-wrap">
                                            <h4 className="step-title">
                                              Waiting Approval
                                            </h4>
                                            <h6>
                                              <span style={{ color: 'black' }}>{value.admin_note}</span><br />
                                              <span className="order-clock"><Image src="./images/click-order.svg" /> </span> {value.updated_at.slice(0, 19)}
                                            </h6>
                                          </div></div>
                                      </>

                                        :

                                        value.status == 'Fax Reacived' ? <>
                                          <div className="step completed">
                                            <div className="step-icon-wrap">

                                              <div className="" style={{ textAlign: 'center' }}>
                                                <div className="step-icon" >
                                                  <Image src="./images/ship.svg" /></div>
                                              </div>
                                            </div>

                                            <div className="step-content-wrap">
                                              <h4 className="step-title">
                                                Fax Reacived
                                              </h4>
                                              <h6>
                                                <span style={{ color: 'black' }}>{value.admin_note}</span><br />
                                                <span className="order-clock"><Image src="./images/click-order.svg" /> </span> {value.updated_at.slice(0, 19)}
                                              </h6>
                                            </div></div>
                                        </>

                                          :

                                          value.status == 'Label Prepared' ?
                                            <>
                                              <div className="step completed">
                                                <div className="step-icon-wrap">
                                                  <div className="step-icon">
                                                    <Image src="./images/label-prepared.svg" /></div>
                                                </div>

                                                <div className="step-content-wrap">
                                                  <h4 className="step-title">
                                                    Label Prepared
                                                  </h4>
                                                  <h6>
                                                    <span style={{ color: 'black' }}>{value.admin_note}</span><br />
                                                    <span className="order-clock"><Image src="./images/click-order.svg" /> </span>
                                                    {value.updated_at.slice(0, 19)}
                                                  </h6>
                                                </div></div>
                                            </>
                                            :
                                            value.status == 'Local Delivery' ? <>
                                              <div className="step completed">

                                                <div className="step-icon-wrap">
                                                  <div className="step-icon"> <Image src="./images/delivery.svg" /> </div>
                                                </div>

                                                <div className="step-content-wrap">
                                                  <h4 className="step-title">
                                                    Local Delivery
                                                  </h4>
                                                  <h6>
                                                    <span style={{ color: 'black' }}>{value.admin_note}</span><br />
                                                    <span className="order-clock"><Image src="./images/click-order.svg" /> </span> {value.updated_at.slice(0, 19)}
                                                  </h6>
                                                </div></div>
                                            </>

                                              :
                                              value.status == 'Ready To Ship' ? <>
                                                <div className="step process">
                                                  <div className="step-icon-wrap">
                                                    <div className="step-icon" style={{ backgroundColor: '#f5f5f5', border: '1px solid #e1e7ec' }}> <Image src="./images/ship.svg" />  </div>
                                                  </div>


                                                  <div className="step-content-wrap">
                                                    <h4 className="step-title">
                                                      Ready To Ship
                                                    </h4>
                                                    <h6>
                                                      <span style={{ color: 'black' }}>{value.admin_note}</span><br />
                                                      <span className="order-clock"><Image src="./images/click-order.svg" /> </span> {value.updated_at.slice(0, 19)}
                                                    </h6>
                                                  </div>
                                                </div>
                                              </>


                                                :
                                                value.status == 'Shipped' ? <>
                                                  <div className="step process">

                                                    <div className="" style={{ textAlign: 'center' }}>
                                                      <div className="step-icon" style={{ backgroundColor: '#f5f5f5', marginLeft: '8px', border: '1px solid #e1e7ec' }}>
                                                        <Image src="./images/ship.svg" /></div>
                                                    </div>

                                                    <div className="step-content-wrap">
                                                      <h4 className="step-title">
                                                        Shipped
                                                      </h4>
                                                      <h6>
                                                        <span style={{ color: 'black' }}>{value.admin_note}</span><br />
                                                        <span className="order-clock"><Image src="./images/click-order.svg" /> </span> {value.updated_at.slice(0, 19)}
                                                      </h6>
                                                    </div></div>
                                                </>
                                                  :




                                                  null
                                  }

                                  {/* <div className="step-content-wrap">
                                        <h4 className="step-title">
                                          {value.status}
                                        </h4>
                                        <h6> <span className="order-clock"><Image src="./images/click-order.svg" /> </span> {value.updated_at.slice(0, 19)} </h6>
                                      </div> */}
                                </div>
                                {/* </div> */}
                              </div>
                            )
                          })}
                        </>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </Container> */}
    </section>
  </>)
}
export default TrackingOrders;