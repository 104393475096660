import React,{useEffect} from 'react';
import Routes from './Routes'
import './App.css';

const App = () => {

  return (<>
    <Routes />
  </>)
}
export default App;








