/*-------------------------About Us --------------------*/
export const aboutUs =
    [
        {
            goals: ' We believe in accessible and compassionate healthcare for all. Our mission is to empower individuals to take control of their health and well-being through innovative and user-friendly healthcare solutions.'
        },
        {
            description: 'At WeldonRx, we are committed to providing high-quality healthcare services, with a focus on empowering our patients to make informed decisions about their health. We understand that navigating the healthcare system can be a daunting task, and we aim to simplify this process by offering easy-to-use digital platforms and personalized care.'
        },
        {
            name: 'About Us'
        },
        {
            image: './images/about-us.svg'
        },
        {
            description: 'Our Vision: Advancing Healthcare with Innovation and Empathy'
        },
        {
            text: 'We envision a society where health is not a luxury but a basic human right, and where everyone can live their best lives with the support of compassionate and knowledgeable healthcare professionals. Our goal is to revolutionize the healthcare industry by providing innovative solutions and empowering patients to take control of their health and wellbeing.'
        },
        {
            bookingdes: 'Sign up today to experience hassle-free medication management, from online prescription renewals to automatic refills. Join the ranks of our satisfied customers and start enjoying the benefits today!'
        },
    ]



export const medicalServices = [
    {
        image: './images/appintment-icon-1.svg',
        text: 'Hassle-Free Medication Management'
    },
    {
        image: './images/appintment-icon-2.svg',
        text: 'Easy Online Prescription Renewals'
    },
    {
        image: './images/appintment-icon-3.svg',
        text: 'Automatic Medication Refills'
    },
    {
        image: './images/appintment-icon-4.svg',
        text: 'Access to Licensed Pharmacists'
    },
    {
        image: './images/appintment-icon-5.svg',
        text: 'Personalized Medication Reminders'
    },
    {
        image: './images/appintment-icon-6.svg',
        text: 'Free Delivery in British Columbia'
    },
    {
        image: './images/appintment-icon-7.svg',
        text: 'Simplified Medication Packaging'
    },
    {
        image: './images/appintment-icon-8.svg',
        text: 'Affordable Prescription Pricing'
    }
]

