import { useEffect, useState, useRef } from "react";
import { BsPlus } from "react-icons/bs";
import { addAddress, deleteAddress, getAllAddress, myAddressUrl } from "../sharedApi/common";
import { customAxios } from "../sharedApi/customeAxios";
import Card from 'react-bootstrap/Card';
import { usePlacesWidget } from 'react-google-autocomplete';
import { getToken } from "../sharedApi/customeAxios";
import { Modal } from 'bootstrap';
import Spinner from 'react-bootstrap/Spinner';

const MyAddresses = (props) => {

    const [shippingAddress, setShippingAddress] = useState([])
    const [billingAddress, setBillingAddress] = useState([])
    const [loading, setLoading] = useState(false)
    const [addressType, setAddressType] = useState('')
    const [addressTypeColor, setAddressTypeColor] = useState('lightgrey')
    const [address1, setAddress1] = useState('')
    const [address2, setAddress2] = useState('')
    const [adrress1Color, setAddress1Color] = useState('lightgrey')
    const [city, setCity] = useState('')
    const [cityColor, setCityColor] = useState('lightgrey')
    const [zipCode, setZipCode] = useState('')
    const [zipCodeColor, setZipCodeColor] = useState('lightgrey')
    const [province, setProvince] = useState('')
    const [provinceColor, setProvinceColor] = useState('lightgrey')
    const [showOtpModal, setShowOTPModal] = useState(false)
    const [selectId, setSelectId] = useState('')
    const [processing, setProcessing] = useState(false)
    const [AddButton, setAddButton] = useState('ADD')
    const [defaultShippingAddress, setDefaultShippingAddress] = useState([])
    const [defaultbillingAddress, setDefaultBillingAddress] = useState([])
    const [defaultBillingLoading, setdefaultBillingLoading] = useState(false)
    const [defaultAddressLoading, setDefaultAddressLoading] = useState(false)
    const [isEditMode, setIsEditMode] = useState(false)
    const [message, setMessage] = useState('Please wait for a moment..!!')

    const token = getToken();

    const fetchShippingAddress = () => {
        if (token) {
            setTimeout(() => {
                setLoading(true);
                setLoader(true);
                try {
                    customAxios({
                        url: getAllAddress,
                        method: 'GET',
                        params: { type: 'shipping' },
                    }).then((response) => {
                        // console.log(response.data);
                        if (response?.data?.data?.default_shipping_address == null) {
                            setMessage('');
                            setDefaultAddressLoading(false);
                        } else {
                            setDefaultShippingAddress(response.data.data.default_shipping_address);
                            setDefaultAddressLoading(true);
                            // setTimeout(() => {
                            setLoading(false);
                            setLoader(false);
                            // }, 3000);
                        }

                        if (response?.data?.data?.default_billing_address == null) {
                            setMessage('');
                            setdefaultBillingLoading(false);
                        } else {
                            setDefaultBillingAddress(response.data.data.default_billing_address);
                            setdefaultBillingLoading(true);
                            // setTimeout(() => {
                            setLoading(false);
                            setLoader(false);
                            // }, 3000);
                        }

                        if (response?.data?.data?.address.length > 0) {
                            setShippingAddress(response.data.data.address);
                            // setTimeout(() => {
                            setLoading(false);
                            setLoader(false);
                            // }, 3000);
                        }
                    });
                } catch (error) {
                    setMessage('');
                }
            }, 3000);
        }
    };


    const fetchBillingAddress = () => {
        if (token) {
            setTimeout(() => {
                setLoading(true);
                setLoader(true)
                customAxios({
                    url: getAllAddress,
                    method: 'GET',
                    params: { type: 'billing' },
                }).then((response) => {
                    if (response?.data?.data.address.length > 0) {
                        setBillingAddress(response.data.data.address);
                        setTimeout(() => {
                            setLoading(false);
                            setLoader(false)
                        }, 3000)
                    }
                })
            }, 3000);
        }
    };

    useEffect(() => {
        fetchShippingAddress()
        fetchBillingAddress();
    }, [])

    const handleClick = (value) => {
        setManageShippBillId('')
        setAddButton('Update')
        setIsEditMode(false);
        setAddressTypeColor('lightgrey')
        setAddress1Color('lightgrey')
        setCityColor('lightgrey')
        setZipCodeColor('lightgrey')
        setProvinceColor('lightgrey')
        setSelectId(value.id)
        setShowOTPModal(true)
        setAddressType(value.type)
        setAddress1(value.address_1)
        setAddress2(value.address_2)
        setCity(value.city)
        setZipCode(value.postcode)
        setProvince(value.state)
    }

    const [manageShippBillId, setManageShippBillId] = useState([])

    const handleShippingId = () => {
        setAddButton('Update')
        setIsEditMode(true);
        setSelectId('')
        // setSelectId(defaultShippingAddress.id)
        setManageShippBillId(defaultShippingAddress.id)
        setAddressType(defaultShippingAddress.type)
        setAddress1(defaultShippingAddress.address_1)
        setAddress2(defaultShippingAddress.address_2)
        setCity(defaultShippingAddress.city)
        setZipCode(defaultShippingAddress.postcode)
        setProvince(defaultShippingAddress.state)
        // console.log(addressType)
    }

    const handleBillingId = () => {
        setSelectId('')
        setAddButton('Update')
        setIsEditMode(true);
        // setSelectId(defaultbillingAddress.id)
        setManageShippBillId(defaultbillingAddress.id)
        setAddressType(defaultbillingAddress.type)
        setAddress1(defaultbillingAddress.address_1)
        setAddress2(defaultbillingAddress.address_2)
        setCity(defaultbillingAddress.city)
        setZipCode(defaultbillingAddress.postcode)
        setProvince(defaultbillingAddress.state)
    }


    const updateAddress = () => {
        setProcessing(true)
        setLoader(true)
        customAxios({
            method: 'POST',
            url: `${myAddressUrl}/${selectId ? selectId : manageShippBillId}`,
            data: {
                address_1: address1, address_2: address2, city: city, postcode: zipCode, state: province, country: "Canada",
                type: addressType, same_shipping: 'false', is_default: manageShippBillId ? '1' : '0'
            }
        },)
            .then((response) => {
                // console.log(response)
                if (response) {
                    setProcessing(false)
                    setShowOTPModal(false)
                    setManageShippBillId('')
                }
            })
    };

    const submitAddAddressData = () => {
        setProcessing(true)
        setLoader(true)
        customAxios({
            method: 'POST',
            url: `${addAddress}`,
            data: {
                address_1: address1, address_2: address2, city: city, postcode: zipCode, state: province, country: "Canada",
                type: addressType, same_shipping: 'false',
            }
        },)
            .then((response) => {
                setProcessing(false)
                // console.log(response.data.success)
                if (response) {
                    setProcessing(false)
                    setShowOTPModal(false)
                }
            })
    }

    const submitData = () => {
        if (!addressType) {
            setAddressTypeColor('#FF4200')
        } else if (!address1) {
            setAddress1Color('#FF4200')
        } else if (!city) {
            setCityColor('#FF4200')
        } else if (!zipCode) {
            setZipCodeColor('#FF4200')
        } else if (!province) {
            setProvinceColor('#FF4200')
        } else if (selectId || manageShippBillId) {
            updateAddress();
            fetchShippingAddress()
            fetchBillingAddress();
            handleClicked('')
        } else {
            submitAddAddressData();
            fetchShippingAddress()
            fetchBillingAddress();
            handleClicked('')
        }
    }

    const openModal = () => {
        setAddButton('Add')
        setIsEditMode(false)
        setAddress1('')
        setAddress2('')
        setAddressType('')
        setCity('')
        setProvince('')
        setZipCode('')
        setSelectId('')
        setManageShippBillId('')
        setAddressTypeColor('lightgrey')
        setAddress1Color('lightgrey')
        setCityColor('lightgrey')
        setZipCodeColor('lightgrey')
        setProvinceColor('lightgrey')
        setShowOTPModal(true)
    }

    const onPlaceSelected111 = (place) => {
        if (place && place.place_id) {
            const request = {
                placeId: place.place_id,
                fields: ['address_components', 'geometry', 'formatted_address', 'name']
            };
            const service = new window.google.maps.places.PlacesService(document.createElement('div'));
            service.getDetails(request, (placeResult, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    const addressComponents = placeResult.address_components;
                    let houseNumber, streetName, city, state, province, country, postcode

                    for (let i = 0; i < addressComponents.length; i++) {
                        const component = addressComponents[i];
                        if (component.types.includes('street_number')) {
                            houseNumber = component.long_name;
                            setAddress1(houseNumber)
                        } else if (component.types.includes('locality')) {
                            city = component.long_name;
                            setCity(city)
                        } else if (component.types.includes('administrative_area_level_1')) {
                            state = component.long_name;
                            setProvince(state)
                        } else if (component.types.includes('administrative_area_level_2')) {
                            province = component.long_name;
                        } else if (component.types.includes('country')) {
                            country = component.long_name;
                        } else if (component.types.includes('postal_code')) {
                            postcode = component.long_name;
                            setZipCode(postcode)
                        } else if (component.types.includes('route')) {
                            streetName = component.long_name;
                        }
                    }
                    setAddress2('')
                    if (houseNumber) {
                        setAddress1(`${houseNumber} ${streetName}`);
                    } else {
                        setAddress1(`${streetName}`);
                    }
                }
            });
        }
    };


    const { ref } = usePlacesWidget({
        apiKey: "https://maps.googleapis.com/maps/api/place/autocomplete/json?input=<input>&key=AIzaSyBzuzGR9_XoLdb7nx-L9UdPPmIwZyiSOdM&libraries=places",
        onPlaceSelected: onPlaceSelected111,
        options: {
            types: ["address"],
            componentRestrictions: { country: "ca" },
            location: { lat: 49.0869607, lng: -122.4077515 },
            limit: 7,
            googleLogo: false,
        }
    });

    const modalRef = useRef(null);

    const handleClicked = () => {
        const modalInstance = Modal.getInstance(modalRef.current);
        modalInstance.hide();
    }

    const [loader, setLoader] = useState(false)

    const fectchDeleteAddress = (id,) => {
        if (token) {
            customAxios({
                url: `${deleteAddress}/${id}`,
                method: 'DELETE',
            }).then((response) => {
                if (response) {
                    // console.log(response)
                }
            })
        }
    };

    const deleteShippingAddress = (i) => {
        const del = shippingAddress.filter((elem, id) => {
            return i != id;
        })
        setTimeout(() => {
            setShippingAddress(del)
        }, 1000)
    }

    const deleteBillingAddress = (i) => {
        const del = billingAddress.filter((elem, id) => {
            return i != id;
        })
        setTimeout(() => {
            setBillingAddress(del)
        }, 1000)
    }

    return (<>
        <h2> My Addresses </h2>
        <div className="uplode-pic-row wx_MyAddressBg mb-4">
            <div className="uplode-pic-left">
                <p className="mb-0"> Manage Your Shipping Addresses  </p>
            </div>
            <div className="uplode-pic-right">
                <div className="remider-col">
                    <div className="reminder-left">
                        <h5 className="mb-0"> Add Address <span className="reminder-arows"> <img src="./images/reminder-arows.svg" /> </span> </h5>
                    </div>
                    <div className="reminder-right">
                        {/* <a className="btn d-flex align-items-center justify-content-center" data-bs-toggle="modal" data-bs-target="#AddAddress"> <i className="fa-light fa-plus"><BsPlus size={30} /></i> </a> */}
                        <a className="btn d-flex align-items-center justify-content-center" data-bs-toggle="modal" data-bs-target="#AddAddress" onClick={openModal} style={{ outline: 'none', border: 'none' }}>
                            <i className="fa-light fa-plus"><BsPlus size={30} /></i> </a>
                    </div>
                </div>
            </div>
        </div>

        <div className="pharmacy-search">
            <div className="row">
                <div className="col-md-12">
                    <div className="search-header">
                        {/* <h5 className="mb-0"> Addresses </h5> */}
                    </div>
                </div>
            </div>

            <div className="card-body wx_MyAddressRightList">

                {loader ?
                    <div className="text-center mt-3">
                        <Spinner animation="border" role="status" >
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                    :
                    <>
                        {defaultAddressLoading ? <>
                            {/* <p>Default Address</p>   */}
                            <div className="pill-schedule-row" >
                                <div className="pill-schedule-left">
                                    <div className="pill-schedule-icon">
                                        <img src="./images/myprofile/location.svg" />
                                    </div>
                                    <div className="pill-schedule-content">
                                        <h4> {defaultShippingAddress.address_1},{defaultShippingAddress.postcode} </h4>

                                        <span className="wx_TitleBox1">Default Address</span> <span className="wx_TitleBox2">{defaultShippingAddress.type} </span>
                                    </div>
                                </div>
                                <div className="pill-schedule-right">
                                    <ul>
                                        <li> <a className="btn" onClick={() => handleShippingId(defaultShippingAddress.id)} data-bs-toggle="modal" data-bs-target="#AddAddress"><img src="./images/edit.svg" /> </a> </li>
                                    </ul>
                                </div>
                            </div>

                        </>
                            : <p>{message}</p>}

                        {defaultBillingLoading ?
                            <div className="pill-schedule-row" >
                                <div className="pill-schedule-left">
                                    <div className="pill-schedule-icon">
                                        <img src="./images/myprofile/location.svg" />
                                    </div>
                                    <div className="pill-schedule-content">
                                        <h4> {defaultbillingAddress.address_1},{defaultbillingAddress.postcode} </h4>

                                        <span className="wx_TitleBox1">Default Address</span> <span className="wx_TitleBox2">{defaultbillingAddress.type} </span>
                                    </div>
                                </div>
                                <div className="pill-schedule-right">
                                    <ul>
                                        <li> <a className="btn" onClick={() => handleBillingId(defaultbillingAddress.id)} data-bs-toggle="modal" data-bs-target="#AddAddress"><img src="./images/edit.svg" /> </a> </li>
                                    </ul>
                                </div>
                            </div> : null}


                        {shippingAddress?.map((value, i) => {
                            return (<div key={i}>
                                {loading ?
                                    <Card className="pill-schedule-row" style={{ height: '68px', backgroundColor: "#ecebeb", borderColor: 'white', marginBottom: '20px' }}></Card>
                                    :

                                    <div className="pill-schedule-row" key={i}>
                                        <div className="pill-schedule-left">
                                            <div className="pill-schedule-icon">
                                                <img src="./images/myprofile/location.svg" />
                                            </div>
                                            <div className="pill-schedule-content">
                                                <h4> {value.address_1},{value.postcode} </h4>
                                                <span className="wx_TitleBox2">{value.type} </span>
                                            </div>
                                        </div>
                                        <div className="pill-schedule-right">
                                            <ul>
                                                <li onClick={() => deleteShippingAddress(i)}> <a className="btn" onClick={() => fectchDeleteAddress(value.id)}> <img src="./images/delete-icon.svg" /> </a> </li>
                                                <li> <a className="btn" onClick={() => handleClick(value)} data-bs-toggle="modal" data-bs-target="#AddAddress"> <img src="./images/edit.svg" /> </a> </li>
                                            </ul>
                                        </div>
                                    </div>
                                }</div>
                            )
                        })}

                        {billingAddress?.map((value, i) => {
                            return (<div key={i}>
                                {loading ?
                                    <Card className="pill-schedule-row" style={{ height: '68px', backgroundColor: "#ecebeb", borderColor: 'white', marginBottom: '20px' }}></Card> :
                                    <div className="pill-schedule-row" key={i}>
                                        <div className="pill-schedule-left">
                                            <div className="pill-schedule-icon">
                                                <img src="./images/myprofile/location.svg" />
                                            </div>
                                            <div className="pill-schedule-content">
                                                <h4> {value.address_1},{value.postcode} </h4>
                                                <span className="wx_TitleBox2">{value.type} </span>
                                            </div>
                                        </div>
                                        <div className="pill-schedule-right">
                                            <ul>
                                                <li onClick={() => deleteBillingAddress(i)}> <a className="btn" onClick={() => fectchDeleteAddress(value.id)}>  <img src="./images/delete-icon.svg" /> </a> </li>
                                                <li> <a className="btn" data-bs-toggle="modal" data-bs-target="#AddAddress" onClick={() => handleClick(value)}> <img src="./images/edit.svg" /> </a> </li>
                                            </ul>
                                        </div>
                                    </div>}</div>
                            )
                        })} </>}
            </div>
        </div>
        {/* <Modal show={showOtpModal} onHide={() => setShowOTPModal(false)} className="modal fade wx_AddAddressModal" centered>  */}

        <div className="modal fade wx_AddAddressModal" id="AddAddress" ref={modalRef} tabIndex="-1" aria-labelledby="AddAddressLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header justify-content-center border-0">
                        <h1 className="modal-title fs-5" id="AddAddressLabel">Add New Address</h1>
                    </div>
                    <div className="modal-body">
                        <form>
                            <div className="row">
                                <div className="col-md-12">
                                    {/* <label htmlFor="formGroupExampleInput" className="form-label">Address Type</label> */}

                                    <div className="form-floating mb-3">
                                        <select className="form-select" disabled={isEditMode} style={{ borderColor: addressTypeColor }} value={addressType} onChange={(e) => { return setAddressType(e.target.value), setAddressTypeColor('lightgrey') }}>
                                            <option> Address Type </option>
                                            <option value='shipping'> Shipping </option>
                                            <option value='billing'>  Billing </option>
                                        </select>
                                        <span className="form-icon"> <img src="./images/myprofile/locationic.svg" alt="" /> </span>
                                    </div>
                                </div>


                                <div className="col-md-12">
                                    <div className="form-floating mb-3">
                                        <input
                                            ref={ref}
                                            value={address1}
                                            onChange={(e) => { return setAddress1(e.target.value), setAddress1Color('lightgrey') }}
                                            className="form-control"
                                            placeholder="House number and street name"
                                            style={{ borderColor: adrress1Color }}
                                        />
                                        <span className="form-icon"> <img src="./images/myprofile//homelocationic.svg" alt="" /> </span>
                                        <label htmlFor="emailAddress" style={{ color: '#838383' }}> House number and street name </label>

                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="form-floating mb-3">
                                        <input type="text" onChange={(e) => setAddress2(e.target.value)} className="form-control" id="formGroupExampleInput" placeholder="Sycamore Street" />
                                        <span className="form-icon"> <img src="./images/myprofile/locationic.svg" alt="" /> </span>
                                        <label htmlFor="emailAddress" style={{ color: '#838383' }}> Apartment </label>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-floating mb-3">
                                        <input type="text" style={{ borderColor: cityColor }} value={city} onChange={(e) => { return setCity(e.target.value), setCityColor('lightgrey') }} className="form-control" id="formGroupExampleInput" placeholder="94103" />
                                        <span className="form-icon"><img src="./images/myprofile/city.svg" alt="" /></span>
                                        <label htmlFor="emailAddress" style={{ color: '#838383' }}> City </label>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-floating mb-3">
                                        <input type="text" style={{ borderColor: zipCodeColor }} value={zipCode} 
                                        onChange={(e) => { return setZipCode(e.target.value.slice(0, 6)), setZipCodeColor('lightgrey') }} className="form-control" id="formGroupExampleInput" placeholder="94103" />
                                        <span className="form-icon"><img src="./images/myprofile/zipcodeic.svg" alt="" /></span>
                                        <label htmlFor="emailAddress" style={{ color: '#838383' }}> Zip code </label>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-floating mb-3">
                                        <input type="text" style={{ borderColor: provinceColor }} value={province} onChange={(e) => { return setProvince(e.target.value), setProvinceColor('lightgrey') }} className="form-control" id="formGroupExampleInput" placeholder="San Francisco" />
                                        <span className="form-icon"> <img src="./images/myprofile/provinceic.svg" alt="" /> </span>
                                        <label htmlFor="emailAddress" style={{ color: '#838383' }}> Province </label>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer popup-fermacy-footer">
                        <button type="button" className="btn" data-bs-dismiss="modal" onClick={() => setShowOTPModal(false)}>Cancel</button>

                        <button type="button" className="btn btn-primary" onClick={submitData}>
                            {processing ? 'Processing...' : <>{AddButton}</>}
                        </button>
                    </div>
                </div></div></div>
        {/* </Modal> */}
    </>)
}
export default MyAddresses