import { medicalServices } from "../constants/aboutUs";
import { aboutUs } from "../constants/aboutUs";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { AboutUsDescription,AboutUsTitle,AboutUsUrl } from "../metaTags/meta";
import LottieAnimation from "../lottiee/lottiee";
import { Link } from "react-router-dom";
const About = (props) => {
  return (<>

<HelmetProvider>
    <Helmet>
      <meta name="robots" content="index,follow" />
<link rel="alternate" href={ AboutUsUrl } hreflang="en" />
<title>{AboutUsTitle}</title>
<meta charset="utf-8" />
<meta name="author" content="WeldonRx"/>
<meta name="title" content={AboutUsTitle}/>
<meta name="description" content= { AboutUsDescription } />
<meta property="og:type" content="website" />
<meta property="og:url" content={ AboutUsUrl } />
<meta property="og:title" content={AboutUsTitle} />
<meta property="og:description" content= { AboutUsDescription }/>
<meta property="og:image" content="https://weldonrx.com/weldon-social-image.png" />
<meta property="twitter:card" content="summary_large_image" />
<meta property="twitter:url" content={ AboutUsUrl } />
<meta property="twitter:title" content={AboutUsTitle}/>
<meta property="twitter:description" content= { AboutUsDescription } />
<meta property="twitter:image" content="https://weldonrx.com/weldon-social-image.png" />
<link rel="canonical" href={ AboutUsUrl } />
  </Helmet>
</HelmetProvider>

    <div className="container spaceContainer pt-5 mt-3 mt-md-3 ">
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <div className="page-title-breadcrumb">
            <h2> {aboutUs[2].name} </h2>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a > Home </a></li>
                <li> <img src="./images/bradcumb-arows.svg" alt="..." /> </li>
                <li className="breadcrumb-item active" aria-current="page">{aboutUs[2].name} </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="section-title section-title-block">
            <h5 className="text-orange">Our Story</h5>
            <h2> Empowering Healthcare for All </h2>
          </div>
          <div className="row justify-content-center align-items-center mt-5">
            <div className="col-lg-5 col-md-5 col-sm-12 col-12">
              <div className="about-content">
                <h5> {aboutUs[0].goals}</h5>
                <p>{aboutUs[1].description} </p>
                <Link to='/login' className="about-btn btn"> Get Started <img className="img-fluid" src="./images/right-big-arows.svg" alt="..." />  </Link>
              </div>
            </div>
            <div className="col-lg-7 col-md-7 col-sm-12 col-12">
              <div className="about-content">
                {/* <img className="img-fluid" src={aboutUs[3].image} alt="..." /> */}
                <LottieAnimation  />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section className="appintment-row">
      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="section-title section-title-block">
              <h2> {aboutUs[4].description} </h2>
            </div>
          </div>

          <div className="col-lg-5 col-md-5 col-sm-12 col-12">
            <div className="about-content">
              <p> {aboutUs[5].text} </p>
            </div>
          </div>
        </div>

        <div className="row mt-5 pt-4">
          {medicalServices.map((value, index) => {
            return (
              <div className="col-lg-3 col-md-3 col-sm-6 col-12" key={index}>
                <div className="appinint-box">
                  <img src={value.image} className="img-fluid" />
                  <h5>{value.text}</h5>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>

    <section className="healthcare-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <div className="healthcare-left">
              <h4>Join us today and experience the benefits of our innovative and user-friendly platform. </h4>
              <p>{aboutUs[6].bookingdes}</p>
              <Link to='/login' className="about-btn btn"> Get Started <img className="img-fluid" src="./images/right-big-arows.svg" alt="..." />  </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>)
}
export default About;