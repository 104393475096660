import MedicineScheudle from "../medicineScheudle/medicineScheudle";
import LogOut from "../logOut/logOut";
import List from "../medicineScheudle/list";
import { useEffect, useState } from "react";
import PillList from "./PillList";
import SetProfile from "../profile/setProfile";
import Spinner from 'react-bootstrap/Spinner';
import { Link } from "react-router-dom";
import LogoutTimer from "../logOut/logOutTimer";

const PillRemainder = (props) => {

    const [isLoading, setIsLoading] = useState(true)
    const [loader, setLoader] = useState(false)


    useEffect(() => {
        setLoader(true)
        setTimeout(() => {
            setLoader(false)
        }, 2000)
    }, [])

    const handleClick = () => {
        localStorage.removeItem('pillsdata')
    }

  const activeLink = 2;

    return (<>
        <section className="dark-back">
            <div className="profile-2-col">
                <div className="container justify-content-center align-items-center">
                    <div className="row">
                        <div className="col-md-4">
                            <SetProfile day={props.day} />
                            <MedicineScheudle />
                            <List activeLink={activeLink}/>
                            <LogOut logOut={props.logOut} />
                            <LogoutTimer onLogout={props.logOut} />        
                        </div>

                        <div className="col-md-8">
                            <div className="profile-right-col">
                                <div className="uplode-pic-row">
                                    <div className="uplode-pic-left">
                                        <h3> Pill Reminders </h3>
                                        <p>Set them up. </p>
                                    </div>

                                    <div className="uplode-pic-right">
                                        <div className="remider-col">
                                            <div className="reminder-left">
                                                <h5> Set Reminder <span className="reminder-arows"> <span ><img src="./images/reminder-arows.svg" /></span> </span> </h5>
                                            </div>

                                            <div className="reminder-right">
                                                <Link to='/SetReminder' className="btn" onClick={handleClick}>
                                                    <img src="./images/bal-icon.svg" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {loader ?
                                    <div className="text-center mt-3">
                                        <Spinner animation="border" role="status" style={{ marginTop: '175px' }}>
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    </div>
                                    : <>
                                        {isLoading ?
                                            <PillList accesstoken={props.accesstoken} />
                                            : <Spinner animation="border" role="status" style={{ marginLeft: '350px', marginTop: '100px' }}>
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>)
}
export default PillRemainder;