/*-------------------------------------------------------Carrers Tags Description start------------------------------------------------------ */

export const CarrerTitle = 'Join the WeldonRx Team: Exciting Careers Options'

export const CarrerDescription = "Explore rewarding career opportunities at WeldonRx. Be part of our passionate team to shape the future of healthcare services and make a difference in people's lives." 

export const CareerUrl = "https://weldonrx.com/Careers"

/*-------------------------------------------------------Carrers Tags Description End------------------------------------------------------ */

/*-------------------------------------------------------Reviews Tags Description start------------------------------------------------------ */

export const ReviewsTitle = "WeldonRx Customer Reviews: Hear Our Clients' Success Stories"

export const ReviewsDescription = "Browse genuine WeldonRx customer reviews and learn how our simplified prescription services, quality medications, and exceptional support have made a positive impact on people's lives. " 

export const ReviewsUrl = "https://weldonrx.com/Reviews"

/*-------------------------------------------------------Reviews Tags Description End------------------------------------------------------ */

/*-------------------------------------------------------AboutUs Tags Description start------------------------------------------------------ */

export const AboutUsTitle = "About WeldonRx: Our Story"

export const AboutUsDescription = "Learn about our mission, vision, and commitment to providing simplified prescription management and quality medications at competitive prices." 

export const AboutUsUrl = "https://weldonrx.com/AboutUs"

/*-------------------------------------------------------AboutUs Tags Description End------------------------------------------------------ */

/*-------------------------------------------------------PrivacyPolicy Tags Description start------------------------------------------------------ */

export const PrivacyPolicyTitle = "WeldonRx Privacy Policy"

export const PrivacyPolicyDescription = "Our comprehensive Privacy Policy to understand how we safeguard your personal information, maintain data security, and respect your privacy while delivering quality medications and simplified prescription services through our online pharmacy platform." 

export const PrivacyPolicyUrl = "https://weldonrx.com/PrivacyPolicy"

/*-------------------------------------------------------PrivacyPolicy Tags Description End------------------------------------------------------ */

/*-------------------------------------------------------TermsCondtions Tags Description start------------------------------------------------------ */

export const TermsCondtionsTitle = " WeldonRx Terms and Conditions: Your Guide to Safe & Secure Pharmacy Services"

export const TermsCondtionsDescription = "Familiarize yourself with WeldonRx's Terms and Conditions to ensure a secure and positive experience when using our online pharmacy platform for simplified prescription management, quality medications, and outstanding customer support."

export const TermsCondtionsUrl = "https://weldonrx.com/toc"

/*-------------------------------------------------------PrivacyPolicy Tags Description End------------------------------------------------------ */

/*-------------------------------------------------------ContactUs Tags Description start------------------------------------------------------ */

export const ContactUsTitle = " Contact WeldonRx: Get In Touch with Our Support Team"

export const ContactUsDescription = " Have questions or need assistance? Reach out to WeldonRx's dedicated customer support team for help with prescription management, medication inquiries, or any other concerns related to our online pharmacy services. Your satisfaction is our priority."

export const ContactUsUrl = "https://weldonrx.com/ContactUs"

/*-------------------------------------------------------PrivacyPolicy Tags Description End------------------------------------------------------ */

/*-------------------------------------------------------Login Tags Description start------------------------------------------------------ */

export const LoginTitle = "WeldonRx Login: Access Your Account"

export const LoginDescription = " Sign in to your WeldonRx account to manage prescriptions, track orders, and enjoy personalized healthcare solutions."

export const LoginUrl = "https://weldonrx.com/login"

/*-------------------------------------------------------PrivacyPolicy Tags Description End------------------------------------------------------ */