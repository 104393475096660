import React from 'react';
import { GoogleLogin } from 'react-google-login';
import Container from 'react-bootstrap/esm/Container';
import { FcGoogle } from 'react-icons/fc';
import Image from 'react-bootstrap/Image'
import { clientId } from '../sharedApi/common';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { decrypteData } from '../sharedApi/customeAxios';

function Login(props) {

    const [homepage, setHomePage] = useState([])

    const onFailure = (res) => {
        console.log('Login Failed:', res);
    }

    function handleKeyPress(event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            props.submit()
        }
    }

    useEffect(() => {
        let profile = decrypteData();
        if (profile) {
            setHomePage(profile?.user_default_shipping_address === null)
        }
    }, [])

    return (<>
        <Container className='herosection'>
            <Image src="./images/medicHero.png" style={{ position: 'absolute', marginTop: '0px', marginLeft: '38px' }} />
            <div className="row p-90">
                
                {homepage ?
                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 text-white">
                        <div className='left-banner-content'>
                            <h2 className="text-white">Take Control of Your Health with Personalized Healthcare Solutions</h2>
                            <p>Join Our Community of Patients Who Have Found Optimal Wellness with Our Customized Support and Treatment</p>
                            <p style={{fontWeight:'400',fontSize:'20px'}}>Start Your Journey Today!</p>
                            <div>
                                <GoogleLogin clientId={clientId}
                                    buttonText="Sign In"
                                    onSuccess={props.onSuccess}
                                    onFailure={onFailure}
                                    cookiePolicy={'single_host_origin','none'}
                                    isSignedIn={false}
                                    render={(renderProps) => (
                                    
                                    <div className="btn btn-light gmail-btn " onClick={renderProps.onClick}>
                                            {props.googleLoginLoading ?  
                                                        <Spinner  className="text-center"  animation="border" role="status"  variant="dark"  style={{ marginLeft: '80px',marginRight:'80px', fontSize: '16px' }}>
                                                        <span className="visually-hidden">Loading...</span>
                                                      </Spinner>
                                                      : 
                                                <>
                                        <FcGoogle size={30} className='float-left' />
                                        <span>Login With Google</span> </>}
                                     </div>)}>
                                </GoogleLogin>


                            </div>

                            <div className="title"><span>Or</span></div>
                            <div className="input-group mb-0 mt-3 bg-transparent mw-360px phone-filed-transparent">
                                <span className="bg-transparent input-group-text p-2" id="basic-addon1">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.2188 17.25C14.3549 17.25 12.5024 16.7917 10.6615 15.875C8.82049 14.9583 7.16667 13.7667 5.7 12.3C4.23333 10.8333 3.04167 9.17951 2.125 7.33854C1.20833 5.49757 0.75 3.64514 0.75 1.78125C0.75 1.49097 0.849306 1.24653 1.04792 1.04792C1.24653 0.849306 1.49097 0.75 1.78125 0.75H4.98958C5.20347 0.75 5.39063 0.822569 5.55104 0.967708C5.71146 1.11285 5.81458 1.30764 5.86042 1.55208L6.47917 4.43958C6.50972 4.65347 6.5059 4.84826 6.46771 5.02396C6.42951 5.19965 6.34931 5.34861 6.22708 5.47083L3.93542 7.78542C4.79097 9.20625 5.74965 10.4438 6.81146 11.4979C7.87326 12.5521 9.07639 13.4458 10.4208 14.1792L12.5979 11.9333C12.7507 11.7653 12.9264 11.6469 13.125 11.5781C13.3236 11.5094 13.5222 11.4979 13.7208 11.5437L16.4479 12.1396C16.6771 12.1854 16.8681 12.3 17.0208 12.4833C17.1736 12.6667 17.25 12.8806 17.25 13.125V16.2188C17.25 16.509 17.1507 16.7535 16.9521 16.9521C16.7535 17.1507 16.509 17.25 16.2188 17.25ZM3.24792 6.525L5.10417 4.64583L4.57708 2.125H2.125C2.125 2.72083 2.21667 3.37396 2.4 4.08437C2.58333 4.79479 2.86597 5.60833 3.24792 6.525ZM11.7042 14.8438C12.3306 15.134 13.0104 15.3708 13.7437 15.5542C14.4771 15.7375 15.1875 15.8444 15.875 15.875V13.4229L13.5146 12.9417L11.7042 14.8438Z" fill="white" />
                                    </svg>
                                </span>

                                <input type="text" className="form-control bg-transparent text-white p-2 inputhero" style={{ fontSize: '17px' }} placeholder="10 digit phone number" aria-label="Username" aria-describedby="basic-addon1" name='phonenumber' value={props.userNumDetail} onKeyPress={handleKeyPress}
                                    onChange={props.handleNumber} onKeyDown={(e) => { if (!/^\d+$/.test(e.key) && (e.key !== "Backspace") && (e.key !== "Enter")) { e.preventDefault(); } }} />

                            </div>

                            <span className="requiredPhone" style={{color:'#FF4200',fontWeight:'400'}}>{props.errMsg}</span>

                            <button type="button" className="btn btn-primary btn-hero pt-1 pb-1 pl-4 pr-4" onClick={props.submit}>
                                {props.loadingPhoneNumber ? 'Processing Please wait' : 'Get Started'}
                            </button>

                            <span className="termsagree">By proceeding, you agree to our <Link to='/toc' style={{color:'white'}}>Terms of Use</Link> & <Link to='/PrivacyPolicy'  style={{color:'white'}}> Privacy Policy</Link>. Message and data rates may apply</span>

                        </div>
                    </div>

                    :

                    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 text-white">
                        <div className='left-banner-content'>
                            <h2 className="text-white">Take Control of Your Health with Personalized Healthcare Solutions</h2>
                            <p>Join Our Community of Patients Who Have Found Optimal Wellness with Our Customized Support and Treatment</p>
                            <p>Start Your Journey Today!</p>
                            <Link to='/dashboard'>
                                <button type="button" className="btn btn-primary btn-hero pt-1 pb-1 pl-4 pr-4 dashboardbtn" >

                                    Dashboard
                                </button>
                            </Link>
                        </div>
                    </div>
                }
                
                <div className="col-xl-6 col-lg-12 col-md-6 col-sm-12 col-12 mt-auto" >
                    <Image src="./images/MedicHeroRIght.svg" className="heroimg" />
                </div>
            </div>
        </Container>
    </>
    );
}
export default Login


