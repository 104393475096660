const LogOut = (props) => {
    return (<> 
    <div  className="left-login-bottom" onClick={props.logOut}>
        <div className='btn' >
            <span className="left-login-col"> <img src="./images/login-icon.svg" /> </span>
            <h5>Log Out </h5>
        </div>
    </div>
    </>)
}
export default LogOut;