import { Helmet, HelmetProvider } from 'react-helmet-async';
import { CarrerTitle } from '../metaTags/meta';
import { CarrerDescription } from '../metaTags/meta';
import { CareerUrl } from '../metaTags/meta';

const Careers = (props) => {
  return (<>

<HelmetProvider>
    <Helmet>
      <meta name="robots" content="index,follow" />
<link rel="alternate" href={ CareerUrl } hreflang="en" />
<title>{CarrerTitle}</title>
<meta charset="utf-8" />
<meta name="author" content="WeldonRx"/>
<meta name="title" content={CarrerTitle}/>
<meta name="description" content= { CarrerDescription } />
<meta property="og:type" content="website" />
<meta property="og:url" content={ CareerUrl } />
<meta property="og:title" content={CarrerTitle} />
<meta property="og:description" content= { CarrerDescription }/>
<meta property="og:image" content="https://weldonrx.com/weldon-social-image.png" />
<meta property="twitter:card" content="summary_large_image" />
<meta property="twitter:url" content={ CareerUrl } />
<meta property="twitter:title" content={CarrerTitle}/>
<meta property="twitter:description" content= { CarrerDescription } />
<meta property="twitter:image" content="https://weldonrx.com/weldon-social-image.png" />
<link rel="canonical" href={ CareerUrl } />
  </Helmet>
</HelmetProvider>

    <div className="container spaceContainer pt-5 mt-3 mt-md-3 ">
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <div className="page-title-breadcrumb">
            <h2> Careers</h2>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a > Home </a></li>
                <li> <img src="./images/bradcumb-arows.svg" alt="..." /> </li>
                <li className="breadcrumb-item active" aria-current="page">Careers </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <h5>We are glad to see that you are interested in becoming a part of our team.</h5>
          <p>At WeldonRx, we are dedicated to providing top-notch healthcare services to our patients, and we are always looking for talented and passionate individuals who share our vision. </p>
          <p>Our team is made up of dynamic individuals who are driven by a common goal: to make healthcare accessible and affordable for everyone</p>
          <p>We offer a diverse and inclusive work environment that encourages innovation, creativity, and collaboration. </p>
          <p>We believe that our people are our greatest asset, and we are committed to fostering a culture of learning and growth that empowers our team members to achieve their full potential.</p>
          <p>If you are a self-starter who thrives on challenges and is committed to excellence, we would love to hear from you.</p>
          <p>We offer competitive salaries, comprehensive benefits, and opportunities for professional development and advancement. </p>
          <h6>If you are interested in joining our team, please send your resume and a cover letter to careers@weldonrx.com. </h6>
          <p>We look forward to hearing from you!</p>
        </div>
      </div>
    </div>
    
  </>)
}
export default Careers;

