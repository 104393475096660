import { myProfileUrl } from "../sharedApi/common"
import { useState, useEffect,useMemo } from "react"
import { Link } from "react-router-dom"
import { Card } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import CryptoJS from 'crypto-js';
import { useDispatch,useSelector } from "react-redux"
import { fetchDataRequest,fetchDataSuccess,fetchDataFailure } from "../services/actions/actions";
import { FcExpired } from 'react-icons/fc';
import { useLocation } from 'react-router-dom';
import { decrypteData } from "../sharedApi/customeAxios";
import { useCallback } from "react";

const MyProfile = (props) => {

  // const [isloading, setIsLoading] = useState(false)
  // const [data, setData] = useState([]);
  // const [show, setShow] = useState(false);

  // // Cookies.set('token', 'your_token_value');

  // // const token = Cookies.get('token');

  const [data, setData] = useState(null);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => {
      setShow(false)
    }

    const navigate = useNavigate();

    const handleClick = () => {
      navigate('/Settings')
    }


  useEffect(() => {
    async function getData() {
      let token = JSON.parse(localStorage.getItem('Token_access'))
      if (token) {
        // setIsLoading(true)
        const request = fetch(myProfileUrl,
          {
            method: 'GET',
            headers: {
              'Authorization': 'Bearer ' + token,
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            }
          }
        );
        const response = await request;
        const parsed = await response.json();
        // console.log(parsed.message) 
        if(parsed?.data){
          setIsLoading(false)
          setData(parsed.data);
          // localStorage.setItem('myprofile', JSON.stringify(parsed.data))
          
          const encryptedUserData = CryptoJS.AES.encrypt(
            JSON.stringify(parsed.data),
            'secret key'
          ).toString();
          
          localStorage.setItem('u/\s/p/r\e', encryptedUserData);
        }
        if(parsed.message === 'Unauthenticated.'){
          setShow(true)
          setTimeout(()=>{
          localStorage.removeItem('Token_access')
          localStorage.removeItem('u/\s/p/r\e')
          localStorage.removeItem('My Number')
          localStorage.removeItem('O/[]r/d/I/d')
          localStorage.removeItem('activeTab')
          localStorage.removeItem('n/x\t/r/m/n/d/r')
          navigate('/')  
          },2000)
        }
      }
    }
    getData();
  }, []);


  // const dispatch = useDispatch();

  // const fetchData = async () => {
  //   try {
  //     let token = JSON.parse(localStorage.getItem('Token_access'))
  //     dispatch(fetchDataRequest());
  //     const response = await fetch(myProfileUrl,  {
  //       method: 'GET',
  //       headers: {
  //         'Authorization': 'Bearer ' + token,
  //         'Accept': 'application/json',
  //         'Content-Type': 'application/json',
  //       }
  //     });
  //     const data = await response.json();
  //     dispatch(fetchDataSuccess(data));
  //   } catch (error) {
  //     dispatch(fetchDataFailure(error.message));
  //   }
  // };

  //   useEffect(()=>{
  //       fetchData();
  //   },[])
    
  useEffect(() => {
    async function getData() {
      let token = JSON.parse(localStorage.getItem('Token_access'))
      if (token) {
        const request = fetch(myProfileUrl,
          {
            method: 'GET',
            headers: {
              'Authorization': 'Bearer ' + token,
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            }
          }
        );
        const response = await request;
        const parsed = await response.json();
        if(parsed?.data){
          setData(parsed.data);
          const encryptedUserData = CryptoJS.AES.encrypt(
            JSON.stringify(parsed.data),
            'secret key'
          ).toString();
          localStorage.setItem('u/\s/p/r\e', encryptedUserData);
        }
        if(parsed.message === 'Unauthenticated.'){
          setShow(true)
          setTimeout(()=>{
            localStorage.removeItem('Token_access')
            localStorage.removeItem('u/\s/p/r\e')
            localStorage.removeItem('My Number')
            localStorage.removeItem('O/[]r/d/I/d')
            localStorage.removeItem('activeTab')
            localStorage.removeItem('n/x\t/r/m/n/d/r')
            navigate('/')  
          },2000)
        }
      }
    }
  
    const cachedData = localStorage.getItem('u/\s/p/r\e');
    if (cachedData) {
      setData(JSON.parse(CryptoJS.AES.decrypt(cachedData, 'secret key').toString(CryptoJS.enc.Utf8)));
      setIsLoading(false); 
    } else {
      setIsLoading(true); 
      getData(); 
    }
  }, []);
  
  const location = useLocation();
  const getProfileFromStorage = useCallback(() => {
    const cachedData = localStorage.getItem('u/\s/p/r\e');
    if (cachedData) {
      return CryptoJS.AES.decrypt(cachedData, 'secret key').toString(CryptoJS.enc.Utf8);
    }
    return null; 
  }, []);
  
  useEffect(() => {
    if (data) {
      setIsLoading(false);
    }
  }, [data]);
  
  useMemo(() => {
    const token = getProfileFromStorage();
    if (token) {
      setData(JSON.parse(token));
    }
  }, [location]);
  
  useEffect(() => {
    if (!data) {
      setData(JSON.parse(getProfileFromStorage()));
    }
  }, [data, getProfileFromStorage]);
  

  return (<>
  <div className="card-logo-col" >
      <Link to='/dashboard'> <img src="./images/weldonrx-logo.png" style={{ width: "160px" }} /> </Link>
      <div className="user-dropdown-group">
        <div className='dropdown-toggle'>
          {isLoading ?  
          <>
          <div className="user-dropdown-toggle">
          <Card style={{width:'75px',height:'50.36px',backgroundColor: "#ecebeb", borderColor: 'white'}}/>
          <div className="media-body">
          <Card style={{width:'115px',height:'22px',margin:'5px 5px 5px 0px' ,backgroundColor: "#ecebeb", borderColor: 'white'}}  className="mt-0" />
          <Card style={{width:'175px',height:'28.8px' ,backgroundColor: "#ecebeb", borderColor: 'white',}}/>
            </div>
          </div>

          <div className="wx_ShowHideTab">
          <Card style={{width:'39px',height:'39px',borderRadius:'50%',backgroundColor: "#ecebeb", borderColor: 'white'}}   className="accordion-button collapsed m-0" />
          </div>
        </>
          
          :<>
          <div className="user-dropdown-toggle">
            <img src={data?.profile_image} />
            <div className="media-body">
              <h5 className="mt-0">{props.day}</h5>
              <h4>{data?.full_name}</h4>
            </div>
          </div>

          <div className="wx_ShowHideTab">
            <button className="accordion-button collapsed m-0" type="button" >
              <img src="./images/myprofile/settingic.svg" className="btn" onClick={handleClick} />
            </button>
          </div>
          
          </>}
        </div>
      </div>
    </div>

  <Modal show={show} onHide={handleClose} centered  style={{overflow:'hidden'}}>
        <Modal.Header closeButton>
          <Modal.Title><FcExpired  />Your session has expired</Modal.Title>
        </Modal.Header>
        <Modal.Body><p>Please log in again!</p></Modal.Body> 
  </Modal>
  </>)
}

export default MyProfile;