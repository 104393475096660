import { useEffect, useState, useRef } from 'react';

const LogoutTimer = ({ onLogout }) => {
  const [timer, setTimer] = useState(null);
  const timerRef = useRef(null);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      onLogout();
    }, 30 * 60 * 1000); 

    setTimer(timeoutId);
    timerRef.current = timeoutId;

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [onLogout]);

  const resetTimer = () => {
    clearTimeout(timerRef.current);
    const newTimer = setTimeout(() => {
      onLogout();
    },30 * 60 * 1000);
    setTimer(newTimer);
    timerRef.current = newTimer;
  };

  const handleInteraction = () => {
    resetTimer();
  };

  useEffect(() => {
    const handleMouseMove = () => {
      handleInteraction();
    };

    const handleKeyPress = () => {
      handleInteraction();
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('keypress', handleKeyPress);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('keypress', handleKeyPress);
    };
  }, []);

  return null;
};

export default LogoutTimer;
 