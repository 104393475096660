import axios from "axios";
import { AppUrl } from "../sharedApi/common";
import { useState, useEffect } from "react";
import Card from 'react-bootstrap/Card';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { PrivacyPolicyDescription,PrivacyPolicyTitle,PrivacyPolicyUrl } from "../metaTags/meta";
import DOMPurify from 'dompurify';

const PrivacyPolicy = (props) => {
  const [privacyPolicyData, setPrivacyPolicyData] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function makeRequest() {
      setLoading(true)

      const response = await axios.get(`${AppUrl}/api/content`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      });
      setPrivacyPolicyData(response.data.data)
      setLoading(false)
    }
    makeRequest();
  }, [])

  const sanitizedHTML = privacyPolicyData && privacyPolicyData[1] ? DOMPurify.sanitize(privacyPolicyData[1].content) : '';


  return (<>

<HelmetProvider>
    <Helmet>
      <meta name="robots" content="index,follow" />
<link rel="alternate" href={ PrivacyPolicyUrl } hreflang="en" />
<title>{PrivacyPolicyTitle}</title>
<meta charset="utf-8" />
<meta name="author" content="WeldonRx"/>
<meta name="title" content={PrivacyPolicyTitle}/>
<meta name="description" content= { PrivacyPolicyDescription } />
<meta property="og:type" content="website" />
<meta property="og:url" content={ PrivacyPolicyUrl } />
<meta property="og:title" content={PrivacyPolicyTitle} />
<meta property="og:description" content= { PrivacyPolicyDescription }/>
<meta property="og:image" content="https://weldonrx.com/weldon-social-image.png" />
<meta property="twitter:card" content="summary_large_image" />
<meta property="twitter:url" content={ PrivacyPolicyUrl } />
<meta property="twitter:title" content={PrivacyPolicyTitle}/>
<meta property="twitter:description" content= { PrivacyPolicyDescription } />
<meta property="twitter:image" content="https://weldonrx.com/weldon-social-image.png" />
<link rel="canonical" href={ PrivacyPolicyUrl } />
  </Helmet>
</HelmetProvider>

    <div className="container spaceContainer pt-5 mt-3 mt-md-3 ">
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <div className="page-title-breadcrumb">
            <h2> Privacy Policy</h2>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a > Home </a></li>
                <li> <img src="./images/bradcumb-arows.svg" alt="..." /> </li>
                <li className="breadcrumb-item active" aria-current="page">Privacy Policy </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>

      <div className="row mt-5">
        <div className="card wx_myprofileTextRight wx_MyContactBg mt-0">
          {loading ? <>
            <Card className="pill-schedule-row" style={{ height: '68px', backgroundColor: "#ecebeb", borderColor: 'white', marginBottom: '20px' }}></Card>
            <Card className="pill-schedule-row" style={{ height: '68px', backgroundColor: "#ecebeb", borderColor: 'white', marginBottom: '20px' }}></Card>
            <Card className="pill-schedule-row" style={{ height: '68px', backgroundColor: "#ecebeb", borderColor: 'white', marginBottom: '20px' }}></Card>
            <Card className="pill-schedule-row" style={{ height: '68px', backgroundColor: "#ecebeb", borderColor: 'white', marginBottom: '20px' }}></Card>
            <Card className="pill-schedule-row" style={{ height: '68px', backgroundColor: "#ecebeb", borderColor: 'white', marginBottom: '20px' }}></Card>
            <Card className="pill-schedule-row" style={{ height: '68px', backgroundColor: "#ecebeb", borderColor: 'white', marginBottom: '20px' }}></Card>
            <Card className="pill-schedule-row" style={{ height: '68px', backgroundColor: "#ecebeb", borderColor: 'white', marginBottom: '20px' }}></Card>
          </>
            :
            <div className="card-body">
            <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }}></div>
            </div>}
        </div>
      </div>
    </div>
  </>)
}
export default PrivacyPolicy;