import Accordion from 'react-bootstrap/Accordion';

const QuestionsPart = () => {
  return (<>
    <div className="w-100 spaceContainer p-lg-5 p-md-5 p-sm-0 p-0 mt-3" style={{ backgroundColor: '#F5F5FB' }}>
      <div className="container pt-4 mb-3 mt-2 pb-5">
        <div className="section-title text-center">
          <h2>Frequently Asked Questions</h2>
        </div>
        <div className="mt-5" style={{ backgroundColor: '#fff ', borderRadius: '25px' }}>

          <div className="accordion p-3" id="accordionPanelsStayOpenExample">

            <Accordion defaultActiveKey={['0']} alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header>What are the delivery options available?</Accordion.Header>
                <Accordion.Body>
                  <p>Our app offers hassle-free delivery directly to you, with no extra cost. In select locations, we even provide the option of receiving your medications on the same day!</p>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>Do you offer discreet packaging?</Accordion.Header>
                <Accordion.Body>
                  <p>We understand the importance of privacy. That's why we use discreet packaging for all deliveries, ensuring that the contents of your package remain confidential.</p>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>Do you offer automatic refills?</Accordion.Header>
                <Accordion.Body>
                  <p>Yes! We will takes care of everything,  from managing your refills to communicating with your doctors for prescription renewals. This way, you never have to worry about running out of medication and can always have what you need on hand.</p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  </>)
}
export default QuestionsPart;