import Login from '../login/login';
import About from './howItWorks';
import QuestionsPart from './questioningPArt';
import { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Header = (props) =>
{
  const[loginButton,setLoginButton] = useState(true)

    return(<> 

<HelmetProvider>
    <Helmet>
      <meta name="robots" content="index,follow" />
<link rel="alternate" href="https://weldonrx.com/" hreflang="en" />
<title>WeldonRx: Simple Prescription Management</title>
<meta charset="utf-8" />
<meta name="author" content="WeldonRx"/>
<meta name="title" content="WeldonRx: Simple Prescription Management" />
<meta name="description" content="Experience prescriptions made simple with WeldonRx, offering new and transfer prescription services, quality medications, and healthcare essentials at competitive prices." />
<meta property="og:type" content="website" />
<meta property="og:url" content="https://weldonrx.com/" />
<meta property="og:title" content="WeldonRx: Simple Prescription Management" />
<meta property="og:description" content="Experience prescriptions made simple with WeldonRx, offering new and transfer prescription services, quality medications, and healthcare essentials at competitive prices." />
<meta property="og:image" content="https://weldonrx.com/weldon-social-image.png" />
<meta property="twitter:card" content="summary_large_image" />
<meta property="twitter:url" content="https://weldonrx.com/" />
<meta property="twitter:title" content="WeldonRx: Simple Prescription Management" />
<meta property="twitter:description" content="Experience prescriptions made simple with WeldonRx, offering new and transfer prescription services, quality medications, and healthcare essentials at competitive prices." />
<meta property="twitter:image" content="https://weldonrx.com/weldon-social-image.png" />
<link rel="canonical" href="https://weldonrx.com/" />
  </Helmet>
</HelmetProvider>

     <Login submit={props.submit} handleNumber={props.handleNumber} userNumDetail={props.userNumDetail}
        errMsg={props.errMsg} submitGoogleToken={props.submitGoogleToken} loginButton={loginButton} googleToken={props.googleToken} 
        googleEmail={props.googleEmail} onSuccess={props.onSuccess} loadingPhoneNumber={props.loadingPhoneNumber} loading={props.loading} changeBackground={props.changeBackground} googleLoginLoading={props.googleLoginLoading}/>

        <About />

        <QuestionsPart />

    </>)
}
export default Header ;