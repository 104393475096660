import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { logoutUrl } from "../sharedApi/common";
import { decrypteData } from "../sharedApi/customeAxios";
import CryptoJS from 'crypto-js';
import {persistor} from '../services/store/store'

const ProtectedRoute = (props) => {
  
  const [day, setDay] = useState('');

  const { Component } = props
  const navigate = useNavigate();
  

  let today = new Date()
  let curHr = today.getHours()

  useEffect(() => {
    if (curHr < 12) {
      setDay('Good Morning')
    } else if (curHr < 16) {
      setDay('Good Afternoon')
    } else {
      setDay('Good Evening')
    }
  }, [])


  const logoutNumber = async () => {
    const token = JSON.parse(localStorage.getItem('Token_access'))
    const rawResponse = await fetch(logoutUrl,
      {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + token,
          'Accept': 'application/json',
        },
        body: JSON.stringify()
      })
    const result = await rawResponse.json();
    return result;
  }

  const userLogout = () => {
    logoutNumber();
    navigate('/')
    persistor.purge();
    localStorage.removeItem('Token_access')
    localStorage.removeItem("My Number");
    localStorage.removeItem("O/[]r/d/I/d");
    localStorage.removeItem("u/s/p/re")
    localStorage.removeItem("orderStatus")
    localStorage.removeItem("p/l/r\/m/n/d/\r")
    localStorage.removeItem("O/[]r/date")
    localStorage.removeItem("email")
    localStorage.removeItem("activeTab")
    localStorage.removeItem("p/\h/r\m/c\/i[]d")    
    localStorage.removeItem('n/x\t/r/m/n/d/r');
  }


  // useEffect(()=> 
  // {
  //   let login = JSON.parse(localStorage.getItem('Token_access'))
  //   setAccessToken(login) 
  //   if(!login) 
  //     {
  //         navigate('/Error')  
  //     }
  // })

  useEffect(() => {
    const login = JSON.parse(localStorage.getItem('Token_access'))
    const myProfile = decrypteData();

    if (!login) {
      navigate('/Error')
    } else if (!myProfile) {
      setTimeout(() => {
        const updatedProfile = decrypteData();
        if (!updatedProfile) {
          navigate('/createProfile')
        } else if (updatedProfile?.user_default_shipping_address === null) {
          navigate('/createProfile')
        }
      }, 5000)
    } else if (myProfile?.user_default_shipping_address === null) {
      navigate('/createProfile')
    }
  }, [])

  return (
    <div>
      <Component         
       logOut={userLogout}  day={day}
      />
    </div>
  )
};

export default ProtectedRoute;
